import { Component, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RouteService } from '../../../service/route.service';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';

@Component({
  selector: 'app-user-logs',
  templateUrl: './user-logs.component.html',
  styleUrl: './user-logs.component.scss',
})
export class UserLogsComponent {
  routes: string[];

  constructor(
    private routesService: RouteService,
    private http: HttpClient
  ) {}

  gridConfig: GridConfig = {
    columns: [
      { label: 'User Email', jsonKey: 'userEmail', dataType: 'string' },
      { label:'User Role', jsonKey:'role', dataType:'string'},
      { label: 'Action', jsonKey: 'action', dataType: 'string', toUppercase: true },
      { label: 'Device', jsonKey: 'device', dataType: 'string' },
      { label: 'IP Address', jsonKey: 'ipAddress', dataType: 'string' },
      { label: 'Event Time',jsonKey:'createdAt',dataType:'date'},
      { label: 'Browser', jsonKey: 'browser', dataType: 'string', width: 'full', isSortable:false },
    ],
    filters: [
      { label: 'User Email', jsonKey: 'emailFilters', options: [], type: 'multi' },
      { label: 'Action', jsonKey: 'actionFilters', options: {data:[{id:'login',name:'LOGIN'},{id:'logout',name:'LOGOUT'}]}, type: 'multi' },
    ],
    editable: false,
    deletable: false,
    exportable: true,
  };

  getDataURL = API_ENDPOINTS.USER_LOGGING;
  getUserEmailURL = API_ENDPOINTS.USERS_ROLE;

  @ViewChild(ToastComponent) toastComponent!: ToastComponent;
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;

  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
    this.refreshHandler();
  }

  ngOnInit(){
    this.fetchUserEmailOptions();
  }


  fetchUserEmailOptions() {
    // Make an API call to fetch practice load options
    this.http.get<any>(this.getUserEmailURL, {params: { pageSize: 20 }}).subscribe(response => {
      response.data=response.data.map(option => ({
        ...option,
        name: option.email,
        id: option.email
    })).sort((a, b) => a.name.localeCompare(b.name));
      this.gridConfig.filters.find(filter => filter.jsonKey === 'emailFilters').options = response;
    });
  }

}
