<div class="w-full h-[100vh] bg-[#f5f5f9]">
  <!-- application header -->
  <div class="flex h-full w-full">
    <!-- sidebar menu -->
    <app-sidebar (collapsedStateChanged)="onCollapsedStateChanged($event)" [ngClass]="{'hidden':isLoading}" *ngIf="hasRole && user?.isActive"></app-sidebar>

    <!-- main content area -->
    <div class="w-full h-full overflow-x-hidden">
      <header class="flex justify-between bg-white shadow-sm" [ngClass]="{'ml-2': hasRole}">
        <!-- Logo Section -->
        <div *ngIf="!hasRole || !user?.isActive" class="flex items-center">
          <img  class="h-12 p-1 ml-4 mb-2" src="../../../assets/images/logo.png" alt="">
        </div>

        <!-- BreadCrumb Section -->
        <span *ngIf="hasRole && user?.isActive" class="px-4 py-4 h-fit flex items-center">
          <span *ngFor="let route of currentRoutes; let i = index" class="flex">
            <a
              class="regular-font hover:text-indigo-500 focus:text-indigo-500 focus:outline-none"
              [ngClass]="{
                'text-indigo-500': i === currentRoutes.length - 1
              }"
              [routerLink]="['/' + getBreadCrumbPath(i)]"
              routerLinkActive="router-link-active"
            >
              {{ getBreadCrumbName(route) }}
            </a>
            <span
              class="flex items-center justify-center text-gray-700 regular-font text-center px-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="12"
                width="12"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                />
              </svg>
            </span>
          </span>
        </span>

        <!-- profile section -->
        <div class="flex items-center justify-between gap-5 pr-5">
          <div class="relative ml-3">
            <div>
              <button
                type="button"
                class="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2"
                id="user-menu-button"
                [ngClass]="{
                  'outline-none ring-2 ring-gray-800 ring-offset-2 ring-offset-white': showMenu
                }"
                aria-expanded="false"
                aria-haspopup="true"
                (click)="toggleMenu($event)"
              >
                <span class="absolute -inset-1.5"></span>
                <span class="sr-only">Open user menu</span>
                <div class="h-8 w-8 rounded-full bg-indigo-400 text-white flex items-center justify-center">
                  <span class="text-lg font-bold">{{ getInitials(user?.name) }}</span>
                </div>
              </button>
            </div>

            <div
              class="absolute right-0 z-10 mt-2 w-64 origin-top-right bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1"
              [ngClass]="{ hidden: !showMenu }"
              [@menuAnimation]="showMenu ? 'visible' : 'hidden'"
            >
              <div class="flex w-full mx-2 px-2 py-2 items-center whitespace-nowrap">
                <div class="w-[25%]">
                  <div class="h-12 w-12 rounded-full bg-indigo-400 text-white flex items-center justify-center">
                    <span class="text-lg font-bold">{{ getInitials(user?.name) }}</span>
                  </div>
                </div>
                <div class="w-[75%] overflow-hidden">
                  <h3 class="text-ellipsis overflow-hidden w-[90%] mr-2">
                    {{ user?.name }}
                  </h3>
                  <p class="regular-font text-gray-400 text-ellipsis overflow-hidden w-[90%] mr-2">
                    {{ user?.email }}
                  </p>
                </div>
              </div>

              <div>
                <div class="cursor-pointer flex items-center gap-3  mx-2 px-2 py-2 my-1 regular-font text-gray-700 hover:bg-indigo-100 hover:text-indigo-500 rounded-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    height="16"
                    width="16"
                    fill="currentColor"
                  >
                    <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                  </svg>
                  <a routerLink="/profile" role="menuitem" tabindex="-1" id="user-menu-item-0">Your Profile</a>
                </div>
 
                <div class="cursor-pointer flex items-center gap-3  mx-2 px-2 py-2 my-1 regular-font text-gray-700 hover:bg-indigo-100 hover:text-indigo-500 rounded-md" (click)="logout()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    height="16"
                    width="16"
                    fill="currentColor"
                  >
                    <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" />
                  </svg>
                  <a href="#" role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div *ngIf="isLoading" class="h-[calc(100vh-72px)] flex items-center justify-center bg-gray-100 bg-opacity-50 z-50">
        <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
      <div *ngIf="!isLoading" class="bg-gray-100 min-h-[calc(100vh-80px)] flex items-center justify-center">
        <div class="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
          <!-- User profile content -->
          <div class="flex items-center mb-6">
            <div class="w-20 h-20  bg-indigo-400 rounded-full flex items-center justify-center">
              <img *ngIf="getProfileImage()" [src]="getProfileImage()" alt="Profile Image" class="w-full h-full rounded-full object-cover">
              <span *ngIf="!getProfileImage()" class="text-4xl text-white font-bold">{{ getInitials(user?.name) }}</span>
            </div>
            <div class="ml-4">
              <h1 class="uppercase text-base text-gray-800"><b>{{ user?.name }}</b></h1>
              <p class="text-gray-600 text-sm">{{ user?.email }}</p>
            </div>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div>
              <!-- <div class="mb-4">
                <p class="text-gray-600 font-semibold"><b>Username/Email:</b></p>
                <p class="text-gray-800 regular-font text-ellipsis overflow-hidden">{{ user?.preferred_username }}</p>
              </div> -->
              <div class="mb-4" *ngIf="user?.given_name">
                <p class="text-gray-600 text-base uppercase"><b>First Name</b></p>
                <p class="text-gray-800 text-sm uppercase">{{ user?.given_name }}</p>
              </div>
              <div class="mb-4" *ngIf="user.role">
                <p class="text-gray-600 text-base uppercase"><b>User Role</b></p>
                <p class="text-gray-800 text-sm uppercase">{{user?.role}}</p>
              </div>
            </div>
            <div>
              <div class="mb-4" *ngIf="user?.family_name">
                <p class="text-gray-600 text-base uppercase"><b>Last Name</b></p>
                <p class="text-gray-800 text-sm uppercase">{{ user?.family_name }}</p>
              </div>
              <div class="mb-4">
                <p class="text-gray-600 text-base uppercase"><b>Okta User ID</b></p>
                <p class="text-gray-800 text-sm">{{ user?.sub }}</p>
              </div>
              
            </div>
          </div>
          <div *ngIf="!hasRole || !user?.isActive" class="mt-8">
            <div class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 flex items-center" role="alert">
              <div class="flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-yellow-500" viewBox="0 0 512 512">
                  <path fill="currentColor" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                </svg>
              </div>
              <div class="ml-3">
                <ng-container *ngIf="!hasRole;else checkIsActive">
                  <p class="header-font">SignIn Successful</p>
                  <p>The signed-in user does not have an assigned role. For further access, please contact the administrator.</p>
                </ng-container>
                <ng-template #checkIsActive>
                  <ng-container *ngIf="!user?.isActive" >
                    <p class="header-font"> Account Deactivated</p>
                    <p>Your account has been deactivated. Please contact your administrator for assistance.</p>
                  </ng-container>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
