import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import {
  DrawerComponent,
  ModalModel,
} from '../../shared/drawer/drawer.component';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { RouteService } from '../../../service/route.service';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { DrawerInputConfig } from '../../../models/drawer-model';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { v4 as uuidv4 } from 'uuid';
import { NIL as NIL_UUID } from 'uuid';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';
import { DRAWER_PLACEHOLDERS } from '../../shared/constants/drawerPlaceHolders';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface PMSObject extends ModalModel {
  id: string;
  name: string;
  query: string;
  notes: string;
  createdAt: string;
  modifiedAt: string;
  createdBy: string;
  modifiedBy: string;
  isActive: boolean;
  pmsVersion?: string;
  pmsId: string;
  pmsMasterName: string;
}
@Component({
  selector: 'app-pms-object',
  templateUrl: './pms-object.component.html',
  styleUrl: './pms-object.component.css',
})
export class PMSObjectComponent {
  @ViewChild(DrawerComponent) drawerComponent!: DrawerComponent<PMSObject>;
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;

  pmsObjectType: PMSObject = {
    id: '',
    name: '',
    query: '',
    notes: '',
    createdAt: '',
    modifiedAt: '',
    createdBy: '',
    modifiedBy: '',
    isActive: true,
    pmsId: NIL_UUID,
    pmsVersion: '',
    pmsMasterName: DRAWER_PLACEHOLDERS.SELECT_PMS,
    toggleUrl: '',
  };

  ngOnInit() {
    this.fetchPMSNameOptions();
  }

  gridConfig: GridConfig = {
    columns: [
      {
        label: 'Active',
        jsonKey: 'isActive',
        dataType: 'toggle',
        isSortable: false,
      },
      // {
      //   label: 'Checksum required ',
      //   jsonKey: 'isChecksumRequired',
      //   dataType: 'toggle',
      //   toggleUrl: API_ENDPOINTS.TOGGLE_ID_BASED_PULL,
      // },
      //{ label: 'ID', jsonKey: 'id', dataType: 'number' },
      { label: 'PMS Name', jsonKey: 'pmsMasterName', dataType: 'string' },
      {
        label: 'Configured PMS Version',
        jsonKey: 'pmsVersion',
        dataType: 'string',
        isSortable: false,
      },
      { label: 'PMS Object Name', jsonKey: 'name', dataType: 'string' },
      //{ label: 'PMS ID', jsonKey: 'pmsId', dataType: 'number' },
      {
        label: 'Full Load Query',
        jsonKey: 'query',
        dataType: 'text-wrap',
        isSortable: false,
      },
      // {
      //   label: 'Incremental Load Query',
      //   jsonKey: 'incrementalQuery',
      //   dataType: 'text-wrap',
      // },
      // { label: 'Patch Query', jsonKey: 'patchQuery', dataType: 'text-wrap' },
      {
        label: 'Created By',
        jsonKey: 'createdByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Created At',
        jsonKey: 'createdAt',
        dataType: 'date',
        hideOnToggle: true,
      },
      {
        label: 'Modified By',
        jsonKey: 'modifiedByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Modified At',
        jsonKey: 'modifiedAt',
        dataType: 'date',
        hideOnToggle: true,
      },
      {
        label: 'Notes',
        jsonKey: 'notes',
        dataType: 'string',
        width:'full',
        isSortable: false,
      },
    ],
    filters: [
      {
        label: 'PMS Name',
        jsonKey: 'pmsId',
        type: 'multi',
        options: [],
        width: 'w-50',
      },
    ],

    editable: true,
    deletable: false,
    displayAdd: true,
    isToggleDisabled: true,
    displayHideAuditColumnsToggle: true,
  };

  drawerInputConfig: DrawerInputConfig[] = [
    {
      label: 'PMS Name',
      jsonKey: 'pmsMasterName',
      input: 'select',
      dataType: 'select',
      foreignKeyProperty: 'pmsId',
      dropDownOptionsURL: `${API_ENDPOINTS.PMS_MASTER}`,
      dropdownJsonKey: 'pmsNameVersion',
      dropdownSortDirection: 'asc',
      isRequired: true,
    },
    {
      label: 'PMS Object Name',
      jsonKey: 'name',
      input: 'text',
      dataType: 'string',
      isRequired: true,
      minLength: 1,
      maxLength: 500,
      validationRegex: /^[a-zA-Z_]+$/,
    },
    {
      label: 'Full Load Query',
      jsonKey: 'query',
      input: 'textarea',
      dataType: 'string',
      isRequired: true,
      minLength: 1,
    },
    // {
    //   label: 'Incremental Load Query',
    //   jsonKey: 'incrementalQuery',
    //   input: 'textarea',
    //   dataType: 'string',
    //   isRequired: false,
    //   minLength: 1,
    // },
    // {
    //   label: 'Patch Query',
    //   jsonKey: 'patchQuery',
    //   input: 'textarea',
    //   dataType: 'string',
    //   isRequired: false,
    //   minLength: 1,
    // },
    {
      label: 'Notes',
      jsonKey: 'notes',
      input: 'textarea',
      dataType: 'string',
      isRequired: false,
      minLength: 1,
    },
    // {
    //   label: 'Checksum required ',
    //   jsonKey: 'isChecksumRequired',
    //   input: 'toggle',
    //   dataType: 'boolean',
    //   fieldsToDisableOnToggle:['patchQuery','incrementalQuery'],
    // },
    {
      label: 'Active',
      jsonKey: 'isActive',
      input: 'toggle',
      dataType: 'boolean',
    },
  ];

  getDataUrl = API_ENDPOINTS.PMS_OBJECT;
  editDataUrl = API_ENDPOINTS.PMS_OBJECT;
  addDataUrl = API_ENDPOINTS.PMS_OBJECT;
  toggleUrl = API_ENDPOINTS.TOGGLE_PMS_OBJECT;
  getPracticesUrl = API_ENDPOINTS.PMS_MASTER;

  private routerSubscription: Subscription;
  routes: string[] = [];
  constructor(
    private routeService: RouteService,
    private cdr: ChangeDetectorRef,
    private _http: HttpClient
  ) {}

  public get http(): HttpClient {
    return this._http;
  }
  public set http(value: HttpClient) {
    this._http = value;
  }
  ngAfterViewInit(): void {
    this.routerSubscription = this.routeService
      .getRoutes()
      .subscribe((data) => {
        this.routes = data;
        this.routes.shift();
        if (this.routes.length == 2) {
          this.routeService.redirectTO(
            this.routes.join('/').concat('/pms-objects')
          );
        }
        this.cdr.detectChanges();
      });
  }
  onAddHandler(): void {
    if (this.drawerComponent) {
      this.drawerComponent.drawerType = 'add';
      this.resetEntry();
      this.drawerComponent.openDrawer();
    } else {
      console.error('Drawer component is not initialized');
    }
  }

  fetchPMSNameOptions() {
    this._http
      .get<any>(this.getPracticesUrl, { params: { pageSize: 20 } })
      .subscribe((response) => {
        response.data = response.data
          .filter((option) => option.isActive)
          .map((option) => ({
            ...option,
            name: option.pmsNameVersion,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        this.gridConfig.filters.find(
          (filter) => filter.jsonKey === 'pmsId'
        ).options = response;
      });
  }

  resetEntry() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    const dateString = `${year}-${month}-${day}`;
    this.drawerComponent.drawerModel = {
      id: NIL_UUID,
      name: '',
      query: '',
      notes: '',
      createdAt: dateString,
      modifiedAt: dateString,
      pmsMasterName: DRAWER_PLACEHOLDERS.SELECT_PMS,
      createdBy: NIL_UUID,
      modifiedBy: NIL_UUID,
      isActive: true,
      pmsVersion: '',
      pmsId: NIL_UUID,
    };
  }

  onEditHandler(rowData: any): void {
    rowData.pmsMasterName = rowData.pmsMasterName + ' ' + rowData.pmsVersion;
    if (this.drawerComponent) {
      this.drawerComponent.drawerType = 'edit';
      this.drawerComponent.drawerModel = rowData;
      this.drawerComponent.openDrawer();
      this.cdr.detectChanges();
    } else {
      console.error('Drawer component is not initialized');
    }
  }

  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  // toast related properties

  @ViewChild(ToastComponent) toastComponent!: ToastComponent;

  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
    this.refreshHandler();
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
