import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Toast, ToastComponent } from '../toast/toast.component';
import { DrawerService } from './drawer.service';
import { DrawerInputConfig } from '../../../models/drawer-model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import {
  A11yModule,
  ConfigurableFocusTrap,
  ConfigurableFocusTrapFactory,
  LiveAnnouncer,
} from '@angular/cdk/a11y';
import { DRAWER_PLACEHOLDERS } from '../constants/drawerPlaceHolders';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { API_ENDPOINTS } from '../constants/apiEnpoints';
import moment from 'moment';
import seedrandom from 'seedrandom';
import { Clipboard } from '@angular/cdk/clipboard';
import cronstrue from 'cronstrue';

export interface ModalModel {
  [key: string]: any;
}

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent<T extends ModalModel> {
  drawerType: 'add' | 'edit' = 'add';
  isDrawerOpen: boolean = false;
  isSubmitLoading = false;
  isConfirmationModalVisible: boolean = false;
  @Input() drawerModel!: T;
  @Input() drawerConfig!: DrawerInputConfig[];
  @Input() editDataURL!: string;
  @Input() AddDataURL!: string;
  @Input() createDrawerTitle: string = '';
  @Input() updateDrawerTitle: string = '';
  @Input() hasTwoColumns?: boolean = false;
  @Input() removeLevel?: boolean = true;
  @Input() maxDrawerHeight?: string = 'max-h-[650px]';
  @Output() resetData: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(ToastComponent) toastComponent!: ToastComponent;
  @ViewChildren(DropdownComponent) dropdowns: QueryList<DropdownComponent>;
  @ViewChild('drawer') drawer: ElementRef<HTMLElement>;

  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();
  @Output() beforeSave: EventEmitter<void> = new EventEmitter<void>();
  containsDuplicate: boolean = false;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (this.isDrawerOpen) {
      this.closeDrawer();
    }
  }
  ngAfterViewInit() {
    this.dropdowns.changes.subscribe(() => {
      this.dropdowns.forEach((dropdown) => {
        dropdown.dependentDropDownChange.subscribe((event: any) => {
          this.onDependentDropDownChange(event);
        });
      });
    });
  }

  public get focusTrapFactory(): ConfigurableFocusTrapFactory {
    return this._focusTrapFactory;
  }
  public set focusTrapFactory(value: ConfigurableFocusTrapFactory) {
    this._focusTrapFactory = value;
  }
  public get changeDetectorRef(): ChangeDetectorRef {
    return this._changeDetectorRef;
  }
  public set changeDetectorRef(value: ChangeDetectorRef) {
    this._changeDetectorRef = value;
  }
  focusTrap: ConfigurableFocusTrap = null;
  requiredClass = "after:content-['*'] after:ml-0.5 after:text-red-500";
  addOnBlur = true;
  showValidators = false;
  showCertificateInput = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  showPassword: boolean = false;
  disableLicenseKey: boolean = false;

  constructor(
    private drawerService: DrawerService,
    private announcer: LiveAnnouncer,
    private _focusTrapFactory: ConfigurableFocusTrapFactory,
    private _changeDetectorRef: ChangeDetectorRef,
    private clipboard: Clipboard
  ) {}

  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
  }

  saveChanges() {
    this.showValidators = true;
    for (let element of this.drawerConfig) {
      const value = this.validateInput(
        this.drawerModel[element.jsonKey],
        element.label,
        element.input,
        element.jsonKey,
        element?.minLength,
        element?.maxLength,
        element?.isRequired,
        element.validationRegex
      );

      if (value) {
        return;
      }
    }
    this.isSubmitLoading = true;
    if (this.drawerType === 'edit') {
      this.editDataURL = this.editDataURL.replace(
        ':id',
        this.drawerModel['id']
      );
      this.drawerService
        .editEntry(this.drawerModel, this.editDataURL)
        .subscribe({
          next: (value: any) => {
            this.isSubmitLoading = false;
            this.closeDrawer();
            this.showToast({
              severity: 'success',
              title: 'Success',
              message: 'Record Updated Successfully',
            });
            this.refreshGrid();
          },
          error: (err) => {
            this.isSubmitLoading = false;

            this.showToast({
              severity: 'error',
              title: 'Error',
              message: err.error.errorMessage,
            });
          },
          complete: () => {},
        });
    } else {
      this.beforeSave.emit();
      this.drawerService.addEntry(this.drawerModel, this.AddDataURL).subscribe({
        next: (res: any) => {
          this.isSubmitLoading = false;
          this.closeDrawer();
          this.showToast({
            severity: 'success',
            title: 'Success',
            message: 'Record added succesfully',
          });
          this.refreshGrid();
        },
        error: (err: any) => {
          this.isSubmitLoading = false;
          this.showToast({
            severity: 'error',
            title: 'Error',
            message: err.error.errorMessage,
          });
        },
      });
    }
  }

  closeDrawer() {
    this.dropdowns?.forEach((dropdown) => (dropdown.showDropdown = false));
    this.isDrawerOpen = false;
    this.showValidators = false;
    this.focusTrap._disable();
    this.onResetClick();
    this.disableLicenseKey = false;
  }

  openDrawer() {
    if (
      this.drawerType == 'edit' &&
      this.drawerModel['licenseKey'] &&
      this.drawerModel['isInstalled']
    ) {
      this.disableLicenseKey = true;
    }
    this.focusTrap = this._focusTrapFactory.create(
      document.getElementById(`drawer-${this.AddDataURL}`),
      {
        defer: true,
      }
    );
    this.focusTrap.focusFirstTabbableElement();
    this.isDrawerOpen = true;
    this.showValidators = false;

    if (this.drawerType === 'edit') {
      // Simulate the selection of the parent dropdown during edit
      this.drawerConfig.forEach((config) => {
        if (config.dependentDropDownConfig) {
          const selectedOption = this.getSelectedOption(
            config.jsonKey,
            config.uniqueIdentifier
          );
          if (selectedOption) {
            // Trigger the dependent dropdown change event
            this.onDependentDropDownChange({
              key: config.jsonKey,
              value: selectedOption.id,
            });
          }
        }
      });
    }
  }

  logSelectedOptions() {
    this.drawerConfig.forEach((config) => {
      if (config.input === 'select') {
        const selectedOption = this.getSelectedOption(
          config.jsonKey,
          config.uniqueIdentifier
        );
        if (selectedOption) {
          //console.log(`Selected option for ${config.label}:`, selectedOption);
        } else {
          //console.log(`No option selected for ${config.label}`);
        }
      }
    });
  }

  getSelectedOption(
    key: string,
    uniqueIdentifier: string
  ): { id: any; name: any; uniqueIdentifier: string } | null {
    const selectedValue = this.drawerModel[key];
    const foreignKeyProperty = this.drawerConfig.find(
      (config) => config.jsonKey === key
    )?.foreignKeyProperty;

    if (selectedValue && foreignKeyProperty) {
      const selectedId = this.drawerModel[foreignKeyProperty];
      return {
        id: selectedId,
        name: selectedValue,
        uniqueIdentifier: uniqueIdentifier,
      };
    }

    return null;
  }

  refreshGrid() {
    this.refresh.emit();
  }

  updateDrawerModel(jsonKey: string, foreignKeyProperty: any, value: any) {
    const newDrawerModel: { [key: string]: any } = { ...this.drawerModel };
    if (typeof value === 'object' && value !== null) {
      newDrawerModel[jsonKey] = value.name;
      newDrawerModel[`${foreignKeyProperty}`] = value.id;
      // Trigger the dependent dropdown change event
      const dependentDropDownConfig = this.drawerConfig.find(
        (config) => config.jsonKey === jsonKey
      )?.dependentDropDownConfig;
      if (dependentDropDownConfig) {
        this.onDependentDropDownChange({
          key: jsonKey,
          value: value.id,
        });
      }
    } else {
      newDrawerModel[jsonKey] = value;
    }

    this.drawerModel = newDrawerModel as T;
  }

  addChip(key: string, event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      const chips = this.drawerModel[key]
        ? this.drawerModel[key].split(',')
        : [];
      const lowercaseChips = chips.map((chip) => chip.toLowerCase());
      this.resetDuplicate();
      if (lowercaseChips.includes(value.toLowerCase())) {
        this.containsDuplicate = true;
        event.chipInput!.clear();

        return;
      }

      chips.push(value);
      this.drawerModel = {
        ...this.drawerModel,
        [key]: chips.join(','),
      };
    }

    event.chipInput!.clear();
  }

  resetDuplicate() {
    this.containsDuplicate = false;
  }

  removeChip(key: string, chip: string): void {
    const chips = this.drawerModel[key].split(',');
    const index = chips.indexOf(chip);

    if (index >= 0) {
      chips.splice(index, 1);
      this.drawerModel = {
        ...this.drawerModel,
        [key]: chips.join(','),
      };
      this.announcer.announce(`Removed ${chip}`);
    }
  }

  editChip(key: string, chip: string, event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
      this.removeChip(key, chip);
      return;
    }

    const chips = this.drawerModel[key].split(',');
    const index = chips.indexOf(chip);

    if (index >= 0) {
      this.resetDuplicate();
      const lowercaseChips = chips.map((c) => c.toLowerCase());
      const lowercaseValue = value.toLowerCase();

      const isDuplicate = lowercaseChips.some(
        (c, i) => c === lowercaseValue && i !== index
      );

      if (isDuplicate) {
        this.containsDuplicate = true;
        this.drawerModel = {
          ...this.drawerModel,
          [key]: chips.join(','),
        };
        return;
      }

      chips[index] = value;
      this.drawerModel = {
        ...this.drawerModel,
        [key]: chips.join(','),
      };
    }
  }

  // method to return error message for input

  validateInput(
    value: string,
    label: string,
    input: string,
    key?: string,
    minLength?: number,
    maxLength?: number,
    isRequired?: boolean,
    regex?: RegExp
  ): string {
    // if field isn't required then return empty message
    if (!isRequired) {
      return '';
    }

    // trim value before validation
    // value = value ? value.trim() : value;

    // validate in case of select
    if (
      input === 'select' &&
      (value === DRAWER_PLACEHOLDERS.SELECT_TYPE ||
        value === DRAWER_PLACEHOLDERS.SELECT_PMS ||
        value === DRAWER_PLACEHOLDERS.SELECT_OBJECT ||
        value === DRAWER_PLACEHOLDERS.SELECT_PRACTICE ||
        value === DRAWER_PLACEHOLDERS.SELECT_LOAD_SCHEDULE ||
        value === DRAWER_PLACEHOLDERS.SELECT_STATUS ||
        value === DRAWER_PLACEHOLDERS.SELECT_ROLE ||
        value === DRAWER_PLACEHOLDERS.SELECT_USER ||
        value === DRAWER_PLACEHOLDERS.DATABASESERVERTYPE ||
        value === DRAWER_PLACEHOLDERS.SELECT_PMSNAME ||
        value === DRAWER_PLACEHOLDERS.SELECT_AGENTVERSION ||
        value === DRAWER_PLACEHOLDERS.SELECT_AGENTRELEASE ||
        value === DRAWER_PLACEHOLDERS.SELECT_LOADTYPE)
    ) {
      return 'Please select an option from the dropdown.';
    }

    // validate in case of text or passowrd
    else if (input === 'text' || input === 'password') {
      if (!value) {
        return 'This field is required. Please provide input.';
      } else if (regex && !regex.test(value)) {
        if (key === 'cronExpression') {
          return 'Please enter a valid cron expression.';
        }
        return 'This field requires a valid input. Please check the format and try again.';
      } else if (
        (value && value.length < minLength) ||
        (value && value.length > maxLength)
      ) {
        return `Input must be ${minLength} to ${maxLength} characters long.`;
      }
    }

    // validate in case of number
    else if (input === 'number') {
      return !+value
        ? 'This field is required.'
        : +value < minLength || +value > maxLength
        ? `Please enter a value between ${minLength} and ${maxLength}`
        : '';
    } else if (input === 'date') {
      if (!value) {
        return 'This field is required. Please provide input.';
      }
    }

    // validate in case of textarea
    else if (input === 'textarea') {
      if (!value) {
        return 'This field is required. Please provide input.';
      } else if (
        (value && value.length < minLength) ||
        (value && value.length > maxLength)
      ) {
        return `Input must be ${minLength} to ${maxLength} characters long.`;
      }
    } else if (input === 'time') {
      if (!value) {
        return 'This field is required. Please provide input.';
      }
    }
    // return empty message if no validation is required
    return '';
  }

  isInputValidated(): boolean {
    return false;
  }

  onDependentDropDownChange(event: any) {
    const { key, value } = event;
    console.log(this.drawerConfig);

    const dependentDropDownConfig = this.drawerConfig.find(
      (config) => config.jsonKey === key
    )?.dependentDropDownConfig;

    if (dependentDropDownConfig) {
      const url = `${dependentDropDownConfig.dependentDropDownURL}?${dependentDropDownConfig.dependentDropDownParam}=${value}`;
      const dependentDropDown = this.dropdowns.find(
        (dropdown) =>
          dropdown.uniqueIdentifier ===
          dependentDropDownConfig.dependentDropDownKey
      );
      dependentDropDown.isLoading = true;
      this.drawerService.getOptions(url).subscribe(
        (response) => {
          if (response.isSuccess && response.data) {
            if (dependentDropDown) {
              const previouslySelectedOption = this.getSelectedOption(
                dependentDropDownConfig.dependentDropDownKey,
                dependentDropDown.uniqueIdentifier
              );

              dependentDropDown.options = response.data
                .map((item: any) => ({
                  id: item.id,
                  name: item[dependentDropDown.dropDownJsonKey],
                }))
                .sort((a, b) => a.name.localeCompare(b.name));
              dependentDropDown.isLoading = false;
              // Set the previously selected option if it exists in the updated options
              if (previouslySelectedOption) {
                const selectedOption = dependentDropDown.options.find(
                  (option) => option.id === previouslySelectedOption.id
                );
                if (selectedOption) {
                  this.updateDrawerModel(
                    dependentDropDownConfig.dependentDropDownKey,
                    dependentDropDown.foreignKeyProperty,
                    selectedOption
                  );
                } else {
                  this.updateDrawerModel(
                    dependentDropDownConfig.dependentDropDownKey,
                    dependentDropDown.foreignKeyProperty,
                    null
                  );
                }
              } else {
                this.updateDrawerModel(
                  dependentDropDownConfig.dependentDropDownKey,
                  dependentDropDown.foreignKeyProperty,
                  null
                );
              }

              this._changeDetectorRef.detectChanges();
            }
          }
        },
        (error) => {
          console.error(`Error fetching options for ${key}:`, error);
        }
      );
    }
  }

  certificateInput(value: boolean) {
    this.showCertificateInput = value;
  }

  onResetClick() {
    this.showValidators = false;
    this.resetData.emit();
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  private seeded = false;

  generateKey(jsonKey: string, event: Event): void {
    event.preventDefault();
    // Seed the random number generator
    if (!this.seeded) {
      this.seeded = true;
      seedrandom(Date.now().toString());
    }

    // Generate XY (10-99), Z (0-9), W (10-99)
    const XY = Math.floor(Math.random() * 90) + 10;
    const Z = Math.floor(Math.random() * 10);
    const W = Math.floor(Math.random() * 90) + 10;

    // Calculate ABCD based on the formula (XY + Z) * W + 9 = ABCD / 4
    const intermediate = (XY + Z) * W + 9;
    let ABCD = Math.floor((intermediate * 4) / 17);

    // Ensure ABCD is a two-digit number (10-99)
    ABCD = (ABCD % 90) + 10;

    // Create the key as a 7-digit string
    const key =
      String(XY).padStart(2, '0') +
      String(Z).padStart(1, '0') +
      String(W).padStart(2, '0') +
      String(ABCD).padStart(2, '0');

    // Create the key as a 7-digit string
    this.drawerModel = {
      ...this.drawerModel,
      [jsonKey]: key,
    };
    this.clipboard.copy(key);

    // Show toast notification
    this.showToast({
      severity: 'success',
      title: 'Copied to clipboard',
      message: 'License Key Generated and Copied',
    });

    // Trigger change detection
    this._changeDetectorRef.detectChanges();
  }

  copyToClipboard(text: string, event?: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.clipboard.copy(text);
    this.showToast({
      severity: 'success',
      title: 'Copied',
      message: 'License key copied to clipboard',
    });
  }

  onLicenseKeyEnter(jsonKey: string, event: KeyboardEvent) {
    if (
      event.key === 'Enter' ||
      event.key === ' ' ||
      event.key === 'Spacebar'
    ) {
      event.preventDefault();
      this.generateKey(jsonKey, event);
    }
  }

  disableFieldsOnToggle(fieldsToDisableOnToggle: string[], value: boolean) {
    value
      ? this.drawerConfig
          .filter((e) => fieldsToDisableOnToggle?.includes(e.jsonKey))
          .map(
            (e) => (
              (e.isDisabled = true),
              (this.drawerModel = { ...this.drawerModel, [e.jsonKey]: '' })
            )
          )
      : this.drawerConfig
          .filter((e) => fieldsToDisableOnToggle?.includes(e.jsonKey))
          .map((e) => (e.isDisabled = false));
    this._changeDetectorRef.detectChanges();
  }

  // method listens every input changes
  onValueChage($event: Event, jsonKey: string) {
    if (jsonKey === 'startCronExpression') {
      const target = $event.target as HTMLInputElement;
      // extracting CRON from Input
      const cronExpression = target.value;
      let translatedCRON = '';
      try {
        translatedCRON = cronstrue.toString(cronExpression);
      } catch {
        translatedCRON = 'Invalid CRON Expression';
      }
      this.drawerModel = {
        ...this.drawerModel,
        ['description']: translatedCRON,
      };
    }
  }

  handleModalAction(action: 'confirm' | 'cancel') {
    this.isConfirmationModalVisible = false;
    if (action === 'confirm') {
      this.saveChanges();
    }

    this.focusTrap = this._focusTrapFactory.create(
      document.getElementById(`drawer-${this.AddDataURL}`),
      {
        defer: true,
      }
    );
    this.focusTrap.focusFirstTabbableElement();
  }

  openConfirmationModal() {
    // if updating show confirm modal
    if (this.drawerType === 'edit') {
      this.isConfirmationModalVisible = true;
      this.focusTrap._disable();
    } else {
      this.saveChanges();
    }
  }
}
