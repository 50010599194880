import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import {
 // DrawerComponent,
 // DrawerComponent,
  ModalModel,
} from '../../shared/drawer/drawer.component';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { RouteService } from '../../../service/route.service';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { DrawerInputConfig } from '../../../models/drawer-model';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { Subscription, sampleTime, timestamp } from 'rxjs';
import { NIL as NIL_UUID } from 'uuid';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';
import { HttpClient } from '@angular/common/http';
import { MAX_INT_32 } from '../../shared/constants/values';
 
export interface MasterSchedule extends ModalModel {
  id: string;
  status: string;
  message: string;
  createdAt: string;
  modifiedAt: string;
  createdBy: string;
  modifiedBy: string;
  practiceNameVersion: string;
}

@Component({
  selector: 'app-practice-installation-log',
  templateUrl: './practice-installation-log.component.html',
  styleUrl: './practice-installation-log.component.scss'
})
export class PracticeInstallationLogComponent  {
  @ViewChild(DynamicGridComponent) dynamicGrid!:DynamicGridComponent;

  masterScheduleType: MasterSchedule = {
    id: NIL_UUID,
    practiceNameVersion: '',
    status: '',
    message: '',
    createdAt: '',
    modifiedAt: '',
    createdBy:NIL_UUID,
    modifiedBy: NIL_UUID
  };
 
 
  gridConfig: GridConfig = {
    columns: [
      { label: 'Acknowledge', jsonKey:'isAcknowledged', dataType: 'toggle',toggleUrl :API_ENDPOINTS.PRACTICE_INSTALLATION_LOG_ACKNOWLEDGEMENT, isSortable:false},
      { label: 'Practice Code', jsonKey: 'practiceCode', dataType: 'string' },
      { label: 'Practice Name', jsonKey: 'practiceName', dataType: 'text-wrap' },
      { dataType: 'string', label: 'PMS Name', jsonKey: 'pmsName' },
      { dataType: 'string', label: 'Configured PMS Version', jsonKey: 'pmsVersion', isSortable:false },
      { dataType: 'string', label: 'Latest PMS Version', jsonKey: 'latestPMSVersion', isSortable:false },
      { label: 'MAC Address',jsonKey:"machineKey",dataType:'string',isSortable:false },
      { label: 'PMS Agent Version', jsonKey: 'pmsAgentVersion', dataType: 'string', isSortable:false },
      { label: 'First Installation Date', jsonKey: 'installationDate', dataType: 'date' },
      { label: 'Event', jsonKey: 'eventType', dataType: 'string', isSortable:false },
      { label: 'Status Code', jsonKey: 'pmsStatusCode', dataType: 'number' },
      { label: 'Status', jsonKey: 'pmsStatus', dataType: 'string',width:'full'},
      { label: 'Message', jsonKey: 'message',dataType:'string',width:'full', isSortable:false },
      { label: 'Resolution', jsonKey: 'resolution', dataType: 'string' ,isSortable:false,width:'full'},
      { label: 'Created By', jsonKey: 'createdByUserEmail', dataType: 'string',hideOnToggle:true },
      { label: 'Created At', jsonKey: 'createdAt', dataType: 'date',hideOnToggle:true },
      { label: 'Modified By', jsonKey: 'modifiedByUserEmail', dataType: 'string',hideOnToggle:true },
      { label: 'Modified At', jsonKey: 'modifiedAt', dataType: 'date',hideOnToggle:true },
    ],
    filters: [
      { label: 'Practice', jsonKey: 'practiceId', type: 'multi', options: [],  width: 'w-50' },
      { label: 'Status', jsonKey: 'status', type: 'multi', options: [],  width: 'w-50' }

    ],
    displayHideAuditColumnsToggle:true,
    editable: false,
    deletable: false,
    showAllPagination:true,
  };
 
  ngOnInit() {
    this.fetchFilterOptions();
    this.fetchStatusOptions();
  }

  fetchFilterOptions() {
    this.fetchPracticeLoadOptions();
  }

  getPracticesUrl = API_ENDPOINTS.PRACTICES;
 
  fetchPracticeLoadOptions() {
    // Make an API call to fetch practice load options
    this.http.get<any>(this.getPracticesUrl, {params: { pageSize: 20 }}).subscribe(response => {
      response.data=response.data.map(option => ({
        ...option,
        name: option.practiceCode
    })).sort((a, b) => a.name.localeCompare(b.name));
      this.gridConfig.filters.find(filter => filter.jsonKey === 'practiceId').options = response;
    });
  }

  fetchStatusOptions() {
    // Make an API call to fetch practice load options
    this.http.get<any>(this.optionGetUrlStatus, {params: { pageSize: 20 }}).subscribe(response => {
      response.data = response.data
          .map((option) => ({
            ...option,
            name: option.source +" - "+ option.name ,
          }))
        .sort((a,b)=>a.name.localeCompare(b.name));
      response.data=response.data.filter(record => record.source === 'PMS AGENT INSTALLER');
      this.gridConfig.filters.find(filter => filter.jsonKey === 'status').options = response;
    });
  }

  getDataUrl = API_ENDPOINTS.PRACTICE_INSTALLATION_LOG;
 
  optionGetUrlPractice=API_ENDPOINTS.PRACTICES;
  optionGetUrlStatus=API_ENDPOINTS.PMS_STATUS;
  dropDownPracticeColumn="name";
  dropDownStatusColumn="description";
  routes: string[];
  private routerSubscription: Subscription;
 
  constructor(
    private routeService: RouteService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient
  ) {}
 
 
  ngAfterViewInit(): void {
    this.routerSubscription = this.routeService.getRoutes().subscribe((data) => {
      this.routes = data;
      this.routes.shift();
      if (this.routes.length == 2) {
        this.routeService.redirectTO(this.routes.join('/').concat('/practice-installation-logs'));
      }
      this.cdr.detectChanges();
    });
  }


  refreshHandler(){
    this.dynamicGrid.fetchData();
  }

  // toast related properties
  @ViewChild(ToastComponent) toastComponent!: ToastComponent;
 
 
  showToast(toast:Toast) {
    this.toastComponent.showToast(toast);
    this.refreshHandler();
  }

  ngOnDestroy(){
    this.routerSubscription.unsubscribe();
  }
  
}