<header>
  <div
    class="flex justify-between bg-black border-b-2 h-[70px] py-2 border-black"
  >
    <div class="flex items-center">
      <div class="flex mb-auto h-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-full"
          id="logo-dc"
          width="218.632"
          height="79.999"
          viewBox="0 0 218.632 79.999"
        >
          <g
            id="Group_156"
            data-name="Group 156"
            transform="translate(49.603 44.006)"
          >
            <path
              id="Path_223"
              data-name="Path 223"
              d="M55.153,63.6H53.372V60.164h-.127a6.62,6.62,0,0,1-6.1,3.943c-3.688,0-8.14-2.162-8.14-10.048,0-6.486,3.18-10.175,8.14-10.175,2.925,0,5.087,1.4,5.978,3.816h.127V34.6h1.908ZM53.245,52.024c0-3.18-2.035-6.486-5.978-6.486-4.324,0-6.359,3.688-6.359,8.394,0,5.087,1.908,8.521,6.232,8.521s6.1-3.561,6.1-6.614Z"
              transform="translate(-39 -34.6)"
              fill="white"
            />
            <path
              id="Path_224"
              data-name="Path 224"
              d="M63.894,62.022c-5.851,0-8.394-3.688-8.394-9.92,0-5.851,2.925-10.3,8.267-10.3,5.6,0,7.377,4.579,7.377,8.9v1.526H57.408c.127,5.087,2.035,8.14,6.486,8.14a5.544,5.544,0,0,0,5.215-3.434h1.781C70.253,59.733,67.71,62.022,63.894,62.022ZM69.363,50.7c0-2.8-.89-7.25-5.469-7.25-3.943,0-5.978,3.052-6.359,7.377H69.363Z"
              transform="translate(-34.514 -32.643)"
              fill="white"
            />
            <path
              id="Path_225"
              data-name="Path 225"
              d="M84.064,49.25a6.859,6.859,0,0,0-1.017-4.2c-.636-.89-1.653-1.4-3.434-1.4-4.2,0-6.1,3.434-6.1,6.741V61.587H71.6v-19.2h1.781v3.561h.127A6.667,6.667,0,0,1,79.994,42c4.579,0,5.978,2.671,5.978,6.868V61.587H84.064Z"
              transform="translate(-30.138 -32.588)"
              fill="white"
            />
            <path
              id="Path_226"
              data-name="Path 226"
              d="M91.551,37.7v5.851h5.087v1.526H91.551V58.3c0,2.035.89,3.052,3.18,3.052A10.5,10.5,0,0,0,97.02,61.1v1.653a10.776,10.776,0,0,1-2.671.254c-3.688,0-4.833-2.035-4.833-4.579V45.077H85.7V43.551h1.017c2.289,0,2.8-.382,2.8-3.816V37.7Z"
              transform="translate(-26.304 -33.757)"
              fill="white"
            />
            <path
              id="Path_227"
              data-name="Path 227"
              d="M97.59,47.369c0-2.925,2.544-5.469,6.741-5.469,5.723,0,6.614,3.688,6.614,7v8.776c0,1.908.636,2.671,1.908,2.671v1.526H112.6c-2.417,0-3.434-1.272-3.434-4.07h-.127a7.1,7.1,0,0,1-6.614,4.2c-3.434,0-5.723-1.908-5.723-5.342a5.052,5.052,0,0,1,2.417-4.579c1.781-1.017,5.087-1.4,10.048-1.781V48.768c0-2.671-.763-5.342-4.833-5.342-3.052,0-4.706,1.653-4.833,3.816H97.59Zm11.447,5.6V51.82c-5.215.382-7.758.636-8.9,1.526a3.55,3.55,0,0,0-1.526,3.18c0,2.544,1.781,3.816,4.2,3.816C105.73,60.215,109.037,57.671,109.037,52.965Z"
              transform="translate(-23.314 -32.615)"
              fill="white"
            />
            <path
              id="Path_228"
              data-name="Path 228"
              d="M112.5,34.6h1.908v29H112.5Z"
              transform="translate(-19.019 -34.6)"
              fill="white"
            />
            <path
              id="Path_229"
              data-name="Path 229"
              d="M131.482,47.369c-.254-1.908-1.908-3.816-5.087-3.816-3.943,0-6.359,3.052-6.359,8.394s1.908,8.521,6.232,8.521c3.561,0,4.706-2.162,5.342-4.451h1.908c-.636,4.07-3.434,6.1-7.25,6.1-5.6,0-8.267-3.943-8.267-9.92,0-5.851,3.052-10.3,8.394-10.3,3.18,0,6.614,1.526,7.122,5.469Z"
              transform="translate(-17.524 -32.615)"
              fill="white"
            />
            <path
              id="Path_230"
              data-name="Path 230"
              d="M132.7,51.948c0-5.6,2.925-10.048,8.267-10.048,5.851,0,8.14,4.451,8.14,10.048,0,5.851-2.8,10.048-8.14,10.048C135.117,62,132.7,57.8,132.7,51.948Zm1.908,0c0,5.087,2.035,8.521,6.232,8.521,4.07,0,6.232-3.307,6.232-8.521,0-5.087-1.908-8.521-6.232-8.521C136.9,43.426,134.608,46.86,134.608,51.948Z"
              transform="translate(-13.527 -32.615)"
              fill="white"
            />
            <path
              id="Path_231"
              data-name="Path 231"
              d="M149.4,42.382h1.781v4.324h.127A6.437,6.437,0,0,1,157.286,42h.382v1.781h-.382c-2.671,0-5.851,1.908-5.851,7.122V61.459h-1.908V42.382Z"
              transform="translate(-8.987 -32.588)"
              fill="white"
            />
            <path
              id="Path_232"
              data-name="Path 232"
              d="M159.1,42.409h1.781V45.97h.127a6.867,6.867,0,0,1,6.614-4.07c3.943,0,7.758,2.417,7.758,9.92,0,7-3.816,10.3-8.267,10.3a6.485,6.485,0,0,1-5.978-3.943h-.127V68.609H159.1Zm7.885,18.06c4.07,0,6.232-3.561,6.232-8.649,0-4.579-1.526-8.267-6.1-8.267-4.2,0-6.232,3.307-6.232,6.741V53.6C161.008,56.781,162.788,60.469,166.985,60.469Z"
              transform="translate(-6.35 -32.615)"
              fill="white"
            />
          </g>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Logo">
              <path
                id="Path_233"
                data-name="Path 233"
                d="M54.687,27.344A.342.342,0,0,0,54.345,27H51.268C29.737,27,27.687,24.951,27.687,3.42V.342A.34.34,0,0,0,27.346,0h0A.342.342,0,0,0,27,.342V3.419C27,24.95,24.951,27,3.42,27H.343A.341.341,0,0,0,0,27.34v0H0a.342.342,0,0,0,.342.342H3.419C24.95,27.686,27,29.736,27,51.267v3.077a.341.341,0,0,0,.34.343h0a.342.342,0,0,0,.342-.342V51.267c0-21.531,2.05-23.581,23.581-23.581h3.077a.341.341,0,0,0,.343-.34v0Z"
                fill="#9cf"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>

    <div class="flex items-center justify-between gap-5 pr-5">
      <div class="relative ml-3">
        <div>
          <button
            type="button"
            class="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            id="user-menu-button"
            [ngClass]="{
              'outline-none ring-2 ring-white ring-offset-2 ring-offset-gray-800':
                showMenu
            }"
            aria-expanded="false"
            aria-haspopup="true"
            (click)="toggleMenu()"
          >
            <span class="absolute -inset-1.5"></span>
            <span class="sr-only">Open user menu</span>
            <img
              class="h-8 w-8 rounded-full"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </button>
        </div>

        <!--
          Dropdown menu, show/hide based on menu state.

          Entering: "transition ease-out duration-100"
            From: "transform opacity-0 scale-95"
            To: "transform opacity-100 scale-100"
          Leaving: "transition ease-in duration-75"
            From: "transform opacity-100 scale-100"
            To: "transform opacity-0 scale-95"
        -->

        <div
          class="absolute right-0 mt-2 z-40 w-64 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu-button"
          tabindex="-1"
          [ngClass]="{ hidden: !showMenu }"
          [@menuAnimation]="showMenu ? 'visible' : 'hidden'"

        >
          <!-- Active: "bg-gray-100", Not Active: "" -->
          
          <div class="flex w-full mx-2 px-2 py-2 items-center whitespace-nowrap">
            <div class="w-[25%]">
              <img
                class="h-12 w-12 rounded-full"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
            </div>
            <div class="w-[75%] overflow-hidden">
              <h3 class="text-ellipsis header-font overflow-hidden w-[90%]  mr-2">Name Surname</h3>
              <p class="regular-font text-gray-400 text-ellipsis overflow-hidden w-[90%] mr-2">{{'use@useremail.com'}}</p>
            </div>
          </div>

          <div>
            <div
              class="cursor-pointer flex items-center gap-3 rounded-md mx-2 px-2 py-2 my-1 regular-font text-gray-700 hover:bg-indigo-100 hover:text-indigo-500 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                height="16"
                width="16"
                fill="currentColor"
              >
                <path
                  d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                />
              </svg>
              <a href="#" role="menuitem" tabindex="-1" id="user-menu-item-0"
                >Your Profile</a
              >
            </div>

            <!-- <div
              class="cursor-pointer flex items-center gap-3 regular-font rounded-md mx-2 px-2 py-2 my-1 text-gray-700 hover:bg-indigo-100 hover:text-indigo-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                height="16"
                width="16"
                fill="currentColor"
              >
                <path
                  d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
                />
              </svg>
              <a href="#" role="menuitem" tabindex="-1" id="user-menu-item-1"
                >Settings</a
              >
            </div> -->

            <div
              class="cursor-pointer flex items-center gap-3 rounded-md mx-2 px-2 py-2 my-1 regular-font text-gray-700 hover:bg-indigo-100 hover:text-indigo-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                height="16"
                width="16"
                fill="currentColor"
              >
                <path
                  d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
                />
              </svg>

              <a href="#" role="menuitem" tabindex="-1" id="user-menu-item-2"
                >Sign out</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
