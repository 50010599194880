import { Injectable } from '@angular/core';
import {
  ActivationEnd,
  ChildActivationEnd,
  Event,
  NavigationEnd,
  Router,
  Scroll,
} from '@angular/router';
import { Observable, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  constructor(private router: Router) {}

  getRoutes(): Observable<string[]> {
    return this.router.events.pipe(
      filter(
        (event: Event): event is NavigationEnd | Scroll =>
          event instanceof NavigationEnd || event instanceof Scroll
      ),
      map((event: NavigationEnd | Scroll) => {
        return event instanceof NavigationEnd
          ? event.url?.trim().split('/') || []
          : event.routerEvent.url.trim().split('/') || [];
      })
    );
  }

  redirectTO(path: string) {
    this.router.navigate([`${path}`]).then(() => {});
  }
}
