import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, HostListener, Inject } from '@angular/core';
import { RouteService } from '../../service/route.service';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { BREADCRUMB_NAMES } from '../shared/constants/breadCrumbPaths';
import { HttpClient } from '@angular/common/http';
import { UserLogService } from '../../user-log.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('menuAnimation', [
      state(
        'hidden',
        style({
          opacity: 0,
          transform: 'scale(0.95)',
        })
      ),
      state(
        'visible',
        style({
          opacity: 1,
          transform: 'scale(1)',
        })
      ),
      transition('hidden => visible', animate('100ms ease-out')),
      transition('visible => hidden', animate('100ms ease-in')),
    ]),
  ],
})
export class HomeComponent {
  currentRoutes!: string[];
  user: any;
  showMenu: boolean = false;
  isConfirmationModalVisible: boolean;

  constructor(
    private routeService: RouteService,
    private _oktaStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private oktaAuthStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private http: HttpClient,
    private userLogService: UserLogService 
  ) {
    this.routeService.getRoutes().subscribe((routes: string[]) => {
      this.currentRoutes = routes;
      this.currentRoutes.shift();
    });
  }

  async ngOnInit() {
    const userClaims = await this.oktaAuth.getUser();
    this.user = {
      name: userClaims.name,
      email: userClaims.email,
    };
  }
  toggleMenu(event: Event): void {
    event.stopPropagation();
    this.showMenu = !this.showMenu;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (this.showMenu) {
      this.showMenu = false;
    }
  }

  getInitials(name: string): string {
    const names = name?.split(' ');
    const initials = names
      ?.map((name) => name?.charAt(0))
      .join('')
      .toUpperCase();
    return initials;
  }

  // toggleMenu(): void {
  //   this.showMenu = !this.showMenu;
  // }

  getBreadCrumbPath(index: number): string {
    return (
      this.currentRoutes
        ?.map((e, i) => {
          if (i <= index) {
            return e;
          }
          return '';
        })
        .join('/') ?? ''
    );
  }

  getBreadCrumbName(path: string): string {
    return BREADCRUMB_NAMES[path] ?? '';
  }

  handleLogout(){
    this.isConfirmationModalVisible = true;
  }

  public async logout(): Promise<void> {
    try {
      const userClaims = await this.oktaAuth.getUser();

      const ipAddress = await this.http.get<{ ip: string }>('https://api.ipify.org?format=json').toPromise();

      const userLog = {
        externalUserId: userClaims.sub,
        action: 'logout',
        browser: navigator.userAgent,
        device: this.getDeviceType(),
        ipAddress: ipAddress?.ip,  
        // userId: null, 
        createdAt: new Date().toISOString()
      };

      await this.userLogService.createUserLog(userLog).toPromise();

      await this._oktaAuth.signOut();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  }

  private getDeviceType(): string {
    return /Mobi|Android/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop';
  }


  handleModalAction(action: 'confirm' | 'cancel'){
    this.isConfirmationModalVisible = false;
    if(action === 'confirm'){
      this.logout();
    }
  }
}