import { Injectable } from '@angular/core';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class PracticeLoadDataService {
  constructor() {}

  private practiceLoadId: string = '';
  private loadDate: string = '';
  private loadTypeId: string = '';
  private loadType: string = '';
  private practiceName: string = '';
  private pmsId: string = '';
  private practiceCode: string = '';

  getPracticeLoadId(): string {
    return this.practiceLoadId;
  }

  setPracticeLoadId(practiceLoadId: string): void {
    this.practiceLoadId = practiceLoadId;
  }

  getLoadDate(): string {
    return this.loadDate;
  }

  setLoadDate(utcDate: string): void {
    // Format date in the same way as it's displayed in the grid
    if (utcDate) {
      const userTimeZone = moment.tz.guess();
      const userDateTime = moment.utc(utcDate).tz(userTimeZone);
      this.loadDate = userDateTime.format('YYYY-MM-DD HH:mm');
    } else {
      this.loadDate = '';
    }
  }

  getLoadTypeId(): string {
    return this.loadTypeId;
  }

  setLoadTypeId(loadTypeId: string): void {
    this.loadTypeId = loadTypeId;
  }

  getPracticeName(): string {
    return this.practiceName;
  }

  setPracticeName(practiceName: string): void {
    this.practiceName = practiceName;
  }

  getPracticeCode(): string {
    return this.practiceCode;
  }

  setPracticeCode(practiceCode: string): void {
    this.practiceCode = practiceCode;
  }

  getLoadType(): string {
    return this.loadType;
  }

  setLoadType(loadType: string): void {
    this.loadType = loadType;
  }

  setPMSId(practiceId: string): void {
    this.pmsId = practiceId;
  }

  getPMSId(): string {
    return this.pmsId;
  }
}
