import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getDashboardData(url: string): Observable<any> {
    return this.http.get(url);
  }

  getLoadDataStatus(url: string, params?: any): Observable<any> {
    return this.http.get(url, { params: params });
  }

  getDashboardLoadSummary(url: string, params?: any) {
    return this.http.get(url, { params: params });
  }
}
