<app-drawer
  #drawerComponent
  [drawerModel]="drawerData"
  [drawerConfig]="drawerInputConfig"
  [AddDataURL]="addDataUrl"
  [editDataURL]="editDataUrl"
  (refresh)="refreshHandler()"
  [createDrawerTitle]="'Create New Practice Load Configuration'"
  [updateDrawerTitle]="'Update Practice Load Configuration'"
  (resetData)="resetEntry()"
></app-drawer>
<app-dynamic-grid
  [gridConfig]="gridConfig"
  [getDataUrl]="getDataUrl"
  [toggleUrl]="toggleUrl"
  [sortBy]="'modifiedAt'"
  [sortOrder]="'desc'"
  (addClicked)="onAddHandler($event)"
  (editClicked)="onEditHandler($event)"
  (onToggleShowToastEmitter)="showToast($event)"
></app-dynamic-grid>
<app-toast></app-toast>
