import {
  Component,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef,
} from '@angular/core';
import { DrawerComponent } from '../../shared/drawer/drawer.component';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { DrawerInputConfig } from '../../../models/drawer-model';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';
import { DropdownComponent } from '../../shared/dropdown/dropdown.component';
import { Subscription } from 'rxjs';
import { RouteService } from '../../../service/route.service';
import { HttpClient } from '@angular/common/http';
import * as semver from 'semver';

export interface PMSAgentReleaseMappingData {
  id: string | null;
  releaseId: string;
  // pmsId: string;
  practiceId: string;
  // pmsName: string;
  practiceName: string;
  version: string;
  releaseNote: string;
  releaseDate: Date;
  isActive: boolean;
  createdAt: Date;
  modifiedAt: Date;
  createdBy: string;
  modifiedBy: string;
  createdByUser: string;
  modifiedByUser: string;
}

@Component({
  selector: 'app-pms-agent-release-mapping',
  templateUrl: './pms-agent-release-mgmt.component.html',
  styleUrls: ['./pms-agent-release-mgmt.component.scss'],
})
export class PMSAgentReleaseMgmtComponent {
  drawerData: PMSAgentReleaseMappingData = {
    id: null,
    releaseId: null,
    // pmsId: null,
    practiceId: null,
    // pmsName: 'None',
    practiceName: 'All',
    version: 'Select PMS Agent Version',
    releaseNote: '',
    releaseDate: null,
    isActive: true,
    createdAt: new Date(),
    modifiedAt: null,
    createdBy: null,
    modifiedBy: null,
    createdByUser: '',
    modifiedByUser: '',
  };

  @ViewChild(DrawerComponent)
  drawerComponent!: DrawerComponent<PMSAgentReleaseMappingData>;
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;
  @ViewChildren(DropdownComponent) dropdown!: DropdownComponent;
  @ViewChild(ToastComponent) toastComponent: ToastComponent;

  gridConfig: GridConfig = {
    columns: [
      {
        label: 'Active',
        jsonKey: 'isActive',
        dataType: 'toggle',
        isSortable: false,
      },
      {
        label: 'Hotfix',
        jsonKey: 'isCritical',
        dataType: 'toggle',
        isSortable: false,
      },
      { label: 'Practice Code', jsonKey: 'practiceCode', dataType: 'string' },
      {
        label: 'Practice Name',
        jsonKey: 'practiceName',
        dataType: 'text-wrap',
      },
      {
        label: 'PMS Agent Version',
        jsonKey: 'version',
        dataType: 'string',
        isSortable: false,
      },
      // { label: 'PMS', jsonKey: 'pmsName', dataType: 'string' },
      {
        label: 'Created By',
        jsonKey: 'createdByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Created At',
        jsonKey: 'createdAt',
        dataType: 'date',
        hideOnToggle: true,
      },
      // {
      //   label: 'Modified By',
      //   jsonKey: 'modifiedByUserEmail',
      //   dataType: 'string',
      // },
      // { label: 'Modified At', jsonKey: 'modifiedAt', dataType: 'date' },
      { label: 'Release Date', jsonKey: 'releaseDate', dataType: 'dateOnly' },
      {
        label: 'Release Notes',
        jsonKey: 'releaseNote',
        dataType: 'text-wrap',
        isMarkDownSupported: true,
        isSortable: false,
      },
    ],
    filters: [
      {
        label: 'PMS Agent Release',
        jsonKey: 'releaseId',
        type: 'multi',
        options: [],
        width: 'w-50',
      },
    ],
    editable: false,
    isToggleDisabled: true,
    deletable: false,
    displayAdd: true,
    displayHideAuditColumnsToggle: true,
  };

  getDataUrl = API_ENDPOINTS.PMS_AGENT_RELEASE_MAPPING;
  toggleUrl = API_ENDPOINTS.TOGGLE_PMS_AGENT_RELEASE_MAPPING_ACTIVE;
  addDataUrl = API_ENDPOINTS.PMS_AGENT_RELEASE_MAPPING;
  editDataUrl = API_ENDPOINTS.PMS_AGENT_RELEASE_MAPPING;
  getRelease = API_ENDPOINTS.PMS_AGENT_RELEASE;

  drawerInputConfig: DrawerInputConfig[] = [
    {
      label: 'PMS Agent Version',
      jsonKey: 'version',
      input: 'select',
      dataType: 'select',
      foreignKeyProperty: 'releaseId',
      dropDownOptionsURL: API_ENDPOINTS.PMS_AGENT_RELEASE,
      dropdownSortDirection: 'desc',
      dropdownJsonKey: 'displayVersion',
      uniqueIdentifier: 'Version',
      isRequired: true,
    },
    // {
    //   label: 'PMS',
    //   jsonKey: 'pmsName',
    //   input: 'select',
    //   dataType: 'select',
    //   foreignKeyProperty: 'pmsId',
    //   dropDownOptionsURL: API_ENDPOINTS.PMS_MASTER,
    //   dropdownJsonKey: 'pmsNameVersion',
    //   isRequired: false,
    //   showNone:true,
    //   dependentDropDownConfig: {
    //     dependentDropDownKey: 'releaseMappingPracticeName',
    //     dependentDropDownURL: `${
    //       API_ENDPOINTS.PRACTICES_DROPDOWN + '/dropdown'
    //     }`,
    //     dependentDropDownParam: 'pmsId',
    //   },
    //   uniqueIdentifier: 'releaseMappingPMSName',
    // },
    {
      label: 'Practice',
      jsonKey: 'practiceName',
      input: 'select',
      dataType: 'text-wrap',
      foreignKeyProperty: 'practiceId',
      dropDownOptionsURL: `${API_ENDPOINTS.PRACTICES}`,
      dropdownJsonKey: 'practiceNameWithPMS',
      dropdownSortDirection: 'asc',
      uniqueIdentifier: 'releaseMappingPracticeName',
      isRequired: false,
      showNone: true,
    },
    // {
    //   label: 'Active',
    //   jsonKey: 'isActive',
    //   input: 'toggle',
    //   dataType: 'boolean',
    // },
  ];

  private routerSubscription: Subscription;
  routes: string[] = [];
  constructor(
    private routeService: RouteService,
    private cdr: ChangeDetectorRef,
    private _http: HttpClient
  ) {}

  public get http(): HttpClient {
    return this._http;
  }
  public set http(value: HttpClient) {
    this._http = value;
  }

  ngAfterViewInit(): void {
    this.routerSubscription = this.routeService
      .getRoutes()
      .subscribe((data) => {
        this.routes = data;
        this.routes.shift();
        if (this.routes.length == 2) {
          this.routeService.redirectTO(this.routes.join('/').concat('/assign'));
        }
        this.cdr.detectChanges();
      });
  }

  onAddHandler(addClicked: boolean): void {
    if (addClicked) {
      this.drawerComponent.drawerType = 'add';
      this.resetEntry();
      this.openDrawer();
    }
  }

  openDrawer() {
    this.drawerComponent.openDrawer();
  }

  onEditHandler(rowData: any): void {
    if (this.drawerComponent) {
      this.drawerComponent.drawerType = 'edit';
      this.drawerComponent.drawerModel = rowData;
      this.drawerComponent.openDrawer();
    } else {
      console.error('Drawer component is not initialized');
    }
  }

  resetEntry() {
    this.drawerComponent.drawerModel = {
      id: null,
      releaseId: null,
      // pmsId: null,
      practiceId: null,
      // pmsName: 'None',
      practiceName: 'All',
      version: 'Select PMS Agent Version',
      releaseNote: '',
      releaseDate: null,
      isActive: true,
      createdAt: new Date(),
      modifiedAt: null,
      createdBy: null,
      modifiedBy: null,
      createdByUser: '',
      modifiedByUser: '',
    };
  }

  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  ngOnInit(): void {
    this.fetchReleaseOptions();
  }

  fetchReleaseOptions() {
    this._http
      .get<any>(this.getRelease, { params: { pageSize: 20 } })
      .subscribe((response) => {
        response.data = response.data
          .filter((option) => option.isActive)
          .map((option) => ({
            ...option,
            name: option.version,
          }))
          .sort((a, b) =>
            semver.compare(semver.coerce(a.version), semver.coerce(b.version))
          );
        this.gridConfig.filters.find(
          (filter) => filter.jsonKey === 'releaseId'
        ).options = response;
      });
  }

  // toast related properties
  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
    this.refreshHandler();
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
