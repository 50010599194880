<div class="w-full h-[100vh] bg-[#f5f5f9]">
  <!-- application header -->

  <div class="flex h-full w-full">
    <!-- sidebar menu -->

    <app-sidebar></app-sidebar>

    <!-- main content area  it will automatically take height of rest of screen except header -->

    <div class="ml-2 w-full h-full overflow-x-hidden">
      <!-- header section -->

      <section class="flex justify-between bg-white shadow-sm">
        <!-- BreadCrumb Section  -->

        <span class="px-4 py-4 h-fit flex items-center">
        @for (item of currentRoutes; track $index) { @if($index!==0){
          <span *ngIf="getBreadCrumbName(item)" class="flex items-center justify-center text-gray-700 header-font text-center px-2">
            <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512">
              <path fill="currentColor"
                d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
            </svg>
          </span>
          }
          <a class="header-font hover:text-indigo-500 focus:text-indigo-500 focus:outline-none" [ngClass]="{
              'text-indigo-500': $index == currentRoutes.length - 1
            }" [routerLink]="['/' + getBreadCrumbPath($index)]" routerLinkActive="router-link-active">
            {{ getBreadCrumbName(item)}}
          </a>
          }
        </span>

        <!-- profile section -->

        <div class="flex items-center justify-between gap-5 pr-5">
          <div class="relative ml-3">
            <div>
              <button type="button"
                class="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2"
                id="user-menu-button" [ngClass]="{
                  'outline-none ring-2 ring-gray-800 ring-offset-2 ring-offset-white':
                    showMenu
                }" aria-expanded="false" aria-haspopup="true" (click)="toggleMenu($event)">
                <span class="absolute -inset-1.5"></span>
                <span class="sr-only">Open user menu</span>
                <div class="h-8 w-8 rounded-full bg-indigo-400 text-white flex items-center justify-center">
                  <span class="text-lg font-bold">{{ getInitials(user?.name) }}</span>
                </div>
              </button>
            </div>

            <!--
              Dropdown menu, show/hide based on menu state.
    
              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->

            <div
              class="absolute right-0 z-20 mt-2 w-64 origin-top-right  bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1"
              [ngClass]="{ hidden: !showMenu }" [@menuAnimation]="showMenu ? 'visible' : 'hidden'">
              <!-- Active: "bg-gray-100", Not Active: "" -->

              <div class="flex w-full mx-2 px-2 py-2 items-center whitespace-nowrap">
                <div class="w-[25%]">
                  <div class="h-12 w-12 rounded-full bg-indigo-500 text-white flex items-center justify-center">
                    <span class="text-lg font-bold">{{ getInitials(user?.name) }}</span>
                  </div>
                </div>
                <div class="w-[75%] overflow-hidden">
                  <h3 class="text-ellipsis overflow-hidden w-[90%] mr-2">
                    {{ user?.name }}
                  </h3>
                  <p class="text-sm text-gray-400 text-ellipsis overflow-hidden w-[90%] mr-2">
                    {{ user?.email }}
                  </p>
                </div>
              </div>
 
                <div
                  class="cursor-pointer flex items-center gap-3  mx-2 px-2 py-2 my-1 regular-font text-gray-700 hover:bg-indigo-100 hover:text-indigo-500 rounded-md">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="16" width="16"
                    fill="currentColor">
                    <path
                      d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                  </svg>
                  <a routerLink="/profile" role="menuitem" tabindex="-1" id="user-menu-item-0">Your Profile </a>
                </div>
                <div
                  class="cursor-pointer flex items-center gap-3  mx-2 px-2 py-2 my-1 regular-font text-gray-700 hover:bg-indigo-100 hover:text-indigo-500 rounded-md"
                  (click)="handleLogout()">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="16" width="16"
                    fill="currentColor">
                    <path
                      d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" />
                  </svg>

                  <a role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</a>
                </div> 
            </div>
          </div>
        </div>
      </section>

      <section>
        <!-- <app-configuration></app-configuration> -->
        <router-outlet></router-outlet>
      </section>
    </div>
  </div>
</div>

<app-confirmation-modal
  [isVisible]="isConfirmationModalVisible"
  (onActionEventEmitter)="handleModalAction($event)"
  [title]="'Sign out'"
  [message]="'Are you sure you want to sign out?'"
></app-confirmation-modal>