import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { RouteService } from '../../../service/route.service';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { Subscription, sampleTime, timestamp } from 'rxjs';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pms-agent-health-status',
  templateUrl: './pms-agent-health-status.component.html',
  styleUrl: './pms-agent-health-status.component.scss',
})
export class PmsAgentHealthStatusComponent {
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;

  gridConfig: GridConfig = {
    columns: [
      {
        label: 'Practice Code',
        jsonKey: 'practiceCode',
        dataType: 'text-wrap',
      },
      {
        label: 'Practice Name',
        jsonKey: 'practiceName',
        dataType: 'text-wrap',
      },
      {
        label: 'PMS Agent Version',
        jsonKey: 'latestPMSAgentVersion',
        dataType: 'string',
        isSortable:false
      },
      { label: 'PMS Name', jsonKey: 'pmsName', dataType: 'string' },
      {
        label: 'Latest PMS Version',
        jsonKey: 'latestPMSVersion',
        dataType: 'string',
      },
      { label: 'Status Code', jsonKey: 'pmsStatusCode', dataType: 'string' },
      { label: 'Status', jsonKey: 'pmsStatus', dataType: 'string',width:'full' },
      { label: 'Last Reported', jsonKey: 'createdAt', dataType: 'date' },
    ],
    filters: [
      {
        label: 'Practice',
        jsonKey: 'practice',
        type: 'multi',
        options: [],
      },
    ],

    editable: false,
    deletable: false,
    showAllPagination: true,
  };

  ngOnInit() {
    this.fetchFilterOptions();
  }

  fetchFilterOptions() {
    this.fetchPracticeLoadOptions();
  }

  getPracticesUrl = API_ENDPOINTS.PRACTICES;

  fetchPracticeLoadOptions() {
    // Make an API call to fetch practice load options
    this.http
      .get<any>(this.getPracticesUrl, { params: { pageSize: 20 } })
      .subscribe((response) => {
        response.data = response.data
          .map((option) => ({
            ...option,
            name: option.practiceCode,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        this.gridConfig.filters.find(
          (filter) => filter.jsonKey === 'practice'
        ).options = response;
      });
  }

  getDataUrl = API_ENDPOINTS.AGENT_HEALTH_STATUS;

  optionGetUrlPractice = API_ENDPOINTS.PRACTICES;
  optionGetUrlStatus = API_ENDPOINTS.PMS_STATUS;
  dropDownPracticeColumn = 'name';
  dropDownStatusColumn = 'description';
  routes: string[];
  private routerSubscription: Subscription;

  constructor(
    private routeService: RouteService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient
  ) {}

  //  method to set PMSAgentHealthCheck page as default in roports
  ngAfterViewInit(): void {
    this.routerSubscription = this.routeService
      .getRoutes()
      .subscribe((data) => {
        this.routes = data;
        this.routes.shift();
        if (this.routes.length == 2) {
          this.routeService.redirectTO(
            this.routes.join('/').concat('/pms-agent-health-status')
          );
        }
        this.cdr.detectChanges();
      });
  }

  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  @ViewChild(ToastComponent) toastComponent!: ToastComponent;

  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
    this.refreshHandler();
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
