import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as XLSX from 'xlsx';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ViewChild } from '@angular/core';
import { Toast, ToastComponent } from '../toast/toast.component';
import { MatSort, SortDirection } from '@angular/material/sort';
import { FilterConfig, GridConfig } from './grid-model/grid-config.model';
import { GridDataService } from './grid-service/grid-data.service';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import moment from 'moment';
import 'moment-timezone';
import cronstrue from 'cronstrue';
import { marked } from 'marked';
import { Router } from '@angular/router';
import {
  ACKNOWLEDGEABLE_ERROR_STATUS_CODES,
  MAX_INT_32,
  ACKNOWLEDGEABLE_SUCCESS_STATUS_CODES,
} from '../constants/values';
import { BREADCRUMB_NAMES } from '../constants/breadCrumbPaths';

@Component({
  selector: 'app-dynamic-grid',
  templateUrl: './dynamic-grid.component.html',
  styleUrls: ['./dynamic-grid.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicGridComponent implements OnInit {
  @Input() gridConfig!: GridConfig;
  @Input() getDataUrl!: string;
  @Input() postUrl!: string;
  @Input() deleteUrl!: string;
  @Input() toggleUrl!: string;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Output() addClicked = new EventEmitter<boolean>();
  @Output() editClicked = new EventEmitter<any>();
  @ViewChild(ToastComponent) toastComponent!: ToastComponent;
  @Input() onAdd!: Function;
  @Input() onEdit!: Function;
  @Output() onToggleShowToastEmitter: EventEmitter<Toast> =
    new EventEmitter<Toast>();
  @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();
  dataSource = new MatTableDataSource();
  private searchSubject = new Subject<string>();
  displayedColumns!: string[];
  isDropdownOpen: boolean = false;
  hasFilters: boolean;
  isExportMenuOpen: boolean;
  isLoading: boolean = true;
  pageNumber: number = 1;
  pageSize: number = 20;
  searchQuery: string = '';
  @Input() sortBy: string = '';
  showErrorCard: boolean = false;
  errorTitle: string = '';
  errorMessage: string = '';
  @Input() sortOrder: string = '';
  totalItems: number;
  isServerSidePagination: boolean = true;
  pageChanged: boolean = true;
  pageSizeOptions: number[] = [20, 50, 100, MAX_INT_32];
  refreshTimeout: any;
  filters: { [key: string]: any } = {};
  isFilterDropdownOpen: { [key: string]: boolean } = {};
  customDateRange = {
    from: '',
    to: '',
  };
  isSelected: any;
  userRole = localStorage.getItem('userRole');
  userEmail = localStorage.getItem('userEmail');
  filtered: { [key: string]: any } = {};
  appliedFilter: boolean = false;
  resetAll: boolean = false;
  uniqueTableName: string;
  hideAuditColumns: boolean =
    JSON.parse(localStorage.getItem('hideAuditColumns')) || false;
  emptyDataMessage: string = 'No data found at this moment';
  private _totalRecordsCount: number = 0;

  constructor(
    private gridDataService: GridDataService,
    public dialog: MatDialog,
    private _router: Router,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}
  showDropdown = false;
  public get router(): Router {
    return this._router;
  }
  public set router(value: Router) {
    this._router = value;
  }
  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    this.isDropdownOpen = false;
    Object.keys(this.isFilterDropdownOpen).forEach((key) => {
      this.isFilterDropdownOpen[key] = false;
    });
    this.showDropdown = false;
  }

  ngOnInit(): void {
    this.hasFilters = this.gridConfig.filters == undefined ? false : true;
    this.uniqueTableName = this.gridConfig.uniqueTableName;
    this.fetchData();
    this.setupColumns();
    const currentDateTime = new Date();
    this.customDateRange.from = this.formatDateTime(currentDateTime);
    this.customDateRange.to = this.formatDateTime(currentDateTime);

    this.searchSubject.pipe(debounceTime(500)).subscribe((searchQuery) => {
      this.searchQuery = searchQuery;
      this.pageNumber = 1;
      this.fetchData();
    });

    this.configureMarked();
    this.setAuditColumnToggleState();
  }

  formatNumber(value: number | null): string | number {
    return value ? new Intl.NumberFormat('en-US').format(value) : value;
  }

  async fetchData(): Promise<void> {
    this.isLoading = true;
    try {
      let response = await this.gridDataService
        .fetchData(
          this.getDataUrl,
          this.pageNumber,
          this.pageSize,
          this.searchQuery,
          this.sortBy,
          this.sortOrder,
          this.filters
        )
        .toPromise();

      if (response.isSuccess === false) {
        this.handleError({
          status: response.statusCode,
          error: {
            errorMessage: response.errorMessage,
            detailedErrorMessage: response.detailedErrorMessage,
          },
        });
        return;
      }
      if (this.uniqueTableName === 'UserRoleManagement') {
        response.data = response.data.filter(
          (item) => item.email != this.userEmail
        );
        response.totalRecordsCount = response.totalRecordsCount
          ? response.totalRecordsCount - 1
          : response.totalRecordsCount;
      }
      if (this.gridConfig?.showAllPagination === false) {
        this.pageSizeOptions.pop();
      }
      if (!this.appliedFilter) {
        localStorage.setItem('choosenFilters', JSON.stringify(this.filters));
      }
      if (
        this.uniqueTableName === 'LoadSchedule' ||
        this.uniqueTableName === 'PracticeLoadConfiguration'
      ) {
        response.data.length > 0 &&
          response.data.map((e) => {
            e.description = this.getCronDescription(e.startCronExpression);
          });
      }

      this._totalRecordsCount = response.totalRecordsCount;
      
      this.totalItems = response.totalRecordsCount;
      this.paginator.length = this.totalItems;
      this.dataSource.data = response.data;

      // Check if data is empty
      if (response.data.length === 0) {
        this.emptyDataMessage = 'There are no items to display at the moment.';
      } else {
        this.emptyDataMessage = '';
      }

      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
    } catch (error) {
      this.handleError(error);
    }
  }

  handleError(error: any): void {
    this.showErrorCard = true;
    this.isLoading = false;

    if (error.error && error.error.errorMessage) {
      this.errorTitle = error.error.errorMessage;
      this.errorMessage =
        error.error.detailedErrorMessage ||
        'No detailed error message provided.';
    } else if (error.status === 408) {
      this.errorTitle = 'Request Timeout';
      this.errorMessage =
        'The server is taking too long to respond. Please check your internet connection and try again.';
    } else if (error.status === 403) {
      this.errorTitle = 'Forbidden';
      this.errorMessage =
        "You don't have permission to access this information. If you believe this is a mistake, please contact support.";
    } else if (error.status === 404) {
      this.errorTitle = 'Not Found';
      this.errorMessage =
        'The requested information could not be found. It may have been moved or deleted.';
    } else if (error.status >= 500) {
      this.errorTitle = 'Internal Server Error';
      this.errorMessage =
        'There was a problem with the server. Please try again later or contact support if the problem persists.';
    } else {
      this.errorTitle = 'Unexpected Error';
      this.errorMessage =
        'An unexpected error occurred. Please try again or contact support if the problem continues.';
    }

    console.error('Error occurred:', error);
    this.changeDetectorRef.detectChanges();
  }
  onReloadAfterError() {
    this.closeErrorCard();
    this.fetchData();
  }

  closeErrorCard() {
    this.showErrorCard = false;
    this.changeDetectorRef.detectChanges();
  }

  onFilterChange(filterKey: string, selectedOptions: any[]): void {
    this.filters[filterKey] = selectedOptions;
    this.pageNumber = 1;
    this.fetchData();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    for (let key in this.isFilterDropdownOpen) {
      this.isFilterDropdownOpen[key] = false;
    }
    if (this.appliedFilter) {
      let filtersFromStorage = localStorage.getItem('choosenFilters');
      let convertedFilter = JSON.parse(filtersFromStorage);
      this.filters = convertedFilter;
    } else {
      this.filters = {};
      localStorage.setItem('choosenFilters', JSON.stringify(this.filters));
    }
    this.isDropdownOpen = false;
  }

  toggleFilterDropdown(filterKey: string, event: Event): void {
    for (let key in this.isFilterDropdownOpen) {
      if (filterKey !== key) {
        this.isFilterDropdownOpen[key] = false;
      }
    }
    this.isFilterDropdownOpen[filterKey] =
      !this.isFilterDropdownOpen[filterKey];
  }

  onFilterOptionClick(filter: FilterConfig, option: any): void {
    if (filter.type === 'single') {
      this.filters[filter.jsonKey] = option.id;
    } else if (filter.type === 'multi') {
      if (!this.filters[filter.jsonKey]) {
        this.filters[filter.jsonKey] = [];
      }
      const index = this.filters[filter.jsonKey].indexOf(option);
      if (index === -1) {
        this.filters[filter.jsonKey].push(option);
      } else {
        this.filters[filter.jsonKey].splice(index, 1);
      }
    }
    this.pageNumber = 1;
  }

  isFilterOptionSelected(filterConfig: FilterConfig, option: any): boolean {
    return this.filters[filterConfig.jsonKey]?.some(
      (selectedOption: any) => selectedOption.id === option.id
    );
  }

  getDateFilterOptions(
    filterConfig: FilterConfig
  ): { label: string; value: string }[] {
    return [
      { label: 'Last 24 hours', value: 'last24hours' },
      { label: 'Last 7 days', value: 'last7days' },
      { label: 'Last 30 days', value: 'last30days' },
      { label: 'Custom', value: 'custom' },
    ];
  }
  onDateFilterOptionChange(
    filterConfig: FilterConfig,
    selectedOption: string
  ): void {
    if (selectedOption === 'custom') {
      const currentDateTime = new Date();
      this.customDateRange.from = this.formatDateTime(currentDateTime);
      this.customDateRange.to = this.formatDateTime(currentDateTime);
      this.filters[filterConfig.jsonKey] = selectedOption;
      this.isSelected = 'custom';
    } else {
      const currentDate = new Date();
      let startDate: Date;

      switch (selectedOption) {
        case 'last24hours':
          startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
          this.isSelected = 'last24hours';
          break;
        case 'last7days':
          startDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
          this.isSelected = 'last7days';
          break;
        case 'last30days':
          startDate = new Date(
            currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
          );
          this.isSelected = 'last30days';
          break;
        default:
          startDate = currentDate;
      }

      this.filters[filterConfig.jsonKey] = {
        startDate: startDate.toISOString(),
        endDate: currentDate.toISOString(),
      };

      this.pageNumber = 1;
    }
  }

  hasFilterOptionsSelected(filterConfig: FilterConfig): boolean {
    if (filterConfig.type === 'single') {
      return !!this.filters[filterConfig.jsonKey];
    } else if (filterConfig.type === 'multi') {
      return !!this.filters[filterConfig.jsonKey]?.length;
    } else if (filterConfig.type === 'date') {
      return !!this.filters[filterConfig.jsonKey];
    }
    return false;
  }
  getAppliedFilterCount(filterConfig: FilterConfig): number {
    if (filterConfig.type === 'single') {
      return this.filters[filterConfig.jsonKey] ? 1 : 0;
    } else if (filterConfig.type === 'multi') {
      return this.filters[filterConfig.jsonKey]?.length || 0;
    } else if (filterConfig.type === 'date') {
      return this.filters[filterConfig.jsonKey] ? 1 : 0;
    }
    return 0;
  }

  getDateFilterLabel(filterConfig: FilterConfig): string {
    if (!this.filters[filterConfig.jsonKey]) {
      return '';
    }
    if (typeof this.filters[filterConfig.jsonKey] === 'string') {
      switch (this.isSelected) {
        case 'last24hours':
          return 'Last 24 hours';
        case 'last7days':
          return 'Last 7 days';
        case 'last30days':
          return 'Last 30 days';
        case 'custom':
          const from = new Date(this.customDateRange.from);
          const to = new Date(this.customDateRange.to);
          const diffDays = Math.ceil(
            (to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24)
          );
          return `Last ${diffDays} days`;
      }
    } else if (typeof this.filters[filterConfig.jsonKey] === 'object') {
      const from = new Date(this.filters[filterConfig.jsonKey].startDate);
      const to = new Date(this.filters[filterConfig.jsonKey].endDate);
      const diffDays = Math.ceil(
        (to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24)
      );
      return `Last ${diffDays} days`;
    } else if (this.customDateRange.from && this.customDateRange.to) {
      const from = new Date(this.customDateRange.from);
      const to = new Date(this.customDateRange.to);
      const diffDays = Math.ceil(
        (to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24)
      );
      return `Last ${diffDays} days`;
    }

    return '';
  }

  setupColumns(): void {
    this.displayedColumns =
      this.gridConfig.editable || this.gridConfig.deletable
        ? ['action', ...this.gridConfig.columns.map((c) => c.jsonKey)]
        : this.gridConfig.columns.map((c) => c.jsonKey);
  }

  applyFilter(filterValue: string) {
    const searchQuery = filterValue.trim().toLowerCase();
    this.searchSubject.next(searchQuery);
  }

  onFilterOptionChange(
    filterConfig: FilterConfig,
    option: any,
    checked: boolean
  ): void {
    if (!this.filters[filterConfig.jsonKey]) {
      this.filters[filterConfig.jsonKey] = [];
    }
    if (checked) {
      this.filters[filterConfig.jsonKey].push(option);
    } else {
      const index = this.filters[filterConfig.jsonKey].findIndex(
        (selectedOption: any) => selectedOption.id === option.id
      );
      if (index !== -1) {
        this.filters[filterConfig.jsonKey].splice(index, 1);
      }
    }
  }


  formatDateTime(date:string|Date){
    if (date == null || date == '')
    return '';
    const userTimeZone = moment.tz.guess();
    const userDateTime = moment.utc(date).tz(userTimeZone);
    return userDateTime.format('YYYY-MM-DD HH:mm');
  }

  applyFilters(filterConfig): void {
    localStorage.setItem('choosenFilters', JSON.stringify(this.filters));

    this.appliedFilter = true;
    if (this.filters[filterConfig.jsonKey] === 'custom') {
      const fromDate = new Date(this.customDateRange.from);
      const toDate = new Date(this.customDateRange.to);

      toDate.setHours(23, 59, 59, 999);

      this.filters[filterConfig.jsonKey] = {
        startDate: fromDate.toISOString(),
        endDate: toDate.toISOString(),
      };
    } else if (typeof this.filters[filterConfig.jsonKey] === 'object') {
      this.customDateRange.from = this.filters[filterConfig.jsonKey].startDate;
      this.customDateRange.to = this.filters[filterConfig.jsonKey].endDate;
    }
    this.pageNumber = 1;
    this.fetchData();
    this.closeFilterDropdowns();
  }
  clearFilters(filterConfig: FilterConfig): void {
    this.filters[filterConfig.jsonKey] = [];
    this.closeFilterDropdowns();
  }

  cancelFilters(filterConfig: FilterConfig): void {
    if (this.appliedFilter) {
      let filtersFromStorage = localStorage.getItem('choosenFilters');
      let convertedFilter = JSON.parse(filtersFromStorage);
      this.filters = convertedFilter;
    } else {
      this.filters = {};
      localStorage.setItem('choosenFilters', JSON.stringify(this.filters));
    }
    this.closeFilterDropdowns();
  }

  closeFilterDropdowns(): void {
    Object.keys(this.isFilterDropdownOpen).forEach((key) => {
      this.isFilterDropdownOpen[key] = false;
    });
  }

  isAllOptionsSelected(filterConfig: FilterConfig): boolean {
    return (
      filterConfig.options?.data?.length ===
      this.filters[filterConfig.jsonKey]?.length
    );
  }

  onAllOptionsChange(filterConfig: FilterConfig, checked: boolean): void {
    if (checked) {
      this.filters[filterConfig.jsonKey] = filterConfig.options?.data.map(
        (option: any) => option
      );
    } else {
      this.filters[filterConfig.jsonKey] = [];
    }
    this.pageNumber = 1;
  }

  getSelectedDate(filterConfig: FilterConfig): Date | null {
    return this.filters[filterConfig.jsonKey]
      ? new Date(this.filters[filterConfig.jsonKey])
      : null;
  }

  onDateChange(filterConfig: FilterConfig, selectedDate: string): void {
    this.filters[filterConfig.jsonKey] = selectedDate;
  }
  isFilterApplied(filterConfig: FilterConfig): boolean {
    return (
      this.filters[filterConfig.jsonKey] &&
      this.filters[filterConfig.jsonKey].length > 0
    );
  }

  getSelectedOptionName(filterConfig: FilterConfig): string {
    const selectedOption = filterConfig.options?.data.find(
      (option: any) => option.id === this.filters[filterConfig.jsonKey]
    );
    return selectedOption ? selectedOption.name : '';
  }

  getSelectedOptionNames(filterConfig: FilterConfig): string {
    if (this.isAllOptionsSelected(filterConfig)) {
      return 'All';
    }
    const selectedOptions = filterConfig.options?.data?.filter((option: any) =>
      this.filters[filterConfig.jsonKey]?.some(
        (selectedOption: any) => selectedOption.id === option.id
      )
    );
    const selectedOptionNames =
      selectedOptions?.map((option: any) => option.name) || [];
    return selectedOptionNames.length > 1
      ? `${selectedOptionNames[0]}...`
      : selectedOptionNames[0] || '';
  }

  ngAfterViewInit() {
    this.paginator.pageSize = this.pageSize;
    this.sort.active = this.sortBy;
    this.sort.direction = this.sortOrder as SortDirection;
    this.sort.start = this.sortOrder as SortDirection;
    this.dataSource.sort = this.sort;
    this.sort.sortChange.emit();
    this.changeDetectorRef.detectChanges();
    this.paginator.page.subscribe((event: PageEvent) => {
      this.paginator.length = this.totalItems;
      this.pageNumber = event.pageIndex + 1;
      this.pageSize = event.pageSize;
      this.fetchData();
    });
    this.sort.sortChange.subscribe(() => {
      this.sortBy = this.sort.active;
      this.sortOrder = this.sort.direction;
      this.pageNumber = 1;
      this.fetchData();
      this.paginator.length = this.totalItems;
    });
  }

  onAddClick(): void {
    this.addClicked.emit(true);
  }

  onEditClick(rowData: any): void {
    this.editClicked.emit({ ...rowData });
  }

  onToggleChange(element: any, jsonKey: string, toggleValue: boolean): void {
    const toggleUrl = this.gridConfig.columns.find(
      (column) => column.jsonKey === jsonKey
    )?.toggleUrl;

    const patchUrl = toggleUrl
      ? toggleUrl.replace(':id', element.id.toString())
      : this.toggleUrl;

    this.gridDataService
      .toggleData(patchUrl, element.id, jsonKey, toggleValue)
      .subscribe(
        (response) => {
          element[jsonKey] = response[jsonKey];
          const index = this.dataSource.data.findIndex(
            (item: any) => item.id === element.id
          );
          if (index !== -1) {
            this.dataSource.data[index][jsonKey] = response[jsonKey];
          }
          this.onToggleShowToastEmitter.emit({
            severity: 'success',
            title: 'Success',
            message: response?.data,
          });
        },
        (error) => {
          console.error('Error updating toggle value:', error);
          this.onToggleShowToastEmitter.emit({
            severity: 'error',
            title: 'Error',
            message: error?.error?.errorMessage,
          });
        }
      );
  }

  toggleDropdown(event: MouseEvent): void {
    event.stopPropagation();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  resetFilter() {
    this.filters = {};
    this.resetAll = true;
    localStorage.removeItem('choosenFilters');
    this.appliedFilter = false;
    this.fetchData();
  }

  exportData(format: 'xlsx'): void {
    const data = this.dataSource.data;
    const columns = this.gridConfig.columns.map(column => ({...column}));
    const headers = columns.map(column => column.label.toUpperCase());
    
    const jsonData = data.map((row: any) => {
      // Handle special cases for description computation
      if (this.uniqueTableName === 'LoadSchedule' || this.uniqueTableName === 'PracticeLoadConfiguration') {
        row = { ...row };
        if (row.startCronExpression) {
          row.description = this.getCronDescription(row.startCronExpression);
        }
      }

      const rowData: any = {};
      columns.forEach((column) => {
        const value = row[column.jsonKey];
        if (typeof value === 'boolean') {
          rowData[column.label.toUpperCase()] = value ? 'Yes' : 'No';
        } else if (column.dataType === 'date') {
          if (value) {
            const formattedDate = moment
              .utc(value, 'YYYY-MM-DD HH:mm:ss')
              .local()
              .format('YYYY-MM-DD HH:mm:ss');
            rowData[column.label.toUpperCase()] = formattedDate;
          }
        } else {
          rowData[column.label.toUpperCase()] = value;
        }
      });
      return rowData;
    });

    const worksheet = XLSX.utils.json_to_sheet(jsonData, {
      header: headers,
      skipHeader: false,
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Set column widths
    worksheet['!cols'] = columns.map(() => ({ wch: 20 }));

    const currentURLSegements = this._router.url.split('/');
    const currentPath = currentURLSegements[currentURLSegements.length - 1];
    const fileName = this.generateFilename(
      BREADCRUMB_NAMES[currentPath],
      'xlsx'
    );

    XLSX.writeFile(workbook, `${fileName}`);
    this.isDropdownOpen = false;
  }

  onDelete(element: any): void {
    // implement delete if required
  }
  generateFilename(name: string, format: 'xlsx') {
    const today = moment();
    name = name.replace(' ', '_');
    // corner case for assign and configure release
    name =
      name.includes('Assign') || name.includes('Configure')
        ? name + '_Release'
        : name;
    const dateString = today.format('YYYY-MM-DD-HH-mm-ss');
    // Concatenate the date with the name
    return `${name}_${dateString}.${format}`;
  }

  rowClick(data, event) {
    this.onRowClick.emit(data);
  }

  addAllOption() {
    if (!(this.gridConfig?.showAllPagination === false)) {
      const lastOption = document.querySelector(
        '.mat-mdc-select-panel-above .mat-mdc-option:last-child > span'
      );
      lastOption ? (lastOption.innerHTML = 'All') : null;
    }
  }

  getPageSize() {
    this.gridConfig.showAllPagination == false
      ? this.pageSizeOptions.pop()
      : null;
    return this.pageSizeOptions;
  }

  private configureMarked(): void {
    const renderer = new marked.Renderer();
    renderer.link = ({ href, title, text }) => {
      const titleAttr = title ? ` title="${title}"` : '';
      return `<a href="${href}" target="_blank" rel="noopener noreferrer"${titleAttr}>${text}</a>`;
    };
    marked.setOptions({ renderer });
  }

  getMakrDownText(isMarkDownSupported: boolean, text: string) {
    return isMarkDownSupported ? marked(text) : '';
  }

  setAuditColumnToggleState() {
    if (this.hideAuditColumns) {
      this.displayedColumns =
        this.gridConfig.editable || this.gridConfig.deletable
          ? [
              'action',
              ...this.gridConfig.columns
                .filter((e) => !e.hideOnToggle)
                .map((c) => c.jsonKey),
            ]
          : this.gridConfig.columns
              .filter((e) => !e.hideOnToggle)
              .map((c) => c.jsonKey);
    } else {
      this.displayedColumns =
        this.gridConfig.editable || this.gridConfig.deletable
          ? ['action', ...this.gridConfig.columns.map((c) => c.jsonKey)]
          : this.gridConfig.columns.map((c) => c.jsonKey);
    }
    localStorage.setItem('hideAuditColumns', this.hideAuditColumns.toString());
  }

  toggleAuditColumns() {
    this.gridConfig.columns = this.gridConfig.columns.filter(
      (e) => !e?.hideOnToggle
    );
    this.changeDetectorRef.detectChanges();
  }

  disableSuccessAcknowldgeToggle(element: any): boolean {
    if (element.pmsStatusCode) {
      return ACKNOWLEDGEABLE_SUCCESS_STATUS_CODES.includes(
        element.pmsStatusCode
      )
        ? true
        : false;
    }
    return false;
  }

  isErrorLog(element) {
    if (element.pmsStatusCode) {
      return ACKNOWLEDGEABLE_ERROR_STATUS_CODES.includes(element.pmsStatusCode)
        ? true
        : false;
    }
    return false;
  }

  getCronDescription(cronExpression: string) {
    let translatedCRON = '';
    try {
      translatedCRON = cronstrue.toString(cronExpression);
    } catch {
      translatedCRON = 'Invalid CRON Expression';
    }
    return translatedCRON;
  }

  async exportAllData(format: 'xlsx'): Promise<void> {
    this.isLoading = true;
    try {
      const totalRecords = this._totalRecordsCount;

      const response = await this.gridDataService
        .fetchData(
          this.getDataUrl,
          1,
          totalRecords,
          this.searchQuery,
          this.sortBy,
          this.sortOrder,
          this.filters
        )
        .toPromise();

      if (response.isSuccess === false) {
        this.handleError({
          status: response.statusCode,
          error: {
            errorMessage: response.errorMessage,
            detailedErrorMessage: response.detailedErrorMessage,
          },
        });
        return;
      }

      const allData = response.data;
      const columns = this.gridConfig.columns.map(column => ({...column}));
      const headers = columns.map(column => column.label.toUpperCase());
      
      const jsonData = allData.map((row: any) => {
        // Handle special cases for description computation
        if (this.uniqueTableName === 'LoadSchedule' || this.uniqueTableName === 'PracticeLoadConfiguration') {
          row = { ...row };
          if (row.startCronExpression) {
            row.description = this.getCronDescription(row.startCronExpression);
          }
        }

        const rowData: any = {};
        columns.forEach((column) => {
          const value = row[column.jsonKey];
          if (typeof value === 'boolean') {
            rowData[column.label.toUpperCase()] = value ? 'Yes' : 'No';
          } else if (column.dataType === 'date') {
            if (value) {
              const formattedDate = moment
                .utc(value, 'YYYY-MM-DD HH:mm:ss')
                .local()
                .format('YYYY-MM-DD HH:mm:ss');
              rowData[column.label.toUpperCase()] = formattedDate;
            }
          } else {
            rowData[column.label.toUpperCase()] = value;
          }
        });
        return rowData;
      });

      const worksheet = XLSX.utils.json_to_sheet(jsonData, {
        header: headers,
        skipHeader: false,
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Set column widths
      worksheet['!cols'] = columns.map(() => ({ wch: 20 }));

      const currentURLSegements = this._router.url.split('/');
      const currentPath = currentURLSegements[currentURLSegements.length - 1];
      const fileName = this.generateFilename(
        'All_' + BREADCRUMB_NAMES[currentPath],
        'xlsx'
      );

      XLSX.writeFile(workbook, `${fileName}`);

      this.isDropdownOpen = false;
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
    } catch (error) {
      this.handleError(error);
    }
  }
}
