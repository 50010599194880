import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { NIL as NIL_UUID } from 'uuid';
import {
  Component,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  HostListener,
  ElementRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MAX_INT_32 } from '../constants/values';
import * as semver from 'semver';
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: [
    trigger('menuAnimation', [
      state(
        'hidden',
        style({
          opacity: 0,
          transform: 'scale(0.95)',
        })
      ),
      state(
        'visible',
        style({
          opacity: 1,
          transform: 'scale(1)',
        })
      ),
      transition('hidden => visible', animate('100ms ease-out')),
      transition('visible => hidden', animate('100ms ease-in')),
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent implements ControlValueAccessor {
  @Input() options!: any;
  @Input() value: any = null;
  @Input() optionsURL!: string;
  @Input() dropDownJsonKey!: string;
  @Input() isRequired: boolean = false;
  @Input() disabled: boolean = false;
  @Input() uniqueIdentifier!: string;
  @Input() showNone: boolean = false;
  @Input() sortDir: 'desc' | 'asc' = 'asc';
  showDropdown: boolean = false;
  @Input() foreignKeyProperty!: string;
  @Output() valueChange = new EventEmitter<{
    id: string | NIL_UUID;
    name: string | null;
  }>();
  @Input() isSearchable: boolean = false;
  @Input() tabIndex!: number;
  searchValue: string = '';
  debounceTimer: any;
  @Input() dependentDropDownConfig: any;
  @Output() dependentDropDownChange = new EventEmitter<any>();
  @Input() id!: string;
  userEmail = localStorage.getItem('userEmail');
  isLoading: boolean = true;

  constructor(
    private _httpClient: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private eRef: ElementRef
  ) {}

  public get httpClient(): HttpClient {
    return this._httpClient;
  }
  public set httpClient(value: HttpClient) {
    this._httpClient = value;
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }

  ngOnInit() {
    this.fetchOptions();
  }

  onChange(selectedObject: any) {
    this.value = {
      id: selectedObject.id,
      name: selectedObject.name,
    };

    this.propagateChange(this.value);
    this.toggleDropdown();
    this.valueChange.emit(this.value);

    if (this.dependentDropDownConfig && selectedObject) {
      this.dependentDropDownChange.emit({
        key: this.dependentDropDownConfig.dependentDropDownKey,
        value: selectedObject.id,
      });
    }
    this.searchValue = '';
  }

  toggleDropdown() {
    if (this.disabled) {
      return;
    }
    this.showDropdown = !this.showDropdown;
  }

  fetchOptions() {
    if (this.uniqueIdentifier === 'PMSMasterName') {
      const staticOptions = [
        { id: 1, name: 'ABELDent' },
        { id: 2, name: 'Power Practice' },
        { id: 3, name: 'Tracker' },
        { id: 4, name: 'Dentrix' },
      ];

      this.options = staticOptions;

      // Filter the options based on search value if it exists
      this.options = this.searchValue
        ? this.options.filter((value: any) =>
            value.name.toLowerCase().includes(this.searchValue.toLowerCase())
          )
        : this.options;
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
    } else {
      this._httpClient
        .get<any>(this.optionsURL, {
          params: { pageSize: 20 },
        })
        .subscribe(
          (response) => {
            if (
              response.isSuccess &&
              response.data &&
              this.uniqueIdentifier !== 'UserRoleEmailDropdown' &&
              this.uniqueIdentifier !== 'PracticeMasterStatusDropDown' &&
              this.uniqueIdentifier !== 'PMSMasterName' &&
              this.uniqueIdentifier !== 'Version'
            ) {
              this.options = response.data
                .filter((item: any) => item.isActive !== false)
                .map((item: any) => ({
                  id: item.id,
                  name: item[this.dropDownJsonKey],
                }))
                .sort((a, b) =>
                  this.sortDir == 'asc'
                    ? a?.name?.localeCompare(b.name)
                    : b?.name?.localeCompare(a.name)
                );
              this.options = this.searchValue
                ? this.options.filter((value: any) =>
                    value.name
                      .toLowerCase()
                      .includes(this.searchValue.toLowerCase())
                  )
                : this.options;
            } else if (
              response.isSuccess &&
              response.data &&
              this.uniqueIdentifier === 'UserRoleEmailDropdown'
            ) {
              this.options = response.data
                .filter(
                  (item: any) =>
                    item[this.dropDownJsonKey] !== this.userEmail &&
                    item.isActive !== false
                )
                .map((item: any) => ({
                  id: item.id,
                  name: item[this.dropDownJsonKey],
                }))
                .sort((a, b) =>
                  this.sortDir == 'asc'
                    ? a?.name?.localeCompare(b.name)
                    : b?.name?.localeCompare(a.name)
                );
              this.options = this.searchValue
                ? this.options.filter((value: any) =>
                    value.name
                      .toLowerCase()
                      .includes(this.searchValue.toLowerCase())
                  )
                : this.options;
            } else if (
              response.isSuccess &&
              response.data &&
              this.uniqueIdentifier === 'PracticeMasterStatusDropDown'
            ) {
              this.options = response.data
                .filter(
                  (item: any) =>
                    item.source == 'Installer' && item.isActive !== false
                )
                .map((item: any) => ({
                  id: item.id,
                  name: item[this.dropDownJsonKey],
                }))
                .sort((a, b) =>
                  this.sortDir == 'asc'
                    ? a?.name?.localeCompare(b.name)
                    : b?.name?.localeCompare(a.name)
                );
              this.options = this.searchValue
                ? this.options.filter((value) =>
                    value.name
                      .toLowerCase()
                      .includes(this.searchValue.toLowerCase())
                  )
                : this.options;
            } else if (
              response.isSuccess &&
              response.data &&
              this.uniqueIdentifier === 'Version'
            ) {
              this.options = response.data
                .filter((item: any) => item.isActive !== false)
                .map((item: any) => ({
                  id: item.id,
                  name: item[this.dropDownJsonKey],
                }))
                .sort((a, b) =>
                  this.sortDir == 'asc'
                    ? semver.compare(
                        semver.coerce(a.name),
                        semver.coerce(b.name)
                      )
                    : semver.compare(
                        semver.coerce(b.name),
                        semver.coerce(a.name)
                      )
                );

              this.options = this.searchValue
                ? this.options.filter((value: any) =>
                    value.name
                      .toLowerCase()
                      .includes(this.searchValue.toLowerCase())
                  )
                : this.options;
            } else {
              console.error('Failed to fetch options:', response.errorMessage);
            }
            this.isLoading = false;
            // Trigger change detection
            this.changeDetectorRef.detectChanges();
          },
          (error) => {
            this.isLoading = false;
            console.error('Error fetching options:', error);
          }
        );
    }
  }

  filterOptions(event: any) {
    this.showDropdown = true;
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.fetchOptions();
    }, 200);
  }

  propagateChange = (_: any) => {};

  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
      if (this.dependentDropDownConfig) {
        this.dependentDropDownChange.emit({
          key: this.dependentDropDownConfig.dependentDropDownKey,
          value: value?.id || null,
        });
      }
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}
}
