import {
  ChangeDetectorRef,
  Component,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DrawerComponent } from '../../shared/drawer/drawer.component';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { DrawerInputConfig } from '../../../models/drawer-model';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';
import { NIL as NIL_UUID } from 'uuid';
import { DropdownComponent } from '../../shared/dropdown/dropdown.component';
import { RouteService } from '../../../service/route.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface PHIMasterData {
  id: string;
  pmsObjectId: string;
  pmsId: string;
  columnName: string;
  hashType: string;
  createdAt: Date;
  modifiedAt: Date;
  createdBy: string;
  modifiedBy: string;
  isActive: boolean;
  pmsName?: string;
  pmsVersion?: string;
  pmsObjectName?: string;
}

@Component({
  selector: 'app-phi-master',
  templateUrl: './phi-master.component.html',
  styleUrls: ['./phi-master.component.scss'],
})
export class PHIMasterComponent {
  drawerData: PHIMasterData = {
    id: NIL_UUID,
    pmsObjectId: NIL_UUID,
    pmsId: NIL_UUID,
    columnName: '',
    hashType: 'Encryption',
    createdAt: null,
    modifiedAt: null,
    createdBy: NIL_UUID,
    modifiedBy: NIL_UUID,
    isActive: false,
    pmsVersion: '',
    pmsName: 'Select PMS',
    pmsObjectName: 'Select Object',
  };

  @ViewChild(DrawerComponent) drawerComponent!: DrawerComponent<PHIMasterData>;
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;
  @ViewChildren(DropdownComponent) dropdown!: DropdownComponent;
  @ViewChild(ToastComponent) toastComponent: ToastComponent;

  gridConfig: GridConfig = {
    columns: [
      {
        label: 'Active',
        jsonKey: 'isActive',
        dataType: 'toggle',
        isSortable: false,
      },
      {
        label: 'PMS Object Name',
        jsonKey: 'pmsObjectName',
        dataType: 'string',
      },
      { label: 'PMS Name', jsonKey: 'pmsName', dataType: 'string' },
      {
        label: 'Configured PMS Version',
        jsonKey: 'pmsVersion',
        dataType: 'string',
        isSortable: false,
      },
      {
        label: 'Column Names',
        jsonKey: 'columnNames',
        dataType: 'string',
        isSortable: false,
      },
      {
        label: 'Created By',
        jsonKey: 'createdByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Created At',
        jsonKey: 'createdAt',
        dataType: 'date',
        hideOnToggle: true,
      },
      {
        label: 'Modified By',
        jsonKey: 'modifiedByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Modified At',
        jsonKey: 'modifiedAt',
        dataType: 'date',
        hideOnToggle: true,
      },
    ],
    filters: [
      {
        label: 'PMS Name',
        jsonKey: 'pmsId',
        type: 'multi',
        options: [],
        width: 'w-80',
      },
    ],
    editable: true,
    deletable: false,
    displayAdd: true,
    isToggleDisabled: true,
    displayHideAuditColumnsToggle: true,
  };

  getDataUrl = API_ENDPOINTS.PMS_OBJECT_WISE_PHI;
  toggleUrl = API_ENDPOINTS.TOGGLE_PMS_OBJECT_WISE_PHI_ACTIVE;
  addDataUrl = API_ENDPOINTS.PMS_OBJECT_WISE_PHI;
  editDataUrl = API_ENDPOINTS.PMS_OBJECT_WISE_PHI;
  getPracticesUrl = API_ENDPOINTS.PMS_MASTER;

  drawerInputConfig: DrawerInputConfig[] = [
    {
      label: 'PMS Name',
      jsonKey: 'pmsName',
      input: 'select',
      dataType: 'select',
      foreignKeyProperty: 'pmsId',
      dropDownOptionsURL: API_ENDPOINTS.PMS_MASTER,
      dropdownJsonKey: 'pmsNameVersion',
      isRequired: true,
      tabIndex: 1,
      dependentDropDownConfig: {
        dependentDropDownKey: 'phiObjectName',
        dependentDropDownURL: `${API_ENDPOINTS.PMS_OBJECT + '/dropdown'}`,
        dependentDropDownParam: 'pmsId',
      },
      dropdownSortDirection: 'asc',
      uniqueIdentifier: 'phiPMSName',
    },
    {
      label: 'PMS Object Name',
      jsonKey: 'pmsObjectName',
      input: 'select',
      dataType: 'select',
      foreignKeyProperty: 'pmsObjectId',
      dropDownOptionsURL: `${API_ENDPOINTS.PMS_OBJECT + '/dropdown'}`,
      dropdownJsonKey: 'name',
      isRequired: true,
      tabIndex: 2,
      uniqueIdentifier: 'phiObjectName',
    },
    {
      label: 'Column Names',
      jsonKey: 'columnNames',
      input: 'text',
      dataType: 'csvString',
      chips: true,
      isRequired: true,
      tabIndex: 3,
      minLength: 1,
    },
    {
      label: 'Active',
      jsonKey: 'isActive',
      input: 'toggle',
      dataType: 'boolean',
      tabIndex: 4,
    },
  ];

  onAddHandler(addClicked: boolean): void {
    if (addClicked) {
      this.drawerComponent.drawerType = 'add';
      this.resetEntry();
      this.openDrawer();
    }
  }
  private _routerSubscription: Subscription;
  public get routerSubscription(): Subscription {
    return this._routerSubscription;
  }
  public set routerSubscription(value: Subscription) {
    this._routerSubscription = value;
  }
  routes: string[] = [];
  constructor(
    private routeService: RouteService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.fetchPMSNameOptions();
  }

  ngAfterViewInit(): void {
    this._routerSubscription = this.routeService
      .getRoutes()
      .subscribe((data) => {
        this.routes = data;
        this.routes.shift();
        if (this.routes.length == 2) {
          this.routeService.redirectTO(
            this.routes.join('/').concat('/phi-records')
          );
        }
        this.cdr.detectChanges();
      });
  }

  openDrawer() {
    this.drawerComponent.openDrawer();
  }

  onEditHandler(rowData: any): void {
    rowData.pmsName = rowData.pmsName + ' ' + rowData.pmsVersion;
    if (this.drawerComponent) {
      this.drawerComponent.drawerType = 'edit';
      this.drawerComponent.drawerModel = rowData;
      this.drawerComponent.openDrawer();
    } else {
      console.error('Drawer component is not initialized');
    }
  }

  fetchPMSNameOptions() {
    this.http
      .get<any>(this.getPracticesUrl, { params: { pageSize: 20 } })
      .subscribe((response) => {
        response.data = response.data
          .filter((option) => option.isActive)
          .map((option) => ({
            ...option,
            name: option.pmsNameVersion,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        this.gridConfig.filters.find(
          (filter) => filter.jsonKey === 'pmsId'
        ).options = response;
      });
  }

  resetEntry() {
    this.drawerComponent.drawerModel = {
      id: NIL_UUID,
      pmsObjectId: NIL_UUID,
      pmsId: NIL_UUID,
      columnName: null,
      hashType: 'Encryption',
      createdAt: null,
      modifiedAt: null,
      createdBy: NIL_UUID,
      modifiedBy: NIL_UUID,
      isActive: true,
      pmsVersion: '',
      pmsName: 'Select PMS',
      pmsObjectName: 'Select Object',
    };
  }
  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
    this.refreshHandler();
  }

  ngOnDestroy() {
    this._routerSubscription.unsubscribe();
  }
}
