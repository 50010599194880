<div
  class="relative z-50"
  [ngClass]="{ hidden: !isVisible }"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <!--
      Background backdrop, show/hide based on modal state.
  
      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
  <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    [ngClass]="{
      'opacity-0 ease-in duration-200': !isVisible,
      'opacity-100 ease-out duration-300': isVisible
    }"
    aria-hidden="true"
  ></div>

  <div
    class="fixed inset-0 z-50 w-screen overflow-y-auto"
    (click)="handleOutsideClick()"
  >
    <div
      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
    >
      <!--
          Modal panel, show/hide based on modal state.
  
          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
      <div
        [id]="title"
        (click)="$event.stopPropagation()"
        class="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
        [ngClass]="{
          'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 ease-in duration-200':
            !isVisible,
          'opacity-100 translate-y-0 sm:scale-100 ease-out duration-300':
            isVisible
        }"
      >
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                <path
                  d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3l58.3 0c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24l0-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1l-58.3 0c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3
                class="text-base font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                {{ title }}
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  {{ message }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-gray-50 px-4 py-3 gap-3 sm:flex sm:flex-row-reverse sm:px-6"
        >
          <button
            type="button"
            (click)="onConfirm()"
            class="px-6 py-1.5 bg-indigo-500 text-gray-200 hover:bg-indigo-400 regular-font transition-colors duration-500 shadow-md focus:outline-none ring-offset-1 focus:ring-2"
          >
            Confirm
          </button>
          <button
            type="button"
            (click)="onCancel()"
            class="px-6 py-1.5 bg-gray-600 hover:bg-gray-500 text-gray-50 regular-font transition-colors duration-500 shadow-md focus:outline-none ring-offset-1 focus:ring-2 ring-gray-400"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
