export const environment = {
  appTitle:'Dentalcorp',
  envName:'DEV',
  production: false,
  pmsAPIBaseUrl: 'https://api-dev.dentalcorp.ca/api/v1',
  okta: {
    oidc: {
      clientId: '0oae6su5mu2w7f7iI1d7',
      issuer: 'https://dentalcorp.oktapreview.com/oauth2/default',
      redirectUri: 'https://app-dev.dentalcorp.ca/login/callback',
      scopes: ['openid', 'profile', 'email'],
      pkce: true,
      postLogoutRedirectUri: 'https://app-dev.dentalcorp.ca/'
    }
  }
};
