<div class="w-full bg-white shadow-sm relative">
  <!-- Loading screen -->
  <div
    *ngIf="isLoading"
    class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white opacity-75 flex flex-col items-center justify-center z-50"
  >
    <div
      class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500 !important"
    ></div>
  </div>

  <!--  Error Card -->
  <div
    *ngIf="showErrorCard"
    class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50"
    (click)="closeErrorCard()"
  >
    <div
      class="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full relative"
      (click)="$event.stopPropagation()"
    >
      <button
        (click)="closeErrorCard()"
        class="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
      >
        <svg
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div class="text-center">
        <svg
          class="mx-auto h-12 w-12 text-red-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <h3 class="mt-2 text-lg font-medium text-gray-900">{{ errorTitle }}</h3>
        <p class="mt-1 text-sm text-gray-500">{{ errorMessage }}</p>
      </div>
      <div class="mt-5">
        <button
          (click)="onReloadAfterError()"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
        >
          Try Again
        </button>
      </div>
    </div>
  </div>

  <!-- Empty Data Handling -->
  <div
    *ngIf="dataSource.data.length === 0 && !isLoading"
    class="absolute inset-0 flex items-center justify-center bg-white"
  >
    <div
      *ngIf="dataSource.data.length === 0 && !isLoading"
      class="absolute inset-0 flex items-center justify-center bg-white"
    >
      <div class="text-center">
        <svg
          class="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
          />
        </svg>
        <h3 class="mt-2 text-lg font-medium text-gray-900">
          No data available
        </h3>
        <p class="mt-1 text-sm text-gray-500">{{ emptyDataMessage }}</p>
      </div>
    </div>
  </div>

  <!-- Grid content -->
  <div class="w-full !important">
    <div class="w-full !important" [ngClass]="{ 'opacity-50': isLoading }">
      <section
        class="border-collapse pb-3 mt-2 h-full bg-white shadow-sm !important"
      >
        <div
          class="flex w-full items-center justify-start flex-column flex-wrap space-y-4 mb-1 px-4 py-3 md:space-y-0 bg-white border-collapse !important"
        >
          <div
            class="relative justify-between flex items-center gap-2 !important w-full"
          >
            <div class="flex gap-2 items-center justify-between">
              <label for="table-search" class="sr-only !important"
                >Search</label
              >
              <div class="relative !important">
                <div
                  class="absolute group inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none !important"
                >
                  <svg
                    class="w-4 h-4 text-gray-500 group-focus:bg-indigo-500 !important"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  matInput
                  id="table-search-users"
                  class="block py-1.5 ps-10 regular-font text-gray-900 ring-1 focus:outline-none ring-gray-300 rounded-sm w-80 max-xl:w-40 max-2xl:w-40 max-[1756px]:w-40 max-lg:w-40 max-sm:40 bg-gray-50 focus:ring-indigo-500 focus:ring-2 ring-inset !important"
                  placeholder="Search"
                  (keyup)="applyFilter($any($event.target).value)"
                />
              </div>
              <button
                *ngIf="gridConfig.displayAdd"
                (click)="onAddClick()"
                [ngClass]="{ 'hide-element': userRole === 'USER' }"
                class="capitalize flex items-center text-gray-100 group bg-indigo-500 border ring-gray-300 focus:outline-none hover:bg-indigo-400 focus:ring-2 focus:ring-indigo-500 regular-font px-3 py-1.5 !important"
              >
                <div class="flex align-content-center !important">
                  <span class="mr-1 !important regular-font">Create</span>
                  <svg
                    class="w-4 h-4 !important"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                </div>
              </button>
              <!-- Dropdown Filter -->
              <span
                class="z-[20]"
                *ngIf="(gridConfig.filters && gridConfig.filters.length !== 0)"
              >
                <div class="flex flex-wrap gap-2">
                  <div
                    *ngFor="let filterConfig of gridConfig?.filters"
                    class="relative inline-block text-left select-filter-menu"
                    (click)="$event.stopPropagation()"
                  >
                    <div>
                      <button
                        type="button"
                        [class.w-48]="filterConfig.type === 'date'"
                        [class.w-48]="filterConfig.type !== 'date'"
                        [ngClass]="[
                          filterConfig.width || 'w-80',
                          'inline-flex justify-between max-lg:w-40 w-80 max-[1596px]:w-40 max-2xl:w-40 max-md:40 border border-gray-300 shadow-sm px-4 py-1.5 bg-white regular-font text-gray-700 hover:bg-gray-50 focus:outline-none'
                        ]"
                        (click)="
                          toggleFilterDropdown(filterConfig.jsonKey, $event)
                        "
                      >
                        <span
                          *ngIf="!isFilterApplied(filterConfig)"
                          class="overflow-hidden text-nowrap text-ellipsis w-full text-start"
                          >{{ filterConfig.label }}</span
                        >
                        <span
                          *ngIf="isFilterApplied(filterConfig)"
                          class="overflow-hidden text-nowrap text-ellipsis w-full text-start"
                        >
                          <span *ngIf="filterConfig.type === 'single'">
                            <span
                              class="inline-block w-4 h-4 mr-2 border border-gray-300 rounded-full overflow-hidden text-nowrap text-ellipsis"
                              [ngClass]="{
                                'bg-indigo-500 border-indigo-500':
                                  filters[filterConfig.jsonKey]
                              }"
                            ></span>
                            {{ getSelectedOptionName(filterConfig) }}
                          </span>
                          <span *ngIf="filterConfig.type === 'multi'">
                            <!-- <span *ngIf="isAllOptionsSelected(filterConfig)"
                        >All</span
                      > -->
                            <span
                              *ngIf="!isAllOptionsSelected(filterConfig)"
                              class="!w-[100px] truncate"
                            >
                              {{ getSelectedOptionNames(filterConfig) }}
                            </span>
                          </span>
                          <span *ngIf="filterConfig.type === 'date'">
                            <span
                              class="inline-block w-4 h-4 mr-2 border border-gray-300 rounded-full"
                              [ngClass]="{
                                'bg-indigo-500 border-indigo-500':
                                  filters[filterConfig.jsonKey]
                              }"
                            ></span>
                            {{ getDateFilterLabel(filterConfig) }}
                          </span>
                          <span
                            class="ml-2 bg-gray-200 text-gray-700 rounded-full px-2 py-1 regular-font"
                            >{{ getAppliedFilterCount(filterConfig) }}</span
                          >
                        </span>
                        <svg
                          class="-mr-1 ml-2 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 9.586l3.293-3.293a1 1 0 011.414 1.414l-4 4A1 1 0 0110 12z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      class="origin-top-right absolute max-w-[450px] w-fit whitespace-nowrap left-0 z-20 top-5 mt-2 w-180 shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                      [ngClass]="{
                        hidden: !isFilterDropdownOpen[filterConfig.jsonKey],
                        'max-h-96 overflow-auto':
                          filterConfig.type === 'date' &&
                          filters[filterConfig.jsonKey] === 'custom'
                      }"
                    >
                      <div
                        class="py-1 max-h-[250px] w-full overflow-auto"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                        [ngClass]="{
                          'filter-dropdown-menu': filterConfig.type === 'multi',
                          'select-date-menu': filterConfig.type === 'date'
                        }"
                      >
                        <ng-container *ngIf="filterConfig.type === 'multi'">
                          <!-- <div class="px-4 py-2">
                      <label class="inline-flex items-center">
                        <input
                          type="checkbox"
                          class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out pt-0 pb-0"
                          [checked]="isAllOptionsSelected(filterConfig)"
                          (change)="
                            onAllOptionsChange(
                              filterConfig,
                              $event.target['checked']
                            )
                          "
                        />
                        <span class="ml-2 regular-font text-gray-800"
                          >All</span
                        >
                      </label>
                    </div> -->
                          <div
                            class="px-4 py-2 w-fit"
                            *ngFor="let option of filterConfig.options?.data"
                          >
                            <label class="inline-flex items-center w-fit">
                              <input
                                type="checkbox"
                                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                [checked]="
                                  isFilterOptionSelected(filterConfig, option)
                                "
                                (change)="
                                  onFilterOptionChange(
                                    filterConfig,
                                    option,
                                    $event.target['checked']
                                  )
                                "
                              />
                              <span
                                class="ml-2 regular-font text-gray-700 w-fit"
                                >{{ option.name }}</span
                              >
                            </label>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="filterConfig.type === 'single'">
                          <a
                            *ngFor="let option of filterConfig.options?.data"
                            (click)="onFilterOptionClick(filterConfig, option)"
                            class="px-4 py-2 regular-font text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center"
                            role="menuitem"
                          >
                            <span
                              class="inline-block w-4 h-4 mr-2 border border-gray-300 rounded-full regular-font"
                              [ngClass]="{
                                'bg-indigo-500 border-indigo-500':
                                  filters[filterConfig.jsonKey] === option.id
                              }"
                            ></span>
                            {{ option.name }}
                          </a>
                        </ng-container>
                        <ng-container *ngIf="filterConfig.type === 'date'">
                          <div
                            class="py-1 select-date-menu"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                          >
                            <a
                              *ngFor="
                                let option of getDateFilterOptions(filterConfig)
                              "
                              (click)="
                                onDateFilterOptionChange(
                                  filterConfig,
                                  option.value
                                )
                              "
                              class="flex px-4 py-2 w-48 overflow-hidden regular-font text-gray-700 hover:bg-gray-100 hover:text-gray-900 items-center"
                              role="menuitem"
                            >
                              <span
                                class="inline-block w-4 h-4 mr-2 border border-gray-300 rounded-full"
                                [ngClass]="{
                                  'bg-indigo-500 border-indigo-500':
                                    this.isSelected === option.value
                                }"
                              ></span>
                              {{ option.label }}
                            </a>
                            <div
                              *ngIf="filters[filterConfig.jsonKey] === 'custom'"
                              class="px-4 py-2"
                            >
                              <div class="flex items-center justify-between">
                                <span class="mr-2 regular-font">From: </span>
                                <input
                                  type="date"
                                  class="form-input regular-font shadow-lg border-b-2 pl-1 pr-1"
                                  [(ngModel)]="customDateRange.from"
                                />
                              </div>
                              <div
                                class="flex items-center mt-2 justify-between"
                              >
                                <span class="mr-2 regular-font">To: </span>
                                <input
                                  type="date"
                                  class="form-input regular-font shadow-lg border-b-2 pl-1 pr-1"
                                  [(ngModel)]="customDateRange.to"
                                />
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div
                        *ngIf="hasFilterOptionsSelected(filterConfig)"
                        class="flex justify-end px-4 py-2 space-x-2"
                      >
                        <button
                          (click)="applyFilters(filterConfig)"
                          class="px-3 py-1 regular-font text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Apply
                        </button>
                        <button
                          (click)="cancelFilters(filterConfig)"
                          class="px-3 py-1 regular-font text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
              <div
                class="borderButton flex items-center"
                style="margin-right: 30px !important"
              >
                <button
                  *ngIf="hasFilters === true"
                  class="inline-flex items-center ring-offset-1 text-white bg-indigo-500 p- ring-gray-300 focus:outline-none hover:bg-indigo-400 focus:ring-2 focus:ring-indigo-500 regular-font px-3 py-1.5 !important"
                  (click)="resetFilter()"
                >
                  <span class="mr-1 !important">Reset Filter</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    width="18"
                    height="18"
                    viewBox="0 0 32 32"
                    id="icon"
                    stroke="4"
                  >
                    <defs>
                      <style>
                        .cls-1 {
                          fill: none;
                        }
                      </style>
                    </defs>
                    <path
                      d="M22.5,9A7.4522,7.4522,0,0,0,16,12.792V8H14v8h8V14H17.6167A5.4941,5.4941,0,1,1,22.5,22H22v2h.5a7.5,7.5,0,0,0,0-15Z"
                    />
                    <path
                      d="M26,6H4V9.171l7.4142,7.4143L12,17.171V26h4V24h2v2a2,2,0,0,1-2,2H12a2,2,0,0,1-2-2V18L2.5858,10.5853A2,2,0,0,1,2,9.171V6A2,2,0,0,1,4,4H26Z"
                    />
                    <rect
                      id="_Transparent_Rectangle_"
                      data-name="&lt;Transparent Rectangle&gt;"
                      class="cls-1"
                      width="32"
                      height="32"
                    />
                  </svg>
                </button>
              </div>
              <!-- End of Dropdown Filter -->

              <!-- Headers -->
              <div
                *ngIf="gridConfig.headers && gridConfig.headers.length !== 0"
                class="flex gap-2"
              >
                <div
                  *ngFor="let header of gridConfig.headers"
                  class="flex ring-inset bg-gray-50 py-1.5 regular-font rounded-sm px-2 ring-1 ring-gray-300"
                >
                  <!-- <div class="font-medium">{{ header.label }} : &nbsp;</div> -->
                  <div class="truncate">
                    <span class="regular-font">{{ header.label }} : </span>
                    {{ header.value }}
                  </div>
                </div>
              </div>
            </div>

            <div class="relative !important flex gap-5">
              <!-- toggle -->
              <div
                class="flex items-center gap-3 h-[33px]"
                *ngIf="gridConfig.displayHideAuditColumnsToggle"
              >
                <span
                  class="font-medium text-gray-500 regular-font flex items-center justify-between uppercase"
                >
                  Hide Audit Columns
                </span>
                <div
                  class="w-fit h-fit flex justify-between items-center rounded-full"
                >
                  <label
                    class="relative inline-flex items-center cursor-pointer rounded-full"
                  >
                    <input
                      type="checkbox"
                      [checked]="hideAuditColumns"
                      [(ngModel)]="hideAuditColumns"
                      (change)="setAuditColumnToggleState()"
                      class="sr-only peer focus:ring-indigo-500"
                    />
                    <div
                      class="w-7 h-4 peer bg-gray-500 peer-focus:outline-none rounded-full peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-green-500 peer-focus:ring-2 peer-focus:ring-offset-1 peer-focus:ring-indigo-400"
                    ></div>
                  </label>
                </div>
              </div>
              <button
                class="inline-flex items-center ring-offset-1 text-white bg-indigo-500 ring-gray-300 focus:outline-none hover:bg-indigo-400 focus:ring-2 focus:ring-indigo-500 regular-font px-3 py-1.5 !important"
                (click)="toggleDropdown($event)"
              >
                <span class="mr-1 !important">Export</span>
                <svg
                  class="w-4 h-4 !important"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
              </button>

              <div
                class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-sm shadow-lg z-10 !important"
                [ngClass]="{ hidden: !isDropdownOpen }"
              >
                <a
                  class="block px-4 py-2 regular-font text-gray-700 hover:bg-gray-100 cursor-pointer !important"
                  (click)="exportData('csv')"
                >
                  Export CSV
                </a>
                <a
                  class="block px-4 py-2 regular-font text-gray-700 hover:bg-gray-100 cursor-pointer !important"
                  (click)="exportData('xlsx')"
                >
                  Export Excel
                </a>
              </div>
            </div>
          </div>
        </div>
        <ng-container #tableSection>
          <section class="overflow-auto h-[calc(100vh-210px)] !important">
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              class="mat-elevation-z8 !important"
            >
              <!-- Action Column -->
              <ng-container
                matColumnDef="action"
                *ngIf="gridConfig?.editable || gridConfig?.deletable"
                sticky
              >
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  [ngClass]="{ 'hide-element': userRole === 'USER' }"
                  class="sticky top-0 regular-font text-gray-700 uppercase !bg-violet-100 whitespace-nowrap !z-10 !important !w-9"
                >
                  Edit
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="py-2 px-4 whitespace-nowrap regular-font !important !w-9"
                  [ngClass]="{ 'hide-element': userRole === 'USER' }"
                >
                  <div class="flex gap-2 items-center !important">
                    <mat-icon
                      *ngIf="gridConfig?.editable"
                      (click)="onEditClick(element)"
                      class="text-[20px] text-center cursor-pointer text-blue-500 hover:text-blue-600 !important"
                      >edit</mat-icon
                    >
                    <mat-icon
                      *ngIf="gridConfig?.deletable"
                      (click)="onDelete(element)"
                      class="text-[20px] text-center cursor-pointer text-red-500 hover:text-red-600 !important"
                      >delete</mat-icon
                    >
                  </div>
                </td>
              </ng-container>

              <!-- Other Columns Definition -->
              <ng-container
                matColumnDef="{{ column.jsonKey }}"
                *ngFor="let column of gridConfig?.columns"
                [sticky]="column.dataType === 'toggle'"
              >
                <ng-container 
                  *ngIf="column.isSortable !== false;else notSortable"
                >
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="sticky top-0 regular-font text-gray-700 uppercase !bg-violet-100 whitespace-nowrap !important"
                    [ngClass]="{
                      '!z-10': column.dataType === 'toggle'
                    }"
                  >
                    <span
                      [ngClass]="{
                        'justify-center w-full flex h-full':
                          column.dataType == 'date' ||
                          column.dataType == 'number'
                      }"
                    >
                      {{ column.label }}
                    </span>
                  </th>
                </ng-container>
                <ng-template #notSortable>
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="sticky top-0 regular-font text-gray-700 uppercase !bg-violet-100 whitespace-nowrap !important !w-fit"
                    [ngClass]="{
                      '!z-10': column.dataType === 'toggle'
                    }"
                  >
                    <span
                      [ngClass]="{
                        'justify-center w-full flex h-full':
                          column.dataType == 'date' ||
                          column.dataType == 'number'
                      }"
                    >
                      {{ column.label }}
                    </span>
                  </th>
                </ng-template>

                <td
                  mat-cell
                  *matCellDef="let element"
                  class="py-2 px-4 whitespace-nowrap regular-font !important w-fit"
                  (click)="rowClick(element, $event)"
                >
                  <ng-container [ngSwitch]="column.dataType">
                    <app-tailwind-toggle
                      *ngSwitchCase="'toggle'"
                      [toggleId]="element.id + '_' + column.jsonKey"
                      [checked]="element[column.jsonKey]"
                      [disabled]="
                        gridConfig.isToggleDisabled ||
                        disableSuccessAcknowldgeToggle(element)
                      "
                      (onToggleChange)="
                        onToggleChange(element, column.jsonKey, $event)
                      "
                    ></app-tailwind-toggle>
                    <span *ngSwitchCase="'date'" class="!important">{{
                      element[column.jsonKey] | date : "yyyy-MM-dd HH:mm:ss"
                    }}</span>
                    <span *ngSwitchCase="'dateOnly'">
                      {{ element[column.jsonKey] | date : "yyyy-MM-dd" }}
                    </span>
                    <span *ngSwitchCase="'boolean'">{{
                      element[column.jsonKey] ? "Yes" : "No"
                    }}</span>
                    <span
                      *ngSwitchCase="'password'"
                      class="w-full flex h-full"
                      >{{ "********" }}</span
                    >
                    <span *ngSwitchCase="'text-wrap'">
                      <!-- Insert the html by innerhtml if it supports markdown -->
                      <p
                        class="inline-block text-wrap  markdown"
                        *ngIf="
                          column.width !== 'full' && column.isMarkDownSupported
                        "
                        [innerHTML]="
                          getMakrDownText(
                            column.isMarkDownSupported,
                            element[column.jsonKey]
                          )
                        "
                        [ngClass]="{
                          'justify-center w-full flex h-full':
                            column.dataType == 'date' ||
                            column.dataType == 'number',
                          uppercase: column.toUppercase
                        }"
                      ></p>
                      <!-- show normal text -->
                      <p
                        class="text-wrap max-w-[60vw]"
                        *ngIf="
                          column.width !== 'full' && !column.isMarkDownSupported
                        "
                        [ngClass]="{
                          'justify-center w-full flex h-full':
                            column.dataType == 'date' ||
                            column.dataType == 'number',
                          uppercase: column.toUppercase
                        }"
                      >
                        {{ element[column.jsonKey] }}
                      </p>
                    </span>
                    <span></span>

                    <span *ngSwitchDefault>
                      <span *ngSwitchDefault>
                        <span
                          class="inline-block max-w-[15vw] whitespace-nowrap overflow-hidden overflow-ellipsis"
                          *ngIf="column.width !== 'full'"
                          [ngClass]="{
                            'justify-center w-full flex h-full':
                              column.dataType == 'number',
                            uppercase: column.toUppercase
                          }"
                        >
                          {{
                            column.dataType === "number"
                              ? formatNumber(element[column.jsonKey])
                              : element[column.jsonKey]
                          }}
                        </span>
                        <span
                          *ngIf="column.width === 'full'"
                          class="inline-block whitespace-nowrap"
                          [ngClass]="{ uppercase: column.toUppercase }"
                        >
                          {{
                            column.dataType === "number"
                              ? formatNumber(element[column.jsonKey])
                              : element[column.jsonKey]
                          }}
                        </span>
                      </span>
                    </span>
                  </ng-container>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"
                class="bg-gray-50 z-20 !important"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                class="z-10 hover:bg-slate-100 !important"
                [ngClass]="{
                  '!bg-toastErrorBg  !text-toastErrorColor': isErrorLog(row)
                }"
              ></tr>
            </table>
          </section>
        </ng-container>
      </section>
    </div>

    <!-- Paginator -->
    <div
      class="px-5 py-2 outer overflow-hidden !important"
      [ngClass]="{ hidden: isLoading }"
    >
      <mat-paginator
        aria-label="Select page"
        class="custom-paginator regular-font !important"
        style="font-size: 12px !important"
        (page)="addAllOption()"
        (click)="addAllOption()"
        [length]="totalItems"
        [pageSize]="pageSize"
        [pageSizeOptions]="getPageSize()"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
