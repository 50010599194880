import { Component } from '@angular/core';

@Component({
  selector: 'app-beat-loader',
  templateUrl: './beat-loader.component.html',
  styleUrl: './beat-loader.component.scss'
})
export class BeatLoaderComponent {

}
