import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINTS } from '../shared/constants/apiEnpoints';
import { RouteService } from '../../service/route.service';
import { BREADCRUMB_NAMES } from '../shared/constants/breadCrumbPaths';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { UserLogService } from '../../user-log.service';
import { ROLES } from '../shared/constants/roles.constants';


export interface UserDto {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  externalUserId: string;
  roleName?: string;
  roleId: number;
  isActive?: boolean;
}
@Component({
  selector: 'app-user-profile-page',
  templateUrl: './user-profile-page.component.html',
  animations: [
    trigger('menuAnimation', [
      state(
        'open',
        style({
          opacity: 1,
          transform: 'translateX(0)',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          transform: 'translateX(-100%)',
        })
      ),
      transition('open => closed', [animate('0.3s ease-in-out')]),
      transition('closed => open', [animate('0.3s ease-in-out')]),
    ]),
  ],
})
export class UserProfilePageComponent implements OnInit {
  collapsed: boolean = false;
  currentRoutes!: string[];

  onCollapsedStateChanged(collapsed: boolean) {
    this.collapsed = collapsed;
  }
  user: any;
  showMenu: boolean = false;
  isLoading: boolean = true;
  hasRole: boolean = false;

  constructor(
    private oktaAuthStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private routeService: RouteService,
    private router: Router,
    private http: HttpClient,
    private userLogService: UserLogService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentRoutes = this.router.url
          .split('/')
          .filter((route) => route);
      });
  }

  async ngOnInit() {
    this.isLoading = true;
    const userClaims = await this.oktaAuth.getUser();
    this.user = {
      name: userClaims.name,
      email: userClaims.email,
      sub: userClaims.sub,
      preferred_username: userClaims.preferred_username,
      given_name: userClaims.given_name,
      family_name: userClaims.family_name,
      picture: userClaims['picture'],
    };
  
    const userId = userClaims.sub;
    const url = API_ENDPOINTS.USER.replace(':id', userId);
    this.http.get<any>(url).subscribe(
      (response) => {
        if (response.data) {
          if (response.data.roleName) {
            this.user.role = response.data.roleName;
            this.user.isActive = response.data.isActive;
            this.hasRole = true;
            this.isLoading = false;
          } else {
          }
        } else {
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
  
  toggleMenu(event: Event): void {
    event.stopPropagation();
    this.showMenu = !this.showMenu;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (this.showMenu) {
      this.showMenu = false;
    }
  }

  getInitials(name?: string): string {
    return name
      ?.replace(/[^a-zA-Z ]/g, '')
      .match(/\b\w/g)
      .join('')
      .toUpperCase();
  }

  getProfileImage(): string {
    if (this.user && this.user['picture']) {
      return this.user['picture'];
    } else {
      return '';
    }
  }

  // toggleMenu() {
  //   this.showMenu = !this.showMenu;
  // }

  public async logout(): Promise<void> {
    try {
      const userClaims = await this.oktaAuth.getUser();

      const ipAddress = await this.http
        .get<{ ip: string }>('https://api.ipify.org?format=json')
        .toPromise();

      const userLog = {
        externalUserId: userClaims.sub,
        action: 'logout',
        browser: navigator.userAgent,
        device: this.getDeviceType(),
        ipAddress: ipAddress?.ip,
        // userId: null,
        createdAt: new Date().toISOString(),
      };

      await this.userLogService.createUserLog(userLog).toPromise();

      await this.oktaAuth.signOut();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  }

  private getDeviceType(): string {
    return /Mobi|Android/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop';
  }

  getBreadCrumbPath(index: number): string {
    return (
      this.currentRoutes
        ?.map((e, i) => {
          if (i <= index) {
            return e;
          }
          return '';
        })
        .join('/') ?? ''
    );
  }

  getBreadCrumbName(path: string): string {
    return BREADCRUMB_NAMES[path];
  }
}