import { Component } from '@angular/core';
import { HealthCheckService } from './health-check.service';

@Component({
    selector: 'app-health-check',
    template: '',
  })
  export class HealthCheckComponent {
    constructor(private healthCheckService: HealthCheckService) {
      this.healthCheckService.checkHealth().subscribe(
        response => {
        },
        error => {
          console.error('Health check failed:', error);
        }
      );
    }
  }