import { NgModule } from '@angular/core';
import { RouterModule, RouterOutlet, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { PMSObjectComponent } from './components/configuration/pms-object/pms-object.component';
import { LoginComponent } from './components/login/login.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { PHIMasterComponent } from './components/configuration/phi-master/phi-master.component';
import { PMSMasterComponent } from './components/configuration/pms-master/pms-master.component';
import { PracticeMasterComponent } from './components/configuration/practice-master/practice-master.component';
import { PracticeLoadConfigurationComponent } from './components/configuration/practice-load-configuration/practice-load-configuration.component';
import { LoadScheduleMasterComponent } from './components/configuration/load-schedule-master/load-schedule-master.component';
import { UserProfilePageComponent } from './components/user-profile-page/user-profile-page.component';
import { AuthGuard } from './auth.guard';
import { RoleGuard } from './role.guard';
import { ManageUserRoleComponent } from './components/user-role-management/manage-user-role/manage-user-role.component';
import { PmsAgentHealthStatusComponent } from './components/reports/pms-agent-health-status/pms-agent-health-status.component';
import { PracticeLoadSummaryComponent } from './components/reports/practice-load-summary/practice-load-summary.component';
import { PracticeLoadDetailComponent } from './components/reports/practice-load-detail/practice-load-detail.component';
import { PracticeInstallationLogComponent } from './components/reports/practice-installation-log/practice-installation-log.component';
import { HealthCheckComponent } from './health-check.component';
import { DashboardComponent } from './components/home/dashboard/dashboard.component';
import { CustomCallbackComponent } from './custom-callback.component';
import { PMSAgentReleaseComponent } from './components/configuration/pms-agent-release/pms-agent-release.component';
import { PMSAgentReleaseMgmtComponent } from './components/configuration/pms-agent-release-mgmt/pms-agent-release-mgmt.component';
import { AppComponent } from './app.component';
import { PracticeLogComponent } from './components/reports/practice-log/practice-log.component';
import { ForbiddenRequestComponent } from './components/shared/error-pages/forbidden-request/forbidden-request.component';
import { UserLogsComponent } from './components/reports/user-logs/user-logs.component';

export const routes: Routes = [
  { path: 'health', component: HealthCheckComponent },
  {
    path: '',
    component: LoginComponent,
  },
  {path:'login',component:LoginComponent},
  { path: 'login/callback', component: CustomCallbackComponent },
  { path: 'profile', component: UserProfilePageComponent, canActivate:[OktaAuthGuard]  },
  {
    path: 'dashboard',
    component: HomeComponent,
    //To test the screens without login comment out the below line
    canActivate: [OktaAuthGuard,RoleGuard],
    children: [
      { path:'', component:DashboardComponent,canActivate:[OktaAuthGuard,RoleGuard]},
      {
        path: 'configuration',
        children: [
          { path: '',component:PMSMasterComponent, canActivate:[OktaAuthGuard,RoleGuard]},
          { path: 'pms', component: PMSMasterComponent, canActivate:[OktaAuthGuard,RoleGuard] },
          { path: 'phi-records', component: PHIMasterComponent,canActivate:[OktaAuthGuard,RoleGuard] },
          { path: 'pms-objects', component: PMSObjectComponent ,canActivate:[OktaAuthGuard,RoleGuard]},
          { path: 'practice-load-configurations', component: PracticeLoadConfigurationComponent,canActivate:[OktaAuthGuard,RoleGuard]},
          { path: 'practices', component: PracticeMasterComponent ,canActivate:[OktaAuthGuard,RoleGuard]},
          { path: 'load-schedules', component: LoadScheduleMasterComponent,canActivate:[OktaAuthGuard,RoleGuard] },
          
        ],
      },
      {
        path:'reports',
        children:[
          {path:'',component:PmsAgentHealthStatusComponent,canActivate:[OktaAuthGuard,RoleGuard]},
          {path:'pms-agent-health-status',component:PmsAgentHealthStatusComponent,canActivate:[OktaAuthGuard,RoleGuard]},
          {path:'practice-load-summary', component:PracticeLoadSummaryComponent,canActivate:[OktaAuthGuard,RoleGuard],children:[
            {path:':id/details',component:PracticeLoadDetailComponent,canActivate:[OktaAuthGuard,RoleGuard]},
          ]},
          {path: 'practice-installation-logs', component: PracticeInstallationLogComponent,canActivate:[OktaAuthGuard,RoleGuard]},
          {path: 'practice-error-logs', component: PracticeLogComponent,canActivate:[OktaAuthGuard,RoleGuard]},
          {path:'user-logs', component:UserLogsComponent,canActivate:[OktaAuthGuard,RoleGuard]},
        ]
      },
      {
        path:'manage-agent-releases',
        children:[
          {path:'',component:PMSAgentReleaseComponent,canActivate:[OktaAuthGuard,RoleGuard]},
          { path: 'configure', component: PMSAgentReleaseComponent ,canActivate:[OktaAuthGuard,RoleGuard]},
          { path: 'assign', component: PMSAgentReleaseMgmtComponent ,canActivate:[OktaAuthGuard,RoleGuard]},
        ]
      },
      { path: 'forbidden', component: ForbiddenRequestComponent ,canActivate:[OktaAuthGuard,RoleGuard]},
      {
        path:'user-role-management',
        component:ManageUserRoleComponent,canActivate:[OktaAuthGuard,RoleGuard]
      }
    ],
  },
  {
    path:"**",
    redirectTo:'/dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
