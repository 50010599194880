<main class="w-full bg-white shadow-sm">
  <!-- grid component -->
  <app-dynamic-grid
  [gridConfig]="gridConfig"
  [getDataUrl]="getDataUrl"
  [toggleUrl]="toggleUrl"
  [sortBy]="'modifiedAt'"
  [sortOrder]="'desc'"
  (onToggleShowToastEmitter)="showToast($event)"
  (addClicked)="onAddHandler()"
  (editClicked)="onEditHandler($event)"
  ></app-dynamic-grid>
  
  <!-- add/edit pms master entry drawer -->
  <app-drawer
    #drawerComponent
    [drawerModel]="pmsMasterType"
    [drawerConfig]="drawerInputConfig"
    [AddDataURL]="addDataUrl"
    [editDataURL]="editDataUrl"
    (refresh)="refreshHandler()"
    [createDrawerTitle]="'Create New PMS'"
    [updateDrawerTitle]="'Update PMS'"
    (resetData)="resetEntry()"
  ></app-drawer>
  <app-toast></app-toast>
</main>
