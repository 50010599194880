import { Inject, Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { HttpClient } from '@angular/common/http';
import OktaAuth from '@okta/okta-auth-js';
import { API_ENDPOINTS } from './components/shared/constants/apiEnpoints';
import { lastValueFrom, timer, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  private cacheExpirationTime = 60 * 1000;
  constructor(
    private router: Router,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private http: HttpClient
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const isAuthenticated = await this.oktaAuth.isAuthenticated();

    if (isAuthenticated && state.url === '/login') {
      this.router.navigate(['/dashboard']);
      return false;
    }

    if (!isAuthenticated) {
      this.router.navigate(['/login']);
      return false;
    }

    const userClaims = await this.oktaAuth.getUser();
    const userId = userClaims.sub;

    const cachedUserData = this.getCachedUserData();
    if (cachedUserData) {
      // Use cached data
      localStorage.setItem('userRole', cachedUserData.roleName);
      localStorage.setItem('userEmail', cachedUserData.email);

      if (cachedUserData.isActive) {
        return true;
      } else {
        this.router.navigate(['/profile']);
        return false;
      }
    }

    // No valid cached data, fetch from API
    try {
      const url = API_ENDPOINTS.USER.replace(':id', userId);
      const response = await this.fetchUserData(url);

      this.cacheUserData(response.data);

      localStorage.setItem('userRole', response.data.roleName);
      localStorage.setItem('userEmail', response.data.email);

      if (response.data && response.data.roleName && response.data.isActive) {
        return true;
      } else {
        this.router.navigate(['/profile']);
        return false;
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      this.router.navigate(['/profile']);
      return false;
    }
  }

  private async fetchUserData(url: string): Promise<any> {
    return lastValueFrom(
      this.http.get<any>(url).pipe(
        retry({
          count: 5,
          delay: (error, retryCount) => {
            if (error.status === 403) {
              return timer(1000);
            }
            return throwError(() => error);
          }
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      )
    );
  }

  private cacheUserData(data: any): void {
    const cacheData = {
      roleName: data.roleName,
      email: data.email,
      isActive: data.isActive,
      cachedAt: new Date().getTime(),
    };
    localStorage.setItem('cachedUserData', JSON.stringify(cacheData));
  }

  private getCachedUserData(): any {
    const cachedData = localStorage.getItem('cachedUserData');
    if (!cachedData) return null;

    const parsedData = JSON.parse(cachedData);
    const currentTime = new Date().getTime();

    // Check if the cached data is still valid (within the expiration time)
    if (currentTime - parsedData.cachedAt < this.cacheExpirationTime) {
      return parsedData;
    }

    // Data is expired, clear cache and return null
    localStorage.removeItem('cachedUserData');
    return null;
  }
}
