<div class="overflow-hidden">

    <app-dynamic-grid [gridConfig]="gridConfig"
    [getDataUrl]="getDataUrl"
    [sortBy]="'modifiedAt'"
    [sortOrder]="'desc'"
    (addClicked)="onAddHandler()"
    (onToggleShowToastEmitter)="showToast($event)"
    (editClicked)="onEditHandler($event)"
    [toggleUrl] = "toggleUrl"
    ></app-dynamic-grid>
  </div>
  <app-drawer
      #drawerComponent
      [drawerModel]="masterScheduleType"
      [drawerConfig]="drawerInputConfig"
      [AddDataURL]="addDataUrl"
      [editDataURL]="editDataUrl"
      (refresh)="refreshHandler()"
      (resetData)="resetEntry()"
      [createDrawerTitle]="'Create New Load Schedule' "
      [updateDrawerTitle]="'Update Load Schedule'"
    ></app-drawer>
  <app-toast></app-toast>
  
  <!-- <app-grid [headers]="tableHeaders" [rows]="tableRows"></app-grid> -->
  