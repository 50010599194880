import {
  Component,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { NIL as NIL_UUID } from 'uuid';

import {
  DrawerComponent,
  ModalModel,
} from '../../shared/drawer/drawer.component';
import { RouteService } from '../../../service/route.service';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { DrawerInputConfig } from '../../../models/drawer-model';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';
import { Subscription } from 'rxjs';

export interface PMSMasterData {
  // id?: string;
  name?: string;
  version?: string;
  pmsTypeId: number;
  // createdAt?: string;
  // modifiedAt?: string;
  // createdBy: string;
  // modifiedBy: string;
  isActive: boolean;
  typeName?: string;
  notes: string;
  databaseServerType?: string;
  compatibleVersions?: string;
  databaseServerTypeName?: string;
  databaseServerTypeId: number;
}

@Component({
  selector: 'app-pms-master',
  templateUrl: './pms-master.component.html',
  styleUrls: ['./pms-master.component.scss'],
})
export class PMSMasterComponent implements AfterViewInit {
  @ViewChild(DrawerComponent) drawerComponent!: DrawerComponent<PMSMasterData>;
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;

  showAddEntry: boolean = false;
  routes: string[] = [];
  private _routerSubscription: Subscription;
  public get routerSubscription(): Subscription {
    return this._routerSubscription;
  }
  public set routerSubscription(value: Subscription) {
    this._routerSubscription = value;
  }

  pmsMasterType: PMSMasterData = {
    // id: NIL_UUID,
    name: 'Select PMS Name',
    version: null,
    typeName: 'Select Type',
    // createdAt: null,
    // modifiedAt: null,
    notes: '',
    // createdBy: null,
    // modifiedBy: null,
    isActive: true,
    pmsTypeId: 0,
    compatibleVersions: '',
    databaseServerType: '',
    databaseServerTypeName: 'Select Database Server',
    databaseServerTypeId: 1,
  };

  constructor(
    private routeService: RouteService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this._routerSubscription = this.routeService
      .getRoutes()
      .subscribe((data) => {
        this.routes = data;
        this.routes.shift();
        if (this.routes.length == 2) {
          this.routeService.redirectTO(this.routes.join('/').concat('/pms'));
        }
        this.cdr.detectChanges();
      });
  }

  gridConfig: GridConfig = {
    columns: [
      {
        label: 'Active',
        jsonKey: 'isActive',
        dataType: 'toggle',
        isSortable: false,
      },
      // { label: 'ID', jsonKey: 'id', dataType: 'number' },
      { label: 'PMS Name', jsonKey: 'name', dataType: 'string' },
      {
        label: 'Configured PMS Version',
        jsonKey: 'version',
        dataType: 'string',
      },
      {
        label: 'Hosted',
        jsonKey: 'typeName',
        dataType: 'string',
        isSortable: false,
      },
      {
        label: 'Database Server Type',
        jsonKey: 'databaseServerTypeName',
        dataType: 'string',
      },
      {
        label: 'Compatible Versions',
        jsonKey: 'compatibleVersions',
        dataType: 'string',
        isSortable: false,
      },
      {
        label: 'Created By',
        jsonKey: 'createdByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Created At',
        jsonKey: 'createdAt',
        dataType: 'date',
        hideOnToggle: true,
      },
      {
        label: 'Modified By',
        jsonKey: 'modifiedByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Modified At',
        jsonKey: 'modifiedAt',
        dataType: 'date',
        hideOnToggle: true,
      },
      {
        label: 'Notes',
        jsonKey: 'notes',
        dataType: 'string',
        width:'full',
        isSortable: false,
      },
    ],
    editable: true,
    deletable: false,
    displayAdd: true,
    isToggleDisabled: true,
    displayHideAuditColumnsToggle: true,
  };

  drawerInputConfig: DrawerInputConfig[] = [
    {
      label: 'PMS Name',
      dataType: 'select',
      input: 'select',
      jsonKey: 'name',
      isRequired: true,
      uniqueIdentifier: 'PMSMasterName',

      //below two properties are only used to match the model of dropdown otherwise they dont have any use
      dropdownJsonKey: 'name',
      dropDownOptionsURL: 'null',
    },
    {
      label: 'Version',
      dataType: 'string',
      input: 'text',
      jsonKey: 'version',
      isRequired: true,
      minLength: 1,
      maxLength: 50,
    },
    {
      label: 'Hosted',
      dataType: 'select',
      input: 'select',
      jsonKey: 'typeName',
      dropdownJsonKey: 'name',
      dropDownOptionsURL: API_ENDPOINTS.PMS_TYPES,
      isRequired: true,
      foreignKeyProperty: 'pmsTypeId',
    },
    // {
    //   label: 'Database Server Type',
    //   dataType: 'string',
    //   input: 'text',
    //   jsonKey: 'databaseServerType',
    //   isRequired: true,
    // },
    {
      label: 'Database Server Type',
      dataType: 'select',
      input: 'select',
      jsonKey: 'databaseServerTypeName',
      foreignKeyProperty: 'databaseServerTypeId',
      dropDownOptionsURL:
        API_ENDPOINTS.PMS_MASTER_DATABASE_SERVER_TYPE_DROPDOWN,
      dropdownJsonKey: 'name',
      isRequired: true,
      showNone: false,
    },
    {
      label: 'Compatible Versions',
      jsonKey: 'compatibleVersions',
      input: 'textarea',
      dataType: 'string',
      isRequired: false,
      minLength: 1,
    },
    {
      label: 'Notes',
      jsonKey: 'notes',
      input: 'textarea',
      dataType: 'string',
      isRequired: false,
      minLength: 1,
    },
    {
      label: 'Active',
      dataType: 'boolean',
      input: 'toggle',
      jsonKey: 'isActive',
    },
  ];

  getDataUrl = API_ENDPOINTS.PMS_MASTER;
  editDataUrl = API_ENDPOINTS.PMS_MASTER_BY_ID;
  addDataUrl = API_ENDPOINTS.PMS_MASTER;
  toggleUrl = API_ENDPOINTS.TOGGLE_PMS_MASTER;

  onAddHandler(): void {
    if (this.drawerComponent) {
      this.drawerComponent.drawerType = 'add';
      this.resetEntry();
      this.drawerComponent.openDrawer();
    } else {
      console.error('Drawer component is not initialized');
    }
  }

  onEditHandler(rowData: PMSMasterData): void {
    if (this.drawerComponent) {
      this.drawerComponent.drawerType = 'edit';
      rowData.version = rowData.version.includes('v')
        ? rowData.version.replace('v', '')
        : rowData.version;
      this.drawerComponent.drawerModel = rowData;
      this.drawerComponent.openDrawer();
      this.cdr.detectChanges();
    } else {
      console.error('Drawer component is not initialized');
    }
  }

  resetEntry() {
    this.drawerComponent.drawerModel = {
      name: 'Select PMS Name',
      // createdAt: null,
      // modifiedAt: null,
      // createdBy: null,
      notes: '',
      // modifiedBy: null,
      // id: null,
      isActive: true,
      typeName: 'Select Type',
      version: null,
      pmsTypeId: 0,
      databaseServerType: '',
      compatibleVersions: '',
      databaseServerTypeName: 'Select Database Server',
      databaseServerTypeId: 1,
    };
  }

  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  // toast related properties

  @ViewChild(ToastComponent) toastComponent!: ToastComponent;

  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
    this.refreshHandler();
  }

  ngOnDestroy() {
    this._routerSubscription.unsubscribe();
  }
}
