{
  "name": "pms-admin",
  "version": "1.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "coverage": "ng test --code-coverage",
    "bump-major": "npm version major",
    "bump-minor": "npm version minor",
    "bump-patch": "npm version patch"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.2.0",
    "@angular/cdk": "^17.3.1",
    "@angular/common": "^17.2.0",
    "@angular/compiler": "^17.2.0",
    "@angular/core": "^17.2.0",
    "@angular/forms": "^17.2.0",
    "@angular/material": "^17.3.1",
    "@angular/platform-browser": "^17.2.0",
    "@angular/platform-browser-dynamic": "^17.2.0",
    "@angular/router": "^17.2.0",
    "@heroicons/vue": "^2.1.3",
    "@ngx-translate/core": "^15.0.0",
    "@okta/okta-angular": "^6.4.0",
    "@okta/okta-auth-js": "^7.5.1",
    "@types/seedrandom": "^3.0.8",
    "chart.js": "^4.4.3",
    "cronstrue": "^2.50.0",
    "date-fns-tz": "^3.1.3",
    "google-charts": "^2.0.0",
    "marked": "^13.0.3",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.45",
    "rxjs": "~7.8.0",
    "seedrandom": "^3.0.5",
    "semver": "^7.6.3",
    "tslib": "^2.3.0",
    "uuid": "^9.0.1",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.2.2",
    "@angular/cli": "^17.2.2",
    "@angular/compiler-cli": "^17.2.0",
    "@types/jasmine": "~5.1.0",
    "autoprefixer": "^10.4.18",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.35",
    "tailwindcss": "^3.4.1",
    "typescript": "~5.3.2"
  }
}
