import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  DrawerComponent,
  ModalModel,
} from '../../shared/drawer/drawer.component';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { RouteService } from '../../../service/route.service';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { DrawerInputConfig } from '../../../models/drawer-model';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { Subscription, sampleTime, timestamp } from 'rxjs';
import { NIL as NIL_UUID } from 'uuid';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';

export interface MasterSchedule extends ModalModel {
  id: string;
  name: string;
  endTimeOut: number;
  createdAt: string;
  modifiedAt: string;
  createdBy: string;
  modifiedBy: string;
  isActive: boolean;
  description: string;
  cronExpression: string;
}
@Component({
  selector: 'app-load-schedule-master',
  templateUrl: './load-schedule-master.component.html',
  styleUrl: './load-schedule-master.component.scss',
})
export class LoadScheduleMasterComponent {
  @ViewChild(DrawerComponent) drawerComponent!: DrawerComponent<MasterSchedule>;
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;

  masterScheduleType: MasterSchedule = {
    id: NIL_UUID,
    name: '',
    endTimeOut: 0,
    createdAt: '',
    modifiedAt: '',
    createdBy: NIL_UUID,
    modifiedBy: NIL_UUID,
    description: '',
    isActive: true,
    cronExpression: '',
    toggleUrl: '',
  };

  gridConfig: GridConfig = {
    columns: [
      {
        label: 'Active',
        jsonKey: 'isActive',
        dataType: 'toggle',
        isSortable: false,
      },
      { label: 'Load Schedule Name', jsonKey: 'name', dataType: 'text-wrap' },
      {
        label: 'Start Time CRON Expression',
        jsonKey: 'startCronExpression',
        dataType: 'string',
        isSortable: false,
      },
      {
        label: 'Description',
        jsonKey: 'description',
        dataType: 'string',
        width: 'full',
        isSortable: false,
      },
      {
        label: 'End Timeout (Hours)',
        jsonKey: 'endTimeOut',
        dataType: 'number',
      },
      {
        label: 'Created By',
        jsonKey: 'createdByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Created At',
        jsonKey: 'createdAt',
        dataType: 'date',
        hideOnToggle: true,
      },
      {
        label: 'Modified By',
        jsonKey: 'modifiedByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Modified At',
        jsonKey: 'modifiedAt',
        dataType: 'date',
        hideOnToggle: true,
      },
    ],

    editable: true,
    deletable: false,
    displayAdd: true,
    isToggleDisabled: true,
    uniqueTableName: 'LoadSchedule',
    displayHideAuditColumnsToggle: true,
  };

  drawerInputConfig: DrawerInputConfig[] = [
    {
      label: 'Load Schedule Name',
      jsonKey: 'name',
      input: 'text',
      dataType: 'string',
      isRequired: true,
      minLength: 1,
      maxLength: 500,
    },
    {
      label: 'Start Time CRON Expression',
      jsonKey: 'startCronExpression',
      input: 'text',
      dataType: 'string',
      isRequired: true,
      labelUrl:
        'http://www.cronmaker.com/;jsessionid=node0oi37zb539vb01xu9vmsjpcslw421062.node0?0',
      maxLength: 50,
      labelInfo: 'Learn More about CRON',
    },
    {
      label: 'Description',
      jsonKey: 'description',
      input: 'textarea',
      dataType: 'string',
      isDisabled: true,
    },
    {
      label: 'End Timeout (Hours)',
      jsonKey: 'endTimeOut',
      input: 'text',
      dataType: 'number',
      isRequired: true,
    },
    {
      label: 'Active',
      jsonKey: 'isActive',
      input: 'toggle',
      dataType: 'boolean',
    },
  ];

  getDataUrl = API_ENDPOINTS.LOAD_SCHEDULE;
  editDataUrl = API_ENDPOINTS.LOAD_SCHEDULE;
  addDataUrl = API_ENDPOINTS.LOAD_SCHEDULE;
  toggleUrl = API_ENDPOINTS.TOGGLE_LOAD_SCHEDULE;

  private _routerSubscription: Subscription;

  public get routerSubscription(): Subscription {
    return this._routerSubscription;
  }

  public set routerSubscription(subscription: Subscription) {
    this._routerSubscription = subscription;
  }

  routes: string[] = [];
  constructor(
    private routeService: RouteService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.routerSubscription = this.routeService
      .getRoutes()
      .subscribe((data) => {
        this.routes = data;
        this.routes.shift();
        if (this.routes.length == 2) {
          this.routeService.redirectTO(
            this.routes.join('/').concat('/load-schedules')
          );
        }
        this.cdr.detectChanges();
      });
  }

  onAddHandler(): void {
    this.drawerComponent.drawerType = 'add';
    this.resetEntry();
    this.drawerComponent.openDrawer();
  }
  resetEntry() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    const dateString = `${year}-${month}-${day}`;
    this.drawerComponent.drawerModel = {
      id: NIL_UUID,
      name: '',
      endTimeOut: 0,
      createdAt: dateString,
      modifiedAt: dateString,
      cronExpression: '',
      createdBy: NIL_UUID,
      description: '',
      modifiedBy: NIL_UUID,
      isActive: true,
    };
  }

  onEditHandler(rowData: any): void {
    this.drawerComponent.drawerType = 'edit';
    this.drawerComponent.drawerModel = rowData;
    this.drawerComponent.openDrawer();
    this.cdr.detectChanges();
  }

  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  // toast related properties

  @ViewChild(ToastComponent) toastComponent!: ToastComponent;

  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
    this.refreshHandler();
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
