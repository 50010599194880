  <div class="content" role="main">
    <router-outlet></router-outlet>
  </div>

  <div class="flex min-h-screen bg-sky-50">
    <div class="w-2/3 bg-sky-50 p-12 flex flex-col items-center justify-between">
      <div class="self-start">
        <img src="https://www.dentalcorp.ca/images/2310/images/logo-dc.svg" alt="Dentalcorp Logo" class="h-12">
      </div>

      <div>
        <img src="../../../assets/user.png" alt="Profile Illustration" class="mx-auto h-48 w-48 p-2">
        <h2 class="text-center text-2xl font-semibold mt-4 text-sky-600">Welcome to Dentalcorp Admin Panel</h2>
        <p class="text-center text-gray-700 mt-2">Precision in Practice, Efficiency at Your Fingertips: Elevate Your Dental Care with Our Control Panel</p>
      </div>
      <p class="text-sm text-gray-500 absolute bottom-8 ">{{ appTitle }} - PMS Admin Panel, Version: {{ version }}</p>
      <div class="h-12"></div>
    </div>

    <div class="w-1/3 flex items-stretch justify-center p-4 pr-0">
      <div class="w-full flex items-center justify-center">
        <div class="bg-white p-4 rounded-lg shadow-lg space-y-6 w-full max-w-md flex flex-col justify-center h-full">
          <div>
            <h3 class="text-2xl font-bold text-gray-800 text-center">Sign In to</h3>
            <h3 class="text-2xl font-bold text-sky-600 text-center">Dentalcorp Admin Panel</h3>
            <p class="text-center text-sm text-gray-700 mb-4">Take Command of Your Practices</p>
          </div>
          <button (click)="login()" type="button" class="mt-5 w-full py-2 px-4 bg-sky-600 text-white rounded-md hover:bg-sky-700 flex items-center justify-center">
            Sign In with Okta
          </button>
          
        </div>
      </div>
    </div>
  </div>
