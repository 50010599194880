import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { UserLogService } from './user-log.service'; 
import { IpAddressService } from './ip-address.service';
import { catchError, retry, throwError, timer } from 'rxjs';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-custom-callback',
  template: '',
})
export class CustomCallbackComponent implements OnInit {
  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private router: Router,
    private userLogService: UserLogService,
    private ipAddressService: IpAddressService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      await this.oktaAuth.handleLoginRedirect();

      const userClaims = await this.oktaAuth.getUser();

      const ipAddress = await lastValueFrom(this.ipAddressService.getIpAddress());

      await this.logUserAction('login', userClaims, ipAddress.ip);

      const originalUri = this.oktaAuth.getOriginalUri() || '/dashboard';
      this.oktaAuth.removeOriginalUri();
      this.router.navigate([originalUri]);
    } catch (error) {
      console.error('Error during login callback:', error);
      this.router.navigate(['/login']);
    }
  }

  private async logUserAction(
    action: string,
    userClaims: any,
    ipAddress: string
  ): Promise<void> {
    const userLog = {
      externalUserId: userClaims.sub,
      action: action,
      browser: navigator.userAgent,
      device: this.getDeviceType(),
      ipAddress: ipAddress,
      userId: null,
      createdAt: new Date().toISOString(),
    };

    try {
      await lastValueFrom(
        this.userLogService.createUserLog(userLog).pipe(
          retry({
            count: 5,
            delay: (error, retryCount) => {
              if (error.status === 403) {
                return timer(1000); 
              }
              return throwError(() => error);
            }
          }),
          catchError((error) => {
            console.error('Failed to create user log after all retries:', error);
            return throwError(() => error);
          })
        )
      );
    } catch (error) {
      console.error('Error creating user log:', error);
      throw error; 
    }
  }

  private getDeviceType(): string {
    return /Mobi|Android/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop';
  }
}