import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { HttpClient } from '@angular/common/http';
import { PracticeLoadDataService } from './data/practice-load-data.service';
import { RouteService } from '../../../service/route.service';
import { MAX_INT_32 } from '../../shared/constants/values';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-practice-load-detail',
  templateUrl: './practice-load-detail.component.html',
  styleUrls: ['./practice-load-detail.component.scss'],
})
export class PracticeLoadDetailComponent implements OnInit {
  gridConfig: GridConfig = {
    columns: [
      {
        label: 'PMS Object Name',
        jsonKey: 'pmsObjectName',
        dataType: 'string',
      },
      { label: 'Status Code', jsonKey: 'pmsStatusCode', dataType: 'number' },
      {
        label: 'PMS Agent Status',
        jsonKey: 'pmsAgentStatus',
        dataType: 'string',
        width: 'full',
      },
      {
        label: 'Total Records Extracted',
        jsonKey: 'totalRecordsExtracted',
        dataType: 'number',
      },
      { label: 'Start Time', jsonKey: 'startTime', dataType: 'date' },
      { label: 'End Time', jsonKey: 'endTime', dataType: 'date' },
      { label: 'Duration (HH:MM:SS)', jsonKey: 'duration', dataType: 'string' },
      {
        label: 'Message',
        jsonKey: 'message',
        dataType: 'string',
        width: 'full',
      },
      { label: 'Error Details', jsonKey: 'errorDetails', dataType: 'string',width:'full' },
      {
        label: 'Resolution',
        jsonKey: 'resolution',
        dataType: 'string',
        width: 'full',
        isSortable: false,
      },
    ],
    headers: [
      { label: 'Practice Code', value: '' },
      { label: 'Practice', value: '' },
      { label: 'Load Date', value: '' },
    ],
    filters: [
      {
        label: 'PMS Object',
        jsonKey: 'pmsObjectId',
        type: 'multi',
        options: [],
        width: 'w-50',
      },
    ],

    editable: false,
    deletable: false,
    displayAdd: false,
    showAllPagination: true,
  };

  @Input() practiceLoadId!: string;
  @Input() loadDate!: string;
  @Input() loadTypeId!: string;
  @Input() practiceName!: string;
  loadType: string;
  pmsId: string;
  getDataUrl = API_ENDPOINTS.PRACTICE_LOAD_DETAIL;
  getPMSObjectsURL = API_ENDPOINTS.PMS_OBJECT;
  private routerSubscription: Subscription;
  routes: string[];
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;
  isDataLoaded: boolean = false;
  isLoadingData: boolean = false;

  constructor(
    private http: HttpClient,
    private practiceLoadData: PracticeLoadDataService,
    private routeService: RouteService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {    
    // Try to get data from service first
    this.loadDate = this.practiceLoadData.getLoadDate();
    this.loadType = this.practiceLoadData.getLoadType();
    this.loadTypeId = this.practiceLoadData.getLoadTypeId();
    this.practiceLoadId = this.practiceLoadData.getPracticeLoadId();
    this.practiceName = this.practiceLoadData.getPracticeName();
    this.pmsId = this.practiceLoadData.getPMSId();

    // If we don't have the practice load ID from the service, try to get it from the route
    if (!this.practiceLoadId) {
      // Get the practice load ID from the route
      const url = this.router.url;
      
      const urlParts = url.split('/');
      
      const detailsIndex = urlParts.indexOf('details');
      
      if (detailsIndex > 0) {
        this.practiceLoadId = urlParts[detailsIndex - 1];
        
        if (this.practiceLoadId) {
          this.isLoadingData = true;
          this.fetchPracticeLoadDetails(this.practiceLoadId);
        }
      }
    } else {
      this.setupGridHeaders();
      this.isDataLoaded = true;
    }

    // Set up the data URL regardless
    if (this.practiceLoadId) {
      this.getDataUrl = `${this.getDataUrl}?practiceLoadId=${this.practiceLoadId}`;
    }
  }

  fetchPracticeLoadDetails(practiceLoadId: string) {
    
    // Use the correct endpoint to get a single practice load summary by ID
    this.http.get<any>(`${API_ENDPOINTS.PRACTICE_LOAD_SUMMARY}/${practiceLoadId}`)
      .subscribe({
        next: (response) => {
          
          if (response && response.data) {
            const data = response.data;
            
            // Update the service with the fetched data
            this.practiceLoadData.setPracticeLoadId(data.id);
            this.practiceLoadData.setPracticeName(data.practiceName);
            this.practiceLoadData.setPracticeCode(data.practiceCode);
            this.practiceLoadData.setPMSId(data.pmsId);
            this.practiceLoadData.setLoadDate(data.startTime);
            this.practiceLoadData.setLoadTypeId(data.loadTypeId);
            this.practiceLoadData.setLoadType(data.loadTypeName);

            // Update local variables
            this.loadDate = this.practiceLoadData.getLoadDate();
            this.loadType = this.practiceLoadData.getLoadType();
            this.loadTypeId = this.practiceLoadData.getLoadTypeId();
            this.practiceName = this.practiceLoadData.getPracticeName();
            this.pmsId = this.practiceLoadData.getPMSId();

            // Set up the grid headers
            this.setupGridHeaders();
            
            // Update the data URL
            this.getDataUrl = `${API_ENDPOINTS.PRACTICE_LOAD_DETAIL}?practiceLoadId=${this.practiceLoadId}`;
            
            // Mark data as loaded
            this.isDataLoaded = true;
            this.isLoadingData = false;
            
            // Fetch PMS object options
            this.fetchPMSObjectNameOptions();
            
            // Force change detection
            this.cdr.detectChanges();
          } else {
            this.isLoadingData = false;
          }
        },
        error: (error) => {
          console.error('Error fetching practice load details:', error);
          this.isLoadingData = false;
        }
      });
  }

  setupGridHeaders() {
    this.gridConfig.headers[0].value = this.practiceLoadData.getPracticeCode() || '';
    this.gridConfig.headers[1].value = this.practiceName || '';
    this.gridConfig.headers[2].value = this.loadDate || '';
  }

  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  ngAfterViewInit(): void {
    
    this.routerSubscription = this.routeService
      .getRoutes()
      .subscribe((data) => {
        this.routes = data;
        this.routes.shift();
        
        // Only redirect if we're not loading data and couldn't load the data
        if (
          this.routes.length == 5 &&
          !this.isLoadingData &&
          (!this.isDataLoaded || !this.practiceLoadId)
        ) {
          this.routes.pop();
          this.routes.pop();
          this.routeService.redirectTO(this.routes.join('/'));
        } else {
        }
        
        this.cdr.detectChanges();
      });

    if (this.pmsId) {
      this.fetchPMSObjectNameOptions();
    }
  }

  fetchPMSObjectNameOptions() {
    if (!this.pmsId) {
      return;
    }
    
    
    this.http
      .get<any>(`${this.getPMSObjectsURL + '/dropdown'}`, {
        params: { pmsId: this.pmsId },
      })
      .subscribe({
        next: (response) => {
          
          if (response && response.data) {
            response.data = response.data
              .filter((option) => option.isActive)
              .map((option) => ({
                ...option,
                name: option.name,
              }))
              .sort((a, b) => a.name.localeCompare(b.name));

            this.gridConfig.filters.find(
              (filter) => filter.jsonKey === 'pmsObjectId'
            ).options = response;
          }
        },
        error: (error) => {
          console.error('Error fetching PMS object options:', error);
        }
      });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
