import { BootstrapOptions, Component, EventEmitter, Input, Output, output } from '@angular/core';

// toast interface

export interface Toast {
  severity: 'success' | 'error' | 'info' | 'warn';
  title: string;
  message: string;
  duration?: number;
  isAutoClosable?:boolean;
}

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent {
  isVisble: boolean = false;

  // by default toast's severity is set to info and a duration of 2000ms
  toast: Toast = {
    severity: 'info',
    title: 'title',
    message: 'this is sample info',
    duration: 5000,
    isAutoClosable: true
  };

  closeToast() {
    this.isVisble = false;
  }

  showToast(toast: Toast) {
    this.toast = { ...this.toast,...toast };
    this.isVisble = true;
    if(this.toast.isAutoClosable){
      setTimeout(() => {
        this.isVisble = false;
      }, this.toast.duration);
    }
  }
}
