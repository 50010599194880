
<div class="relative">

    <app-dynamic-grid 
    [gridConfig]="gridConfig"
    [getDataUrl]="getDataUrl"
    [sortBy]="'createdAt'"
    [sortOrder]="'desc'"
    (onToggleShowToastEmitter)="showToast($event)"
    ></app-dynamic-grid>

 
  <app-toast></app-toast>
</div>

