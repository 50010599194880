import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { DrawerComponent } from '../../shared/drawer/drawer.component';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { DrawerInputConfig } from '../../../models/drawer-model';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { RouteService } from '../../../service/route.service';

export interface PMSAgentReleaseData {
  // id: string | null;
  version: string;
  releaseNote: string;
  isCritical: boolean;
  releaseDate: string;
  isActive: boolean;
  // createdAt: Date;
  // modifiedAt: Date;
  // createdBy: string;
  // modifiedBy: string;
  // createdByUser: string;
  // modifiedByUser: string;
}

@Component({
  selector: 'app-pms-agent-release',
  templateUrl: './pms-agent-release.component.html',
  styleUrls: ['./pms-agent-release.component.scss'],
})
export class PMSAgentReleaseComponent {
  drawerData: PMSAgentReleaseData = {
    // id: null,
    version: '',
    releaseNote: '',
    isCritical: true,
    releaseDate: this.getFormattedDateTimeNow(),
    isActive: true,
    // createdAt: null,
    // modifiedAt: null,
    // createdBy: null,
    // modifiedBy: null,
    // createdByUser: '',
    // modifiedByUser: '',
  };

  @ViewChild(DrawerComponent)
  drawerComponent!: DrawerComponent<PMSAgentReleaseData>;
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;
  @ViewChild(ToastComponent) toastComponent: ToastComponent;

  gridConfig: GridConfig = {
    columns: [
      {
        label: 'Active',
        jsonKey: 'isActive',
        dataType: 'toggle',
        isSortable: false,
      },
      {
        label: 'Hotfix',
        jsonKey: 'isCritical',
        dataType: 'toggle',
        toggleUrl: API_ENDPOINTS.TOGGLE_PMS_AGENT_RELEASE_CRITICAL,
        isSortable: false,
      },
      { label: 'Release Date', jsonKey: 'releaseDate', dataType: 'dateOnly' },
      {
        label: 'PMS Agent Version',
        jsonKey: 'version',
        dataType: 'string',
        isSortable: false,
      },
      {
        label: 'Created By',
        jsonKey: 'createdByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Created At',
        jsonKey: 'createdAt',
        dataType: 'date',
        hideOnToggle: true,
      },
      {
        label: 'Release Notes',
        jsonKey: 'releaseNote',
        dataType: 'text-wrap',
        isMarkDownSupported: true,
        isSortable: false,
      },
    ],
    editable: false,
    isToggleDisabled: true,
    deletable: false,
    displayAdd: true,
    displayHideAuditColumnsToggle: true,
  };

  getDataUrl = API_ENDPOINTS.PMS_AGENT_RELEASE;
  toggleUrl = API_ENDPOINTS.TOGGLE_PMS_AGENT_RELEASE_ACTIVE;
  addDataUrl = API_ENDPOINTS.PMS_AGENT_RELEASE;
  editDataUrl = API_ENDPOINTS.PMS_AGENT_RELEASE;

  drawerInputConfig: DrawerInputConfig[] = [
    {
      label: 'Release Date',
      jsonKey: 'releaseDate',
      input: 'date',
      isRequired: true,
      dataType: 'datetime-local',
    },
    {
      label: 'Version',
      jsonKey: 'version',
      input: 'text',
      dataType: 'string',
      isRequired: true,
      minLength: 1,
      maxLength: 50,
      validationRegex: /^\d+\.\d+\.\d+\.\d+$/,
    },
    {
      label: 'Release Notes',
      jsonKey: 'releaseNote',
      input: 'textarea',
      dataType: 'string',
      isRequired: true,
      minLength: 1,
      labelInfo: 'MARKDOWN IS SUPPORTED',
      labelUrl: 'https://www.markdownguide.org/getting-started/',
    },
    {
      label: 'Hotfix',
      jsonKey: 'isCritical',
      input: 'toggle',
      dataType: 'boolean',
    },
    // {
    //   label: 'Active',
    //   jsonKey: 'isActive',
    //   input: 'toggle',
    //   dataType: 'boolean',
    // },
  ];

  private _routerSubscription: Subscription;

  public get routerSubscription(): Subscription {
    return this._routerSubscription;
  }
  public set routerSubscription(value: Subscription) {
    this._routerSubscription = value;
  }
  routes: string[] = [];
  constructor(
    private routeService: RouteService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this._routerSubscription = this.routeService
      .getRoutes()
      .subscribe((data) => {
        this.routes = data;
        this.routes.shift();
        if (this.routes.length == 2) {
          this.routeService.redirectTO(
            this.routes.join('/').concat('/configure')
          );
        }
        this.cdr.detectChanges();
      });
  }

  onAddHandler(addClicked: boolean): void {
    if (addClicked) {
      this.drawerComponent.drawerType = 'add';
      this.drawerComponent.drawerModel.releaseDate =
        this.getFormattedDateTimeNow();
      this.resetEntry();
      this.openDrawer();
    }
  }

  getFormattedDateTimeNow(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = ('0' + (now.getMonth() + 1)).slice(-2);
    const day = ('0' + now.getDate()).slice(-2);
    const hours = ('0' + now.getHours()).slice(-2);
    const minutes = ('0' + now.getMinutes()).slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  openDrawer() {
    this.drawerComponent.openDrawer();
  }

  onEditHandler(rowData: any): void {
    if (this.drawerComponent) {
      this.drawerComponent.drawerType = 'edit';
      rowData.releaseDate = moment(rowData.releaseDate).format('YYYY-MM-DD');
      this.drawerComponent.drawerModel = rowData;
      const length = this.drawerComponent.drawerModel.version.length;
      this.drawerComponent.drawerModel.version =
        this.drawerComponent.drawerModel.version.slice(1, length);
      this.drawerComponent.openDrawer();
    } else {
      console.error('Drawer component is not initialized');
    }
  }

  resetEntry() {
    this.drawerComponent.drawerModel = {
      // id: null,
      version: '',
      releaseNote: '',
      isCritical: false,
      releaseDate: this.getFormattedDateTimeNow(),
      isActive: true,
      // createdAt: null,
      // modifiedAt: null,
      // createdBy: null,
      // modifiedBy: null,
      // createdByUser: '',
      // modifiedByUser: '',
    };
  }

  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  ngOnInit(): void {}

  // toast related properties
  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
    this.refreshHandler();
  }

  ngOnDestroy() {
    this._routerSubscription.unsubscribe();
  }

  // method to be called before saving object in add mode
  handleBeforeSave() {
    this.drawerComponent.drawerModel.releaseDate = new Date(
      this.drawerComponent.drawerModel.releaseDate
    ).toISOString();
  }
}
