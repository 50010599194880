import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {

  constructor(private httpClient:HttpClient) { }
  
  editEntry(entry:any,url:string):Observable<any>{
    return this.httpClient.put(url,entry);
  }

  addEntry(entry:any,url:string){
    return this.httpClient.post(url,entry);
  }
  getOptions(url: string) {
    return this.httpClient.get<any>(url);
  }
}
