import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HealthCheckService {
  checkHealth(): Observable<any> {
    return of({ status: 200 });
  }
}