import { environment } from '../../../../environments/environment';
export const API_BASE_URL = environment.pmsAPIBaseUrl;

//For Local Testing 
//export const HEARTBEAT_BASE_URL  = 'http://localhost:7222';
export const HEARTBEAT_BASE_URL  = 'https://api-dev.dentalcorp.ca';


export const API_ENDPOINTS = {
  PMS_OBJECT_WISE_PHI:`${API_BASE_URL}/PMSObjectWisePHI`,
  TOGGLE_PMS_OBJECT_WISE_PHI_ACTIVE:`${API_BASE_URL}/PMSObjectWisePHI/:id/toggle-active`,

  // PMS Object
  PMS_OBJECT: `${API_BASE_URL}/PMSObject`,
  TOGGLE_PMS_OBJECT:`${API_BASE_URL}/PMSObject/:id/toggle-active`,
  TOGGLE_ID_BASED_PULL: `${API_BASE_URL}/PMSObject/:id/toggle-checksum-required`,

  // PMS Master Endpoints
  PMS_MASTER: `${API_BASE_URL}/PMSMaster`,
  PMS_MASTER_BY_ID:`${API_BASE_URL}/PMSMaster/:id`,
  TOGGLE_PMS_MASTER: `${API_BASE_URL}/PMSMaster/:id/toggle-active`,
  PMS_MASTER_DATABASE_SERVER_TYPE_DROPDOWN:`${API_BASE_URL}/PMSMaster/database-server-type-dropdown-options`,

  // PMS Type Endpoints
  PMS_TYPES: `${API_BASE_URL}/PMSType`,

  // Practice Load Configuration Endpoints
  PRACTICE_LOAD_CONFIGURATION:`${API_BASE_URL}/PracticeLoadConfiguration`,
  PRACTICE_LOAD_RESET_DROPDOWN:`${API_BASE_URL}/PracticeLoadConfiguration/reset-dropdown-options`,
  TOGGLE_PRACTICE_LOAD_CONFIGURATION_ACTIVE: `${API_BASE_URL}/PracticeLoadConfiguration/:id/toggle-active`,
  
  // Practice Master Endpoints
  PRACTICES:`${API_BASE_URL}/Practice`,
  GETALLPRACTICES:`${API_BASE_URL}/Practice/GetAllPractice`,
  PRACTICES_DROPDOWN:`${API_BASE_URL}/Practice`,
  PRACTICE_BY_ID:`${API_BASE_URL}/Practice/:id`,
  TOGGLE_PRACTICE:  `${API_BASE_URL}/Practice/:id/toggle-active`,

  //PMS Agent Release
  PMS_AGENT_RELEASE: `${API_BASE_URL}/PMSAgentRelease`,
  TOGGLE_PMS_AGENT_RELEASE_ACTIVE: `${API_BASE_URL}/PMSAgentRelease/:id/toggle-active`,
  TOGGLE_PMS_AGENT_RELEASE_CRITICAL: `${API_BASE_URL}/PMSAgentRelease/:id/toggle-critical`,

  //PMS Agent Release Mapping
  PMS_AGENT_RELEASE_MAPPING: `${API_BASE_URL}/PMSAgentReleaseMapping`,
  TOGGLE_PMS_AGENT_RELEASE_MAPPING_ACTIVE: `${API_BASE_URL}/PMSAgentReleaseMapping/:id/toggle-active`,
  
  //Load Schedule Master
  LOAD_SCHEDULE:`${API_BASE_URL}/LoadSchedule`,
  TOGGLE_LOAD_SCHEDULE:`${API_BASE_URL}/LoadSchedule/:id/toggle-active`,

  // PMS status endpoints 
  PMS_STATUS:`${API_BASE_URL}/PMSStatus`,

  //Load Type
  LOAD_TYPE:`${API_BASE_URL}/LoadType`,

  // Manage user role Endpoints
  USERS_ROLE: `${API_BASE_URL}/ManageUserRole`,
  ASSIGN_USER_ROLE:`${API_BASE_URL}/ManageUserRole/assignRole`,
  UPDATE_USER_ROLE:`${API_BASE_URL}/ManageUserRole/role/:id`,
  TOGGLE_USER_ROLE:`${API_BASE_URL}/ManageUserRole/:id/toggle-active`,

  // Get All roles
  ALL_ROLES:`${API_BASE_URL}/Role`,

  // User endpoints 
  USER:`${API_BASE_URL}/User/userId/:id`,

  //Practice Load Detail
  PRACTICE_LOAD_DETAIL: `${API_BASE_URL}/PracticeLoadDetail`,

  //PMS Agent Health Status
  AGENT_HEALTH_STATUS:`${API_BASE_URL}/PMSAgentHealthStatus`,

  // Practice Load Summary 
  PRACTICE_LOAD_SUMMARY : `${API_BASE_URL}/PracticeLoadSummary`,
  PRACTICE_LOAD_SUMMARY_ACKNOWLEDGEMENT : `${API_BASE_URL}/PracticeLoadSummary/Acknowledge/:id`,

  //Practice Installation Log
  PRACTICE_INSTALLATION_LOG:`${API_BASE_URL}/PracticeInstallationLog`,
  PRACTICE_INSTALLATION_LOG_ACKNOWLEDGEMENT : `${API_BASE_URL}/PracticeInstallationLog/Acknowledge/:id`,

  //User Logging
  USER_LOGGING:`${API_BASE_URL}/UserLog`,

  //Dashboard
  DASHBOARD:`${API_BASE_URL}/Dashboard`,
  DASHBOARD_LOAD_SUMMARY:`${API_BASE_URL}/Dashboard/GetDataLoadSummary`,
  DASHBOARD_DATA_LOAD_STATUS:`${API_BASE_URL}/Dashboard/GetDataLoadStatus`,

  //Load Type
  LOADTYPE : `${API_BASE_URL}/LoadType`,

  //Practice Log
  PRACTICE_LOG: `${API_BASE_URL}/PracticeLog`,
  PRACTICE_LOG_ACKNOWLEDGEMENT : `${API_BASE_URL}/PracticeLog/Acknowledge/:id`,

  //HeartBeat
  HEARTBEAT: `${HEARTBEAT_BASE_URL}/heartbeatHub`,
  PRACTICE_HEARTBEAT: `${API_BASE_URL}/Heartbeat`,

  TIMEZONE:  `${API_BASE_URL}/Practice/timezone-dropdown`

};


export const DROPDOWN_MENU = {

}
