import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GridDataService {
  constructor(private http: HttpClient) {}

  fetchData(
    url: string,
    pageNumber: number,
    pageSize: number,
    searchQuery: string,
    sortBy: string,
    sortOrder: string,
    filters: { [key: string]: any }
  ): Observable<any> {
    let params = new HttpParams();
    let commonParams: { [key: string]: string } = {
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      searchQuery: searchQuery,
      sortBy: sortBy,
      sortOrder: sortOrder,
    };

    Object.entries(commonParams).forEach(([key, value]) => {
      params = value ? params.set(key, value) : params;
    });

    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((option: any) => {
          params = params.append(key, option.id);
        });
      } else if (
        typeof value === 'object' &&
        value.startDate &&
        value.endDate
      ) {
        params = params.set(`${key}StartDate`, value.startDate);
        params = params.set(`${key}EndDate`, value.endDate);
      } else {
        params = params.set(key, value);
      }
    });

    return this.http.get<any>(url, { params });
  }

  createData(url: string, data: any): Observable<any> {
    return this.http.post(url, data);
  }

  updateData(url: string, data: any): Observable<any> {
    return this.http.put(url, data);
  }

  toggleData(
    url: string,
    id: string | number,
    jsonKey: string,
    value: boolean
  ): Observable<any> {
    const toggleUrl = url.replace(':id', id.toString());
    return this.http.patch(toggleUrl, { value });
  }

  deleteData(url: string, id: string | number): Observable<any> {
    const deleteUrl = `${url}/${id}`;
    return this.http.delete(deleteUrl);
  }
}
