<aside
  class="fixed h-[100vh] top-0 right-0 transform transition-transform duration-700 bg-white shadow-lg min-w-[350px] z-40 drawer"
  [ngClass]="{
    'translate-x-[100%]': !isDrawerOpen,
    'w-[700px]': hasTwoColumns
  }"
  role="dialog"
  id="drawer-{{ AddDataURL }}"
>
  <!-- close button -->
  <form
    form
    (submit)="openConfirmationModal()"
    class="h-full place-items-stretch grid"
  >
    <div class="mb-5">
      <div class="w-full justify-between drawer_header pl-5 items-center flex">
        <div class="header-font capitalize">
          {{ drawerType === "add" ? createDrawerTitle : updateDrawerTitle }}
        </div>
        <span
          class="p-1 hover:bg-[#00000010] text-gray-500 hover:text-gray-800 focus:bg-[#00000010] focus:text-gray-800 mr-2 my-2 cursor-pointer transition-colors duration-500"
          (click)="closeDrawer()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </span>
      </div>
      <!-- <div [ngClass]="maxDrawerHeight"
        class="flex flex-col flex-wrap justify-start content-start items-center"
      > -->
      <div
        [ngClass]="{maxDrawerHeight,'grid-cols-2':hasTwoColumns,'grid-cols-1':!hasTwoColumns}"
        class="grid"
      >
        <div
          *ngFor="let item of drawerConfig; let $index = index"
          class="w-[350px]"
          [ngClass]="{
            'col-span-2 w-full':
              item.dataType === 'csvString' || item.input === 'password'
          }"
        >
          <!-- if type is boolean  -->
          <div *ngIf="item.dataType == 'boolean'" class="w-full px-5 pt-4">
            <label
              *ngIf="!removeLevel"
              [for]="item.label + $index"
              class="block font-medium uppercase regular-font pb-2 text-gray-500 after:content-[' '] after:ml-0.5 after:text-red-500"
            >
            </label>

            <div class="flex items-center gap-3 h-[33px]">
              <span
                class="font-medium text-gray-500 regular-font flex items-center justify-between uppercase"
              >
                {{ item.label }}
              </span>
              <div
                class="w-fit h-fit flex justify-between items-center rounded-full"
              >
                <label
                  class="relative inline-flex items-center cursor-pointer rounded-full"
                >
                  <input
                    type="checkbox"
                    value=""
                    (change)="
                      disableFieldsOnToggle(
                        item.fieldsToDisableOnToggle,
                        drawerModel[item.jsonKey]
                      )
                    "
                    [checked]="drawerModel[item.jsonKey]"
                    class="sr-only peer focus:ring-indigo-500"
                    [tabIndex]="item.tabIndex ? item.tabIndex : $index + 1"
                    [(ngModel)]="drawerModel[item.jsonKey]"
                    [name]="item.jsonKey"
                  />
                  <div
                    class="w-7 h-4 peer bg-gray-500 peer-focus:outline-none rounded-full peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-green-500 peer-focus:ring-2 peer-focus:ring-offset-1 peer-focus:ring-indigo-400"
                  ></div>
                </label>
              </div>
            </div>
          </div>

          <!-- if type is password -->

          <div *ngIf="item.input == 'password'" class="w-full px-5 pt-4">
            <!-- time -->
            <label
              [for]="item.label + $index"
              class="block font-medium uppercase regular-font pb-2 text-gray-500"
              [ngClass]="item.isRequired === true ? requiredClass : ''"
              >{{ item.label }}</label
            >
            <div class="relative w-full">
              <input
                class="ring-2 w-full focus-within:ring-indigo-500 ring-gray-200 ring-inset py-2 pl-3 pr-8 regular-font text-gray-800 focus:outline-none"
                [tabIndex]="item.tabIndex ? item.tabIndex : $index + 1"
              />
              <!-- <mat-button (click)="togglePasswordVisibility()">ABS</mat-button> -->
              <span
                class="password-toggle-icon absolute bottom-2 right-3 text-gray-600 cursor-pointer"
                (click)="togglePasswordVisibility()"
              >
                <svg
                  *ngIf="showPassword"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  fill="currentColor"
                  width="16.5"
                  height="16.2"
                >
                  <path
                    d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"
                  />
                </svg>

                <svg
                  *ngIf="!showPassword"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  fill="currentColor"
                  width="16.5"
                  height="16.2"
                >
                  <path
                    d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                  />
                </svg>
              </span>
            </div>

            <p
              class="text-red-500 regular-font mt-1"
              [ngClass]="{
                hidden: !showValidators
              }"
            >
              {{
                validateInput(
                  drawerModel[item.jsonKey],
                  item.label,
                  item.input,
                  item.jsonKey,
                  item?.minLength,
                  item?.maxLength,
                  item?.isRequired,
                  item?.validationRegex
                )
              }}
            </p>
          </div>

          <!-- if type is time -->

          <div
            *ngIf="item.input == 'time' && item.dataType == 'time'"
            class="w-full px-5 pt-4"
          >
            <!-- time -->
            <label
              [for]="item.label + $index"
              class="block font-medium uppercase regular-font pb-2 text-gray-500"
              [ngClass]="item.isRequired === true ? requiredClass : ''"
              >{{ item.label }}</label
            >
            <input
              *ngIf="!item.dropDownOptionsURL"
              [id]="item.label + $index"
              required
              class="w-full ring-2 focus:outline-none ring-gray-200 ring-inset py-2 px-3 regular-font focus:ring-indigo-500 text-gray-800"
              placeholder="HH:MM:SS"
              [(ngModel)]="drawerModel[item.jsonKey]"
              [name]="item.jsonKey"
              [tabIndex]="item.tabIndex ? item.tabIndex : $index + 1"
              step="2"
              type="time"
            />
            <p
              class="text-red-500 regular-font mt-1"
              [ngClass]="{
                hidden: !showValidators
              }"
            >
              {{
                validateInput(
                  drawerModel[item.jsonKey],
                  item.label,
                  item.input,
                  item.jsonKey,
                  item?.minLength,
                  item?.maxLength,
                  item?.isRequired,
                  item?.validationRegex
                )
              }}
            </p>
          </div>

          <!-- if type is textarea -->
          <div *ngIf="item.input == 'textarea'" class="w-full px-5 pt-4 group">
            <label
              [for]="item.label + $index"
              class="block font-medium uppercase regular-font pb-2 text-gray-500"
              [ngClass]="item.isRequired === true ? requiredClass : ''"
            >
              {{ item.label }}
              {{ item.labelUrl && item.labelInfo ? "( " : ""
              }}<a
                [href]="item.labelUrl"
                *ngIf="item.labelUrl && item.labelInfo"
                [tabIndex]="item.tabIndex ? item.tabIndex : $index + 1"
                class="text-indigo-500 capitalize hover:underline focus:underline focus:outline-none"
                target="_blank"
                >{{ item.labelInfo }}</a
              >{{ item.labelUrl && item.labelInfo ? " )" : "" }}
            </label>

            <!-- <label
              [for]="item.label + $index"
              class="block font-medium uppercase regular-font pb-2 text-gray-500"
              [ngClass]="item.isRequired === true ? requiredClass : ''"
            >
              {{ item.label }}
            </label> -->
            <textarea
              *ngIf="!item.dropDownOptionsURL"
              [id]="item.label + $index"
              required
              [disabled]="item.isDisabled"
              class="w-full resize-none ring-2 focus:outline-none ring-gray-200 ring-inset py-2 px-3 regular-font focus:ring-indigo-500 text-gray-800"
              [(ngModel)]="drawerModel[item.jsonKey]"
              [tabIndex]="item.tabIndex ? item.tabIndex : $index + 1"
              rows="3"
              [name]="item.jsonKey"
            >
            </textarea>
            <p
              class="text-red-500 regular-font mt-1"
              [ngClass]="{
                hidden: !showValidators
              }"
            >
              {{
                validateInput(
                  drawerModel[item.jsonKey],
                  item.label,
                  item.input,
                  item.jsonKey,
                  item?.minLength,
                  item?.maxLength,
                  item?.isRequired,
                  item?.validationRegex
                )
              }}
            </p>
          </div>

          @if (item.jsonKey=='hasCertificate'&& drawerConfig[$index+1] &&
          drawerConfig[$index+1].jsonKey=="certificateName") {
          {{ certificateInput(drawerModel[item.jsonKey]) }}
          }

          <!-- DISABLED FOR FULL LOAD (CUSTOM NOT RELATED TO THE CURRENT GENERIC DRAWER SPECIFIC TO PRACTICE LOAD CONFIG) -->
          <div
            *ngIf="item.ifKey && drawerModel[item.ifKey] !== item.ifValue"
            class="w-full px-5 pt-4"
          >
            <label
              class="block font-medium uppercase regular-font pb-2 text-gray-500"
              >Load Type</label
            >
            <div class="relative inline-block text-left w-full">
              <div
                class="flex gap-x-1.5 bg-gray-100 px-3 py-2 regular-font ring-2 ring-gray-200 ring-inset text-gray-400 max-h-9 cursor-not-allowed"
              >
                <p class="text-ellipsis w-full text-start">FULL LOAD</p>
                <svg
                  class="-mr-1 h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
          <!-- if type is not boolean and not textarea -->
          <div
            *ngIf="
              item.dataType != 'boolean' &&
              item.input != 'textarea' &&
              item.dataType != 'url' &&
              item.dataType != 'time' &&
              item.input != 'password'
            "
            class="w-full px-5 pt-4"
            [ngClass]="{
              hidden:
                (!showCertificateInput && item.jsonKey == 'certificateName') ||
                (item.ifKey && drawerModel[item.ifKey] !== item.ifValue)
            }"
          >
            <label
              [for]="item.label + $index"
              *ngIf="
                item.jsonKey !== 'certificateName' &&
                item.jsonKey !== 'startCronExpression'
              "
              class="block font-medium uppercase regular-font pb-2 text-gray-500"
              [ngClass]="item.isRequired === true ? requiredClass : ''"
            >
              {{ item.label }}
            </label>
            <label
              [for]="item.label + $index"
              *ngIf="item.jsonKey == 'certificateName'"
              class="block font-medium uppercase regular-font pb-2 text-gray-500"
              [ngClass]="item.isRequired === true ? requiredClass : ''"
            >
              {{ item.label }}
            </label>

            <label
              [for]="item.label + $index"
              *ngIf="item.labelUrl && item.labelInfo"
              class="block font-medium uppercase regular-font pb-2 text-gray-500"
              [ngClass]="item.isRequired === true ? requiredClass : ''"
            >
              {{ item.label }}
              {{ "( "
              }}<a
                [href]="item.labelUrl"
                [tabIndex]="item.tabIndex ? item.tabIndex : $index + 1"
                class="text-indigo-500 capitalize hover:underline focus:underline focus:outline-none"
                target="_blank"
                >{{ item.labelInfo }}</a
              >{{ " )" }}
            </label>

            <app-dropdown
              *ngIf="
                (item.dropDownOptionsURL || item?.options?.length) &&
                item.dataType !== 'csvString'
              "
              [value]="{
                name: drawerModel[item.jsonKey],
                id: drawerModel[item.foreignKeyProperty]
              }"
              [sortDir]="item.dropdownSortDirection"
              [disabled]="item.isDisabled"
              (valueChange)="
                updateDrawerModel(item.jsonKey, item.foreignKeyProperty, $event)
              "
              [dropDownJsonKey]="item.dropdownJsonKey"
              [uniqueIdentifier]="item.uniqueIdentifier"
              [isSearchable]="item.isDropDownSearchable"
              [optionsURL]="item.dropDownOptionsURL"
              [id]="item.jsonKey"
              [isRequired]="item.isRequired"
              [tabIndex]="item.tabIndex ? item.tabIndex : $index + 1"
              [foreignKeyProperty]="item.foreignKeyProperty"
              [showNone]="item.showNone"
            >
            </app-dropdown>

            <input
              *ngIf="
                !item.dropDownOptionsURL &&
                item.dataType !== 'csvString' &&
                !item.isAutoGenerated &&
                item.dataType !== 'date'
              "
              [disabled]="item.isDisabled"
              type="{{ item.dataType }}"
              [id]="item.label + $index"
              (change)="onValueChage($event, item.jsonKey)"
              class="w-full ring-2 focus:outline-none ring-gray-200 ring-inset py-2 px-3 regular-font focus:ring-indigo-500 text-gray-800"
              [(ngModel)]="drawerModel[item.jsonKey]"
              [tabIndex]="item.tabIndex ? item.tabIndex : $index + 1"
              [name]="item.jsonKey"
            />

            <input
              *ngIf="item.dataType === 'date'"
              type="{{ item.dataType }}"
              [disabled]="item.isDisabled"
              [id]="item.label + $index"
              class="w-full ring-2 focus:outline-none ring-gray-200 ring-inset py-2 px-3 regular-font focus:ring-indigo-500 text-gray-800"
              [(ngModel)]="drawerModel[item.jsonKey]"
              [tabIndex]="item.tabIndex ? item.tabIndex : $index + 1"
              [name]="item.jsonKey"
            />

            <!-- new input field for license key  -->
            <div
              *ngIf="
                !item.dropDownOptionsURL &&
                item.dataType !== 'csvString' &&
                item.isAutoGenerated
              "
              class="relative"
            >
              <input
                type="text"
                [id]="item.label + $index"
                [name]="item.jsonKey"
                [disabled]="item.isDisabled"
                [(ngModel)]="drawerModel[item.jsonKey]"
                class="w-full ring-2 focus:outline-none cursor-text ring-gray-200 ring-inset py-2 px-3 regular-font focus:ring-indigo-500 text-gray-800"
              />

              <button
                [tabIndex]="item.tabIndex ? item.tabIndex : $index + 1"
                *ngIf="!disableLicenseKey"
                class="absolute top-[50%] right-4 hover-target cursor-pointer transform translate-x-[50%] translate-y-[-50%] rounded-full hover:bg-gray-200 focus:bg-gray-200 !h-fit p-1 focus:text-indigo-500 hover:text-indigo-500 focus:outline-none"
                (click)="generateKey(item.jsonKey, $event)"
                (keydown)="onLicenseKeyEnter(item.jsonKey, $event)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="14"
                  width="14"
                  viewBox="0 0 384 512"
                  fill="currentColor"
                >
                  <path
                    d="M0 256L28.5 28c2-16 15.6-28 31.8-28H228.9c15 0 27.1 12.1 27.1 27.1c0 3.2-.6 6.5-1.7 9.5L208 160H347.3c20.2 0 36.7 16.4 36.7 36.7c0 7.4-2.2 14.6-6.4 20.7l-192.2 281c-5.9 8.6-15.6 13.7-25.9 13.7h-2.9c-15.7 0-28.5-12.8-28.5-28.5c0-2.3 .3-4.6 .9-6.9L176 288H32c-17.7 0-32-14.3-32-32z"
                  />
                </svg>
                <div
                  class="absolute right-0 -top-7 z-20 w-fit justify-center hidden hover-content"
                >
                  <div
                    class="bottom-full right-0 w-fit rounded bg-[#00000079] px-4 py-1 text-xs text-gray-50"
                  >
                    Generate License Key
                  </div>
                </div>
              </button>
              <!-- Updated Copy Button -->
              <button
                *ngIf="drawerType === 'edit' && drawerModel[item.jsonKey]"
                class="absolute top-[50%] right-12 hover-target cursor-pointer transform translate-x-[50%] translate-y-[-50%] rounded-full hover:bg-gray-200 focus:bg-gray-200 !h-fit p-1 focus:text-indigo-500 hover:text-indigo-500 focus:outline-none"
                (click)="copyToClipboard(drawerModel[item.jsonKey], $event)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="14"
                  width="14"
                  viewBox="0 0 448 512"
                  fill="currentColor"
                >
                  <path
                    d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z"
                  />
                </svg>
                <div
                  class="absolute right-0 -top-7 z-20 w-fit justify-center hidden hover-content"
                >
                  <div
                    class="bottom-full right-0 w-fit rounded bg-[#00000079] px-4 py-1 text-xs text-gray-50"
                  >
                    Copy to Clipboard
                  </div>
                </div>
              </button>
            </div>

            <p
              class="text-red-500 regular-font mt-1"
              *ngIf="
                item.jsonKey !== 'certificateName' && item.input == 'select'
              "
              [ngClass]="{
                hidden: !showValidators
              }"
            >
              {{
                validateInput(
                  drawerModel[item.jsonKey],
                  item.label,
                  item.input,
                  item.jsonKey,
                  item?.minLength,
                  item?.maxLength,
                  item?.isRequired,
                  item?.validationRegex
                )
              }}
            </p>

            <p
              class="text-red-500 regular-font mt-1"
              *ngIf="
                item.jsonKey !== 'certificateName' && item.input !== 'select'
              "
              [ngClass]="{
                hidden: !showValidators
              }"
            >
              <!-- Select a option for {{ item.label.toLowerCase() }} -->
              {{
                validateInput(
                  drawerModel[item.jsonKey],
                  item.label,
                  item.input,
                  item.jsonKey,
                  item?.minLength,
                  item?.maxLength,
                  item?.isRequired,
                  item?.validationRegex
                )
              }}
            </p>

            <div
              *ngIf="item.dataType === 'csvString'"
              class="ring-2 focus-within:ring-indigo-500 ring-gray-200 ring-inset py-2 px-3 regular-font text-gray-800 w-[660px] focus:outline-none"
              [tabIndex]="item.tabIndex ? item.tabIndex : $index + 1"
            >
              <!-- Chip input for csvString data type -->
              <mat-chip-grid
                #chipGrid
                class="min-h-56 max-h-[18rem] overflow-y-auto first:!items-start"
              >
                <mat-chip-row
                  class="mat-chip-list-stacked !flex !items-start"
                  *ngFor="let chip of drawerModel[item.jsonKey]?.split(',')"
                  (removed)="removeChip(item.jsonKey, chip)"
                  [editable]="true"
                  (edited)="editChip(item.jsonKey, chip, $event)"
                >
                  {{ chip }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                <input
                  [id]="item.label + $index"
                  [matChipInputFor]="chipGrid"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addChip(item.jsonKey, $event)"
                  class="w-full focus:outline-none text-gray-800"
                />
              </mat-chip-grid>
              <p
                class="text-red-500 regular-font mt-1"
                [ngClass]="{
                  hidden: !containsDuplicate
                }"
              >
                Duplicate column names not allowed
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="endSection" class="w-full mt-auto mb-5 flex">
      <div class="px-5 w-full flex justify-between mt-auto">
        <button
          type="submit"
          class="px-6 py-1.5 bg-indigo-500 text-gray-200 hover:bg-indigo-400 regular-font transition-colors duration-500 shadow-md focus:outline-none ring-offset-1 focus:ring-2"
          [disabled]="isSubmitLoading"
          [tabIndex]="drawerConfig.length"
          [ngClass]="{ 'cursor-wait': isSubmitLoading }"
        >
          <app-beat-loader *ngIf="isSubmitLoading"></app-beat-loader>
          <ng-container *ngIf="!isSubmitLoading"> Save </ng-container>
        </button>
        <button
          type="button"
          [tabIndex]="drawerConfig.length + 1"
          *ngIf="drawerType != 'edit'"
          class="px-6 py-1.5 bg-gray-600 hover:bg-gray-500 text-gray-50 regular-font transition-colors duration-500 shadow-md focus:outline-none ring-offset-1 focus:ring-2 ring-gray-400"
          (click)="onResetClick()"
        >
          Reset
        </button>
      </div>
    </div>
  </form>
</aside>
<!-- backtrop effect -->
<div
  class="absolute w-screen h-screen top-0 left-0 z-30 bg-[#333333b5] transition-[visibility] duration-500 ease-in-out"
  [ngClass]="isDrawerOpen ? 'visible' : 'invisible'"
  (click)="closeDrawer()"
></div>
<!-- toast component -->
<app-toast></app-toast>
<app-confirmation-modal
  [isVisible]="isConfirmationModalVisible"
  (onActionEventEmitter)="handleModalAction($event)"
  [title]="'Save Changes'"
  [message]="'Are you sure you want to save your changes?'"
></app-confirmation-modal>
