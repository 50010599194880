import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PracticeLoadDataService {
  constructor() {}

  private practiceLoadId: string = '';
  private loadDate: Date = new Date();
  private loadTypeId: string = '';
  private loadType: string = '';
  private practiceName: string = '';
  private pmsId: string = '';

  getPracticeLoadId(): string {
    return this.practiceLoadId;
  }

  setPracticeLoadId(practiceLoadId: string): void {
    this.practiceLoadId = practiceLoadId;
  }

  getLoadDate(): Date {
    return this.loadDate;
  }

  setLoadDate(utcDate: string): void {
    this.loadDate = new Date(utcDate);
  }

  getLoadTypeId(): string {
    return this.loadTypeId;
  }

  setLoadTypeId(loadTypeId: string): void {
    this.loadTypeId = loadTypeId;
  }

  getPracticeName(): string {
    return this.practiceName;
  }

  setPracticeName(practiceName: string): void {
    this.practiceName = practiceName;
  }

  getLoadType(): string {
    return this.loadType;
  }

  setLoadType(loadType: string): void {
    this.loadType = loadType;
  }

  setPMSId(practiceId: string): void {
    this.pmsId = practiceId;
  }

  getPMSId(): string {
    return this.pmsId;
  }
}
