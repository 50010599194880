import { Component, EventEmitter, Output } from '@angular/core';
import { RouteService } from '../../service/route.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent {
  activeRoute: string[] = [];
  environment:string = environment.envName;
  isMinimized:boolean = false;
  @Output() collapsedStateChanged = new EventEmitter<boolean>();
  userRole = localStorage.getItem('userRole');

  // variables to show and hide childrens of nav

  homeMenu: boolean = false;
  configurationMenu: boolean = true;
  reportsMenu: boolean = false;
  agentReleaseMenu: boolean = false;

  toggleHomeMenu() {
    this.homeMenu = !this.homeMenu;
  }

  toggleConfigurationMenu() {
    this.configurationMenu = !this.configurationMenu;
  }

  openConfigurationMenu(){
    this.configurationMenu = true;
  }

  toggleReportsMenu() {
    this.reportsMenu = !this.reportsMenu;
  }

  toggleAgentReleaseMenu(){
    this.agentReleaseMenu = !this.agentReleaseMenu;
  }

  constructor(private routeService: RouteService) {
    /**
     * calling async getRoutes method  which will keep
     * updating the value of activeRoute whenever there
     * a change in app route & toggleByUrl will open menu
     * based on app route
     */

    this.getRoutes().then(() => {
      this.toggleByUrl();
    });
  }

  async getRoutes() {
    await this.routeService.getRoutes().subscribe((routes) => {
      this.activeRoute = routes;
      this.activeRoute.shift();
    });
  }

  // code to toggle the correct menu item based on url

  toggleByUrl(): void {
    switch (this.activeRoute[1]) {
      case 'configuration':
        this.openConfigurationMenu();
        break;

      case 'reports':
        this.toggleReportsMenu();
        break;
        
      case 'home':
        this.toggleHomeMenu();
        break;
      case 'manage-agent-releases':
        this.toggleAgentReleaseMenu();
        break;
    }
  }

  toggleSideBar(){
    this.isMinimized = !this.isMinimized;
    this.collapsedStateChanged.emit(this.isMinimized);
  }

}
