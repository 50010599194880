<div class="overflow-hidden">
  <app-dynamic-grid
    [gridConfig]="gridConfig"
    [getDataUrl]="getDataUrl"
    [sortBy]="'pmsMasterName'"
    [sortOrder]="'asc'"
    (addClicked)="onAddHandler()"
    (editClicked)="onEditHandler($event)"
    (onToggleShowToastEmitter)="showToast($event)"
    [toggleUrl]="toggleUrl"
  ></app-dynamic-grid>
</div>
<app-drawer
  #drawerComponent 
  [drawerModel]="pmsObjectType"
  [drawerConfig]="drawerInputConfig"
  [AddDataURL]="addDataUrl"
  [editDataURL]="editDataUrl"
  (refresh)="refreshHandler()"
  [createDrawerTitle]="'Create New PMS Object'"
  [updateDrawerTitle]="'Update PMS Object'"
  (resetData)="resetEntry()"
  [hasTwoColumns]="true"
></app-drawer>

<app-toast></app-toast>
