<div class="relative inline-block text-left w-full">
  <div>
    <div
      *ngIf="isSearchable"
      (click)="toggleDropdown()"
      [tabIndex]="tabIndex"
      class="group flex gap-x-1.5 bg-white px-3 items-center regular-font ring-2 focus:outline-none overflow-hidden ring-gray-200 ring-inset has-[input:focus]:ring-indigo-500 text-gray-800 hover:bg-gray-50 max-h-9"
    >
      <input
        type="text"
        [(ngModel)]="searchValue"
        class="group searchValue !bg-transparent text-ellipsis w-full text-start focus:outline-none hover:outline-none bg-white"
        (keyup)="filterOptions($event)"
        [placeholder]="value?.name || 'Select'"
      />
      <svg
        class="-mr-1 h-5 w-5 text-gray-400 transform duration-100 delay-75"
        [ngClass]="{ 'rotate-180': showDropdown }"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
          clip-rule="evenodd"
        />
      </svg>
      <div
        *ngIf="isLoading"
        class="absolute right-[20%] !w-fit !my-auto -translate-y-[50%] top-[50%]"
      >
        <app-beat-loader></app-beat-loader>
      </div>
    </div>

    <button
      *ngIf="!isSearchable"
      type="button"
      class="w-full inline-flex justify-between gap-x-1.5 bg-white px-3 py-2 regular-font ring-2 focus:outline-none overflow-hidden ring-gray-200 ring-inset focus:ring-indigo-500 text-gray-800 hover:bg-gray-50 max-h-9"
      aria-expanded="true"
      [disabled]="disabled"
      [tabIndex]="tabIndex"
      aria-haspopup="true"
      (click)="toggleDropdown()"
    >
      <p class="text-ellipsis w-full text-start">
        {{ value?.name || "None" }}
      </p>

      <svg
        class="-mr-1 h-5 w-5 text-gray-400 transform duration-100 delay-75"
        [ngClass]="{ 'rotate-180': showDropdown }"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
          clip-rule="evenodd"
        />
      </svg>
      <div
        *ngIf="isLoading"
        class="absolute right-[20%] !w-fit !my-auto -translate-y-[50%] top-[50%]"
      >
        <app-beat-loader></app-beat-loader>
      </div>
    </button>
  </div>

  <div
    class="absolute right-0 top-7 z-10 mt-2 w-full origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-[200px] overflow-auto"
    [ngClass]="showDropdown ? 'flex' : 'hidden'"
    [@menuAnimation]="showDropdown ? 'visible' : 'hidden'"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <div class="py-1 w-full" role="none">
      <!-- skeleton menu to be shown during loading  -->
      <div class="py-1 w-full" role="none" *ngIf="isLoading && showDropdown">
        <ul>
          <li>
            <div
              class="skeleton text-gray-700 block mx-4 my-3 rounded-md regular-font h-[16px] cursor-pointer"
              role="menuitem"
              tabindex="-1"
              id="menu-item-3"
            ></div>
          </li>
          <li>
            <div
              class="skeleton text-gray-700 block mx-4 rounded-md my-3 regular-font h-[16px] cursor-pointer"
              role="menuitem"
              tabindex="-1"
              id="menu-item-1"
            ></div>
          </li>
          <li>
            <div
              class="skeleton text-gray-700 block mx-4 rounded-md my-3 regular-font h-[16px] cursor-pointer"
              role="menuitem"
              tabindex="-1"
              id="menu-item-1"
            ></div>
          </li>
        </ul>
      </div>

      <div
        *ngIf="!isRequired && showDropdown && showNone && !isLoading"
        class="text-gray-700 block px-4 w-full py-2 regular-font cursor-pointer hover:bg-gray-100 focus:bg-gray-100 hover:text-gray-900 focus:text-gray-900"
        role="menuitem"
        [ngClass]="{ 'bg-gray-100 text-gray-900': value === null }"
        tabindex="-1"
        (click)="
          onChange({ id: '00000000-0000-0000-0000-000000000000', name: 'All' })
        "
      >
        All
      </div>

      <ul *ngIf="showDropdown && !isLoading">
        <li *ngFor="let item of options; let i = index">
          <div
            *ngIf="item.name !== 'None'"
            class="text-gray-700 block px-4 w-full py-2 regular-font cursor-pointer hover:bg-gray-100 focus:bg-gray-100 hover:text-gray-900 focus:text-gray-900"
            role="menuitem"
            [ngClass]="{ 'bg-gray-100 text-gray-900': item.id === value?.id }"
            tabindex="-1"
            id="menu-item-{{ i }}"
            (click)="onChange(item)"
          >
            {{ item.name }}
          </div>
        </li>
      </ul>
      <p *ngIf="showDropdown && !isLoading"
        class="regular-font pl-4"
        [ngClass]="{ hidden: options && options.length }"
      >
        No record(s) found
      </p>
    </div>
  </div>
</div>
