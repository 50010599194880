import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { DashboardService } from './dashboard.service';
import moment from 'moment';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { Observable, forkJoin } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';

interface LoadDataStatus {
  date: string;
  completedExtraction: number;
  completedInput: number;
  completedBase: number;
  initiated: number;
  failed: number;
  interrupted: number;
  error: number;
  inProgressExtraction: number;
}

interface DashboardData {
  pmsCount: number;
  practiceCount: number;
  usersCount: number;
  installedPracticesCount: number;
}

interface DataLoadSummaryColumn {
  name: string;
  jsonKey: string;
  dataType: 'string' | 'number' | 'dateTime' | 'date';
  isSortable?: boolean;
  toUppercase?: boolean;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  constructor(
    private dashboardService: DashboardService,
    private changeDetectorRef: ChangeDetectorRef,
    private _liveAnnouncer: LiveAnnouncer
  ) {}
  isLoading: boolean = false;
  showLoadDetails: boolean = false;
  isDataLoadSummaryLoading: boolean = false;
  dashboardData: DashboardData;
  dataLoad: LoadDataStatus[];
  practiceLoadSummary: LoadDataStatus;
  dataLoadStatus: (string | number)[][] = [
    ['Date', 'IN-PROGRESS-DATA-EXTRACTION', 'COMPLETED-EXTRACTION', 'COMPLETED-INPUT', 'COMPLETED-BASE', 'FAILED'],
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;

  dataLoadSummaryColumns: DataLoadSummaryColumn[] = [
    { name: 'Practice Code', jsonKey: 'practiceCode', dataType: 'string' },
    { name: 'PMS', jsonKey: 'pms', dataType: 'string' },
    {
      name: 'PMS Version Updated?',
      jsonKey: 'versionUpdated',
      dataType: 'string',
      isSortable: false,
    },
    { name: 'PMS Agent Version', jsonKey: 'pmsAgentVersion', dataType: 'string' },
    { name: 'Status', jsonKey: 'status', dataType: 'string' },
    { name: 'Duration (mins)', jsonKey: 'duration', dataType: 'number' },
    { name: 'Start Time (EST)', jsonKey: 'startTime', dataType: 'dateTime' },
    { name: 'End Time (EST)', jsonKey: 'endTime', dataType: 'dateTime' },
    {
      name: 'Error Details',
      jsonKey: 'errorDetails',
      dataType: 'string',
      isSortable: false,
    },
  ];

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getLoadSummaryColumnNames() {
    return this.dataLoadSummaryColumns.map((e) => e.jsonKey);
  }

  ngOnInit() {
    this.fetchData();
  }

  setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  fetchDashboardData() {
    return new Observable((observer) => {
      this.dashboardService
        .getDashboardData(API_ENDPOINTS.DASHBOARD)
        .subscribe({
          next: ({ data }: { data: DashboardData }) => {
            this.dashboardData = data;
            this.fetchInstalledPracticesCount();
            observer.next();
            observer.complete();
          },
          error: (err) => {
            console.error('Error fetching dashboard data', err);
            observer.error(err);
          },
        });
    });
  }

  fetchInstalledPracticesCount() {
    this.dashboardService
      .getDashboardData(API_ENDPOINTS.GETALLPRACTICES)
      .subscribe({
        next: ({ data }: any) => {
          const installedCount = data.filter((practice: any) => practice.isInstalled === true && practice.isActive == true).length;
          if (this.dashboardData) {
            this.dashboardData.installedPracticesCount = installedCount;
          }
        },
        error: (err) => {
          console.error('Error fetching practices data', err);
        }
      });
  }

  fetchLoadDataStatus() {
    return new Observable((observer) => {
      this.dashboardService
        .getLoadDataStatus(API_ENDPOINTS.DASHBOARD_DATA_LOAD_STATUS)
        .subscribe({
          next: ({ data }: { data: LoadDataStatus[] }) => {
            this.dataLoad = data;
            this.dataLoadStatus = [
              ['Date', 'IN-PROGRESS-DATA-EXTRACTION', 'COMPLETED-EXTRACTION', 'COMPLETED-INPUT', 'COMPLETED-BASE', 'FAILED'],
            ];

            data?.forEach((e: LoadDataStatus) => {
              this.dataLoadStatus.push([
                moment.utc(e.date).format('YYYY-MM-DD'),
                e.inProgressExtraction,
                e.completedExtraction,
                e.completedInput,
                e.completedBase,
                e.failed,
              ]);
            });

            observer.next();
            observer.complete();
          },
          error: (err) => {
            console.error('Error fetching load data status', err);
            observer.error(err);
          },
        });
    });
  }

  fetchData() {
    this.setLoadingState(true);

    forkJoin([this.fetchDashboardData(), this.fetchLoadDataStatus()]).subscribe(
      {
        next: () => {
          this.setLoadingState(false);
        },
        error: (err) => {
          console.error('Error fetching data', err);
          this.setLoadingState(false);
        },
      }
    );
  }

  getUserRole() {
    return localStorage.getItem('userRole');
  }

  handleChartClick(date: string) {
    const startDate = `${date} 18:00:00`;
    const momentStartDate = moment.tz(startDate,"America/New_York");
    const endDate = `${date} 08:00:00`;
    const momentEndDate = moment.tz(endDate,"America/New_York").add(1, 'days');
    this.isDataLoadSummaryLoading = true;
    this.dashboardService
      .getDashboardLoadSummary(API_ENDPOINTS.DASHBOARD_LOAD_SUMMARY, {
        startDateTime: momentStartDate.format('YYYY-MM-DD HH:mm:ss'),
        endDateTime: momentEndDate.format('YYYY-MM-DD HH:mm:ss'),
      })
      .subscribe({
        next: (response: any) => {
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.sort = this.sort;
        },
        error: (err) => {
          console.error('Error fetching load data summary', err);
          this.isDataLoadSummaryLoading = false;
        },
        complete: () => {
          this.isDataLoadSummaryLoading = false;
          this.changeDetectorRef.detectChanges();
        },
      });

    this.showLoadDetails = true;
  }

  hideChart() {
    this.showLoadDetails = false;
    this.practiceLoadSummary = null;
  }
}
