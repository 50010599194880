import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { HttpClient } from '@angular/common/http';
import { PracticeLoadDataService } from './data/practice-load-data.service';
import { RouteService } from '../../../service/route.service';
import { MAX_INT_32 } from '../../shared/constants/values';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-practice-load-detail',
  templateUrl: './practice-load-detail.component.html',
  styleUrl: './practice-load-detail.component.scss',
})
export class PracticeLoadDetailComponent {
  gridConfig: GridConfig = {
    columns: [
      {
        label: 'PMS Object Name',
        jsonKey: 'pmsObjectName',
        dataType: 'string',
      },
      { label: 'Status Code', jsonKey: 'pmsStatusCode', dataType: 'number' },
      {
        label: 'PMS Agent Status',
        jsonKey: 'pmsAgentStatus',
        dataType: 'string',
        width: 'full',
      },
      {
        label: 'Total Records Extracted',
        jsonKey: 'totalRecordsExtracted',
        dataType: 'number',
      },
      { label: 'Start Time', jsonKey: 'startTime', dataType: 'date' },
      { label: 'End Time', jsonKey: 'endTime', dataType: 'date' },
      { label: 'Duration (HH:MM:SS)', jsonKey: 'duration', dataType: 'string' },
      {
        label: 'Message',
        jsonKey: 'message',
        dataType: 'string',
        width: 'full',
      },
      { label: 'Error Details', jsonKey: 'errorDetails', dataType: 'string',width:'full' },
      {
        label: 'Resolution',
        jsonKey: 'resolution',
        dataType: 'string',
        width: 'full',
        isSortable: false,
      },
    ],
    headers: [
      { label: 'Practice', value: '' },
      { label: 'Load Date', value: '' },
      { label: 'Load Type', value: '' },
    ],
    filters: [
      {
        label: 'PMS Object',
        jsonKey: 'pmsObjectId',
        type: 'multi',
        options: [],
        width: 'w-50',
      },
    ],

    editable: false,
    deletable: false,
    displayAdd: false,
    showAllPagination: true,
  };

  @Input() practiceLoadId!: string;
  @Input() loadDate!: Date;
  @Input() loadTypeId!: string;
  @Input() practiceName!: string;
  loadType: string;
  pmsId: string;
  getDataUrl = API_ENDPOINTS.PRACTICE_LOAD_DETAIL;
  getPMSObjectsURL = API_ENDPOINTS.PMS_OBJECT;
  private routerSubscription: Subscription;
  routes: string[];
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;

  constructor(
    private http: HttpClient,
    private practiceLoadData: PracticeLoadDataService,
    private routeService: RouteService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadDate = this.practiceLoadData.getLoadDate();
    this.loadType = this.practiceLoadData.getLoadType();
    this.loadTypeId = this.practiceLoadData.getLoadTypeId();
    this.practiceLoadId = this.practiceLoadData.getPracticeLoadId();
    this.practiceName = this.practiceLoadData.getPracticeName();
    this.pmsId = this.practiceLoadData.getPMSId();
    this.getDataUrl = `${this.getDataUrl}?practiceLoadId=${this.practiceLoadId}`;

    this.gridConfig.headers[0].value = this.practiceName;
    this.gridConfig.headers[1].value = this.formatLoadDate(this.loadDate);
    this.gridConfig.headers[2].value = this.loadType;
  }

  formatLoadDate(date: Date): string {
    return date
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      .replace(/(\d+)\/(\d+)\/(\d+),/, '$3-$1-$2');
  }

  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  ngAfterViewInit(): void {
    this.routerSubscription = this.routeService
      .getRoutes()
      .subscribe((data) => {
        this.routes = data;
        this.routes.shift();
        if (
          this.routes.length == 5 &&
          (!this.loadDate || !this.loadTypeId || !this.practiceLoadId)
        ) {
          this.routes.pop();
          this.routes.pop();
          this.routeService.redirectTO(this.routes.join('/'));
        }
        this.cdr.detectChanges();
      });

    this.fetchPMSObjectNameOptions();
  }

  fetchPMSObjectNameOptions() {
    this.http
      .get<any>(`${this.getPMSObjectsURL + '/dropdown'}`, {
        params: { pmsId: this.pmsId },
      })
      .subscribe((response) => {
        response.data = response.data
          .filter((option) => option.isActive)
          .map((option) => ({
            ...option,
            name: option.name,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        this.gridConfig.filters.find(
          (filter) => filter.jsonKey === 'pmsObjectId'
        ).options = response;
      });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
