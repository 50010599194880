// add a new path here
export const BREADCRUMB_NAMES = {
  "dashboard": 'Dashboard',
  "reports":"Reports",
  "user-role-management":"User Role Management",
  "configuration": 'Configuration',
  "practices": 'Practices',
  "pms-objects": 'PMS Objects',
  "phi-records": 'PHI Records',
  "pms": 'PMS',
  "load-schedules": 'Load Schedules',
  "practice-load-configurations":"Practice Load Configurations",
  "profile": "Profile",
  "pms-agent-health-status":"PMS Agent Health Status",
  "practice-load-summary":"Practice Load Summary",
  "details": "Details",
  "practice-installation-logs" : "Practice Installation Log",
  "configure": "Configure",
  "manage-agent-releases" : "Manage Agent Releases",
  "assign": "Assign",
  "practice-error-logs":"Practice Error Logs",
  "user-logs":"User Logs",
  "pms-agent-heartbeats": "PMS Agent Heartbeats",
  "forbidden": "Forbidden"
};