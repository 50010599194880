export const DRAWER_PLACEHOLDERS = {
  SELECT_TYPE: 'Select Type',
  SELECT_PMS: 'Select PMS',
  SELECT_OBJECT:'Select Object',
  SELECT_PRACTICE:'Select Practice',
  SELECT_LOAD_SCHEDULE:'Select Load Schedule',
  SELECT_STATUS:'Select Status',
  SELECT_ROLE:'Select Role',
  SELECT_USER:'Select User',
  DISABLED:'Disabled',
  DATABASESERVERTYPE:'Select Database Server',
  SELECT_PMSNAME:'Select PMS Name',
  SELECT_AGENTVERSION:'Select Agent version',
  SELECT_AGENTRELEASE:'Select Agent Release',
  SELECT_LOADTYPE:"Select Load Type"
};
