import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {
  ActivatedRoute,
  EventType,
  NavigationEnd,
  Router,
  Scroll,
} from '@angular/router';
import { PracticeLoadDataService } from '../practice-load-detail/data/practice-load-data.service';
import { RouteService } from '../../../service/route.service';
import moment from 'moment';
import { Subscription } from 'rxjs';
import {
  MAX_INT_32,
  PRACTICE_LOAD_STATUS_CODES,
} from '../../shared/constants/values';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';

@Component({
  selector: 'app-practice-load-summary',
  templateUrl: './practice-load-summary.component.html',
  styleUrl: './practice-load-summary.component.scss',
})
export class PracticeLoadSummaryComponent {
  toggleUrl: string;
  showPracticeLoadDetail: boolean = false;
  routes: string[];

  constructor(
    private http: HttpClient,
    private router: Router,
    private practiceLoadData: PracticeLoadDataService,
    private routeService: RouteService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {}

  gridConfig: GridConfig = {
    columns: [
      {
        label: 'Acknowledge',
        jsonKey: 'isAcknowledged',
        dataType: 'toggle',
        toggleUrl: API_ENDPOINTS.PRACTICE_LOAD_SUMMARY_ACKNOWLEDGEMENT,
        isSortable: false,
      },
      { dataType: 'string', label: 'Practice Code', jsonKey: 'practiceCode' },
      {
        dataType: 'text-wrap',
        label: 'Practice Name',
        jsonKey: 'practiceName',
      },
      { dataType: 'string', label: 'PMS Name', jsonKey: 'pmsName' },
      {
        dataType: 'string',
        label: 'Configured PMS Version',
        jsonKey: 'pmsVersion',
      },
      {
        dataType: 'string',
        label: 'Latest PMS Version',
        jsonKey: 'latestPMSVersion',
      },
      // { dataType: 'string', label: 'Load Type', jsonKey: 'loadTypeName' },
      { dataType: 'date', label: 'Start Time', jsonKey: 'startTime' },
      { dataType: 'date', label: 'End Time', jsonKey: 'endTime' },
      {
        dataType: 'string',
        label: 'Duration (HH:MM:SS)',
        jsonKey: 'duration',
        isSortable: false,
      },
      {
        dataType: 'string',
        label: 'Load Schedule Name',
        jsonKey: 'loadSchedule',
      },
      { label: 'Status Code', jsonKey: 'pmsStatusCode', dataType: 'number' },
      { dataType: 'string', label: 'Load Status', jsonKey: 'pmsAgentStatus',width:'full' },
      {
        dataType: 'number',
        label: 'Total Records Extracted',
        jsonKey: 'totalRecordsExtracted',
      },
      {
        dataType: 'string',
        label: 'Message',
        jsonKey: 'message',
        width: 'full',
        isSortable: false,
      },
      {
        label: 'Resolution',
        jsonKey: 'resolution',
        dataType: 'string',
        width:'full',
        isSortable: false,
      },
      {
        label: 'Created By',
        jsonKey: 'createdByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Created At',
        jsonKey: 'createdAt',
        dataType: 'date',
        hideOnToggle: true,
      },
      {
        label: 'Modified By',
        jsonKey: 'modifiedByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Modified At',
        jsonKey: 'modifiedAt',
        dataType: 'date',
        hideOnToggle: true,
      },
    ],
    filters: [
      {
        type: 'multi',
        jsonKey: 'practiceId',
        label: 'Practice',
        options: [],
        width: 'w-50',
      },
      {
        type: 'multi',
        jsonKey: 'status',
        label: 'Status',
        options: [],
        width: 'w-50',
      },
    ],
    displayHideAuditColumnsToggle: true,
    showAllPagination: true,
  };

  getDataUrl: string = API_ENDPOINTS.PRACTICE_LOAD_SUMMARY;
  getPracticesUrl = API_ENDPOINTS.GETALLPRACTICES;
  getStatusUrl = API_ENDPOINTS.PMS_STATUS;
  practiceLoadDetail: any;
  ngOnInit() {
    this.showPracticeLoadDetail = false;
    this.fetchFilterOptions();
    
    // Check if the current URL contains '/details'
    const currentUrl = this.router.url;
    const isDetailView = currentUrl.includes('/details');
    
    this.routeService.getRoutes().subscribe((data) => {
      this.routes = data;
      
      // If the URL has '/details', we should show the detail view regardless of stored data
      if (isDetailView) {
        this.showPracticeLoadDetail = true;
        
        // If data isn't in the service, try to extract ID from URL and fetch it
        if (!(this.practiceLoadData.getLoadDate() instanceof Date) ||
            this.practiceLoadData.getLoadTypeId() === '' ||
            this.practiceLoadData.getPracticeLoadId() === '') {
          
          const urlParts = currentUrl.split('/');
          const detailsIndex = urlParts.indexOf('details');
          
          if (detailsIndex > 0) {
            const practiceLoadId = urlParts[detailsIndex - 1];
            if (practiceLoadId) {
              // Only set the ID in the service so the detail component can fetch the data
              this.practiceLoadData.setPracticeLoadId(practiceLoadId);
            }
          }
        }
      } else {
        // For non-detail views, use the original condition
        this.showPracticeLoadDetail =
          this.routes.includes('details') &&
          this.practiceLoadData.getLoadDate() instanceof Date &&
          this.practiceLoadData.getLoadTypeId() !== '' &&
          this.practiceLoadData.getPracticeLoadId() !== '';
      }
    });
  }

  public routerSubscription: Subscription;

  ngAfterViewInit(): void {
    this.routerSubscription = this.routeService
      .getRoutes()
      .subscribe((data) => {
        this.routes = data;
        this.routes.shift();
        if (this.routes.length == 2) {
          this.routeService.redirectTO(
            this.routes.join('/').concat('/practice-load-summary')
          );
        }
        this.cdr.detectChanges();
      });
  }

  // toast related properties
  @ViewChild(ToastComponent) toastComponent!: ToastComponent;
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;

  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
    this.refreshHandler();
  }

  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  onAddHandler() {
    throw new Error('Method not implemented.');
  }

  onEditHandler($event: any) {
    throw new Error('Method not implemented.');
  }

  fetchFilterOptions() {
    this.fetchPracticeLoadOptions();
    this.fetchPMSStatusOptions();
  }

  fetchPracticeLoadOptions() {
    this.http
      .get<any>(this.getPracticesUrl)
      .subscribe((response) => {
        response.data = response.data
          .map((option) => ({
            ...option,
            name: option.practiceCode,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        this.gridConfig.filters.find(
          (filter) => filter.jsonKey === 'practiceId'
        ).options = response;
      });
  }

  fetchPMSStatusOptions() {
    this.http
      .get<any>(this.getStatusUrl)
      .subscribe((response) => {
        response.data = response.data
          .map((option) => ({
            ...option,
            name: option.source + ' - ' + option.name,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
          response.data = response.data
          .filter((record) => PRACTICE_LOAD_STATUS_CODES.includes(record.id))
          .sort((a, b) => PRACTICE_LOAD_STATUS_CODES.indexOf(a.id) - PRACTICE_LOAD_STATUS_CODES.indexOf(b.id));
        this.gridConfig.filters.find(
          (filter) => filter.jsonKey === 'status'
        ).options = response;
      });
  }

  rowClicked(data) {
    this.router.navigate([
      `/dashboard/reports/practice-load-summary/${data.id}/details`,
    ]);
    this.practiceLoadData.setPracticeLoadId(data.id);
    this.practiceLoadData.setPracticeName(data.practiceName);
    this.practiceLoadData.setPracticeCode(data.practiceCode);
    this.practiceLoadData.setPMSId(data.pmsId);
    this.practiceLoadData.setLoadDate(data.startTime);
    this.practiceLoadData.setLoadTypeId(data.loadTypeId);
    this.practiceLoadData.setLoadType(data.loadTypeName);
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
