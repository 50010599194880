import {
  ConfigurableFocusTrap,
  ConfigurableFocusTrapFactory,
} from '@angular/cdk/a11y';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrl: './confirmation-modal.component.scss',
})
export class ConfirmationModalComponent {
  // Input properties
  @Input() severity!: 'success' | 'error' | 'info' | 'warn';
  @Input() title!: string;
  @Input() message!: string;
  @Input() isVisible!: boolean;

  // Event Emitters
  @Output() onActionEventEmitter: EventEmitter<'confirm' | 'cancel'> =
    new EventEmitter();

  // focus trap
  focusTrap: ConfigurableFocusTrap = null;

  public get focusTrapFactory(): ConfigurableFocusTrapFactory {
    return this._focusTrapFactory;
  }
  public set focusTrapFactory(value: ConfigurableFocusTrapFactory) {
    this._focusTrapFactory = value;
  }

  constructor(private _focusTrapFactory: ConfigurableFocusTrapFactory) {}

  ngOnInit() {
    document.addEventListener('keydown', this.handleEscapePress.bind(this));
  }

  handleEscapePress(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.isVisible) {
      this.onCancel();
    }
  }

  handleOutsideClick() {
    this.onCancel();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isVisible']) {
      if (this.isVisible) {
        // The modal has become visible, so activate the focus trap
        this.focusTrap = this._focusTrapFactory.create(
          document.getElementById(this.title),
          { defer: true }
        );
        this.focusTrap?.focusFirstTabbableElementWhenReady();
      } else {
        // The modal has been hidden, so deactivate the focus trap
        this.focusTrap?.destroy();
      }
    }
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.handleEscapePress.bind(this));
  }

  onConfirm() {
    this.onActionEventEmitter.emit('confirm');
  }

  onCancel() {
    this.onActionEventEmitter.emit('cancel');
  }
}
