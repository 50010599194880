import { Component } from '@angular/core';

@Component({
  selector: 'app-forbidden-request',
  templateUrl: './forbidden-request.component.html',
  styleUrl: './forbidden-request.component.scss'
})
export class ForbiddenRequestComponent {

}
