import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { DrawerComponent } from '../../shared/drawer/drawer.component';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { DrawerInputConfig } from '../../../models/drawer-model';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { NIL as NIL_UUID } from 'uuid';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';
import { Subscription } from 'rxjs';
import { RouteService } from '../../../service/route.service';

export interface PracticeLoadConfigurationData {
  id: string;
  practiceName?: string;
  loadScheduleName?: string;
  loadTypeName?: string;
  loadTypeId?: number;
  batchSize: number;
  isActive: boolean;
  loadScheduleId: string;
  practiceId: string;
}

@Component({
  selector: 'app-practice-load-schedule',
  templateUrl: './practice-load-configuration.component.html',
  styleUrls: ['./practice-load-configuration.component.scss'],
})
export class PracticeLoadConfigurationComponent {
  drawerData: PracticeLoadConfigurationData = {
    id: NIL_UUID,
    practiceName: 'Select Practice',
    loadScheduleName: 'Select Load Schedule',
    loadTypeName: 'FULL LOAD',
    loadTypeId: 1,
    batchSize: 2500,
    isActive: true,
    loadScheduleId: NIL_UUID,
    practiceId: NIL_UUID,
  };

  @ViewChild(DrawerComponent)
  drawerComponent!: DrawerComponent<PracticeLoadConfigurationData>;
  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;

  userRole = localStorage.getItem('userRole');

  gridConfig: GridConfig = {
    columns: [
      {
        label: 'Active',
        jsonKey: 'isActive',
        dataType: 'toggle',
        isSortable: false,
      },
      {
        label: 'Load Schedule Name',
        jsonKey: 'loadScheduleName',
        dataType: 'string',
      },
      {
        label: 'Load Schedule Description',
        jsonKey: 'description',
        dataType: 'string',
        width: 'full',
        isSortable: false,
      },
      {
        label: 'Batch Size',
        jsonKey: 'batchSize',
        dataType: 'number',
      },
      { label: 'Practice Code', jsonKey: 'practiceCode', dataType: 'string' },
      {
        label: 'Practice Name',
        jsonKey: 'practiceName',
        dataType: 'text-wrap',
      },
      {
        label: 'PMS Name',
        jsonKey: 'pmsName',
        dataType: 'string',
        width: 'full',
      },
      {
        label: 'Configured PMS Version',
        jsonKey: 'configuredPMSVersion',
        dataType: 'text-wrap',
        isSortable: false,
      },
      {
        dataType: 'string',
        jsonKey: 'latestPMSVersion',
        label: 'Latest PMS Version',
      },
      {
        label: 'Timezone',
        jsonKey: 'timezone',
        dataType: 'string',
        width: 'full',
        isSortable: false,
      },
      // { label: 'Notes', jsonKey: 'notes', dataType: 'text-wrap', isSortable:false },
      {
        label: 'Last Load Status Code',
        jsonKey: 'lastLoadStatusCode',
        dataType: 'number',
        isSortable: false,
      },
      {
        label: 'Last Load Status',
        jsonKey: 'lastLoadStatus',
        dataType: 'string',
        isSortable: false,
        width: 'full',
      },
      {
        label: 'Last Load Message',
        jsonKey: 'lastLoadMessage',
        dataType: 'string',
        isSortable: false,
        width: 'full',
      },
      {
        label: 'Last Load Date',
        jsonKey: 'lastLoadDate',
        dataType: 'date',
        isSortable: false,
        width: 'full',
      },
      {
        label: 'Created By',
        jsonKey: 'createdByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Created At',
        jsonKey: 'createdAt',
        dataType: 'date',
        hideOnToggle: true,
      },
      {
        label: 'Modified By',
        jsonKey: 'modifiedByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Modified At',
        jsonKey: 'modifiedAt',
        dataType: 'date',
        hideOnToggle: true,
      },
    ],
    editable: true,
    deletable: false,
    displayAdd: true,
    isToggleDisabled: true,
    displayHideAuditColumnsToggle: true,
    uniqueTableName:'PracticeLoadConfiguration'
  };

  getDataUrl = API_ENDPOINTS.PRACTICE_LOAD_CONFIGURATION;
  toggleUrl = API_ENDPOINTS.TOGGLE_PRACTICE_LOAD_CONFIGURATION_ACTIVE;
  addDataUrl = API_ENDPOINTS.PRACTICE_LOAD_CONFIGURATION;
  editDataUrl = API_ENDPOINTS.PRACTICE_LOAD_CONFIGURATION;

  drawerInputConfig: DrawerInputConfig[] = [
    {
      label: 'Practice Code',
      dataType: 'select',
      input: 'select',
      jsonKey: 'practiceName',
      foreignKeyProperty: 'practiceId',
      dropDownOptionsURL: `${API_ENDPOINTS.PRACTICES}`,
      dropdownJsonKey: 'practiceCode',
      dropdownSortDirection: 'asc',
      isRequired: true,
      isDropDownSearchable: true,
    },
    {
      label: 'Load Schedule Name',
      dataType: 'select',
      input: 'select',
      jsonKey: 'loadScheduleName',
      foreignKeyProperty: 'loadScheduleId',
      dropDownOptionsURL: `${API_ENDPOINTS.LOAD_SCHEDULE}`,
      dropdownJsonKey: 'name',
      dropdownSortDirection: 'asc',
      isRequired: true,
    },
    // {
    //   label: 'Do you want to reset all data of the selected practice?',
    //   dataType: 'select',
    //   input: 'select',
    //   jsonKey: 'resetAllName',
    //   foreignKeyProperty: 'resetAllId',
    //   dropDownOptionsURL: `${API_ENDPOINTS.PRACTICE_LOAD_RESET_DROPDOWN}`,
    //   isDisabled: this.userRole !== 'SUPER ADMIN',
    //   dropdownSortDirection: 'asc',
    //   dropdownJsonKey: 'name',
    //   isRequired: true,
    // },
    // {
    //   label: 'Load Type',
    //   dataType: 'select',
    //   input: 'select',
    //   jsonKey: 'loadTypeName',
    //   foreignKeyProperty: 'loadTypeId',
    //   dropDownOptionsURL: `${API_ENDPOINTS.LOAD_TYPE}`,
    //   dropdownSortDirection: 'asc',
    //   dropdownJsonKey: 'name',
    //   ifKey: 'resetAllName',
    //   ifValue: 'Disabled',
    //   isRequired: true,
    // },
    {
      label: 'Batch Size',
      jsonKey: 'batchSize',
      input: 'number',
      dataType: 'number',
      isRequired: true,
      minLength: 1,
      maxLength: 99999,
    },
    {
      label: 'Active',
      jsonKey: 'isActive',
      input: 'toggle',
      dataType: 'boolean',
    },
  ];

  private _routerSubscription: Subscription;
  public get routerSubscription(): Subscription {
    return this._routerSubscription;
  }
  public set routerSubscription(value: Subscription) {
    this._routerSubscription = value;
  }
  routes: string[] = [];
  constructor(
    private routeService: RouteService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this._routerSubscription = this.routeService
      .getRoutes()
      .subscribe((data) => {
        this.routes = data;
        this.routes.shift();
        if (this.routes.length == 2) {
          this.routeService.redirectTO(
            this.routes.join('/').concat('/practice-load-configurations')
          );
        }
        this.cdr.detectChanges();
      });
  }

  onAddHandler(addClicked: boolean): void {
    if (addClicked) {
      this.drawerComponent.drawerType = 'add';
      this.resetEntry();
      this.openDrawer();
    }
  }

  openDrawer() {
    this.drawerComponent.openDrawer();
  }

  onEditHandler(rowData: any): void {
    if (this.drawerComponent) {
      this.drawerComponent.drawerType = 'edit';
      if (rowData.loadTypeId == 2) {
        rowData.resetAllId = 1;
        rowData.resetAllName = 'Disabled';
        this.drawerComponent.drawerModel = rowData;
      } else {
        this.drawerComponent.drawerModel = rowData;
      }
      this.drawerComponent.openDrawer();
    } else {
      console.error('Drawer component is not initialized');
    }
  }

  resetEntry() {
    this.drawerComponent.drawerModel = {
      id: NIL_UUID,
      practiceName: 'Select Practice',
      loadTypeName: 'FULL LOAD',
      loadTypeId: 1,
      loadScheduleName: 'Select Load Schedule',
      batchSize: 2500,
      isActive: true,
      loadScheduleId: NIL_UUID,
      practiceId: NIL_UUID,
    };
  }

  refreshHandler() {
    this.dynamicGrid.fetchData();
  }

  ngOnInit(): void {}

  // toast related properties

  @ViewChild(ToastComponent) toastComponent!: ToastComponent;

  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
    this.refreshHandler();
  }

  ngOnDestroy() {
    this._routerSubscription.unsubscribe();
  }
}
