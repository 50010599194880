import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from './components/shared/constants/apiEnpoints';

@Injectable({
  providedIn: 'root'
})
export class UserLogService {
  private apiUrl = API_ENDPOINTS.USER_LOGGING;

  constructor(private http: HttpClient) {}

  createUserLog(userLog: any): Observable<any> {
    return this.http.post(this.apiUrl, userLog);
  }
}