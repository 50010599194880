import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { GoogleCharts } from 'google-charts';
import { v4 as UUID } from 'uuid';

export interface ChartConfig {
  title: string;
  type: 'Pie' | 'Line' | 'Bar';
  is3D: boolean;
  legend?: {
    position: 'top' | 'left' | 'right' | 'bottom';
    alignment?: 'center';
  };
  height?: number;
  width?: number;
  vAxis?: { title: string; ticks?: number[] };
  hAxis?: { title: string };
  isStacked?: boolean;
  colors?: string[];
}

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnDestroy {
  chartId: string = UUID();
  charts: any;
  visualization: any;
  resizeObserver: ResizeObserver;
  selectListener: any;
  @Input() chartConfig: ChartConfig;
  @Input() data!: any[][];
  @Output() onChartClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() onhideChart: EventEmitter<void> = new EventEmitter<void>();
  isChartLoaded: boolean = false;

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    GoogleCharts.load(this.onGoogleChartsLoaded.bind(this));
  }

  ngOnDestroy() {
    try {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
      if (this.selectListener) {
        GoogleCharts.api.visualization.events.removeListener(
          this.selectListener
        );
        this.selectListener = null;
      }
    } catch (error) {
      console.error('Error during cleanup:', error);
    }
  }

  onGoogleChartsLoaded(): void {
    if (
      GoogleCharts.api &&
      GoogleCharts.api.charts &&
      GoogleCharts.api.visualization
    ) {
      this.charts = GoogleCharts.api.charts;
      this.visualization = GoogleCharts.api.visualization;
      this.charts.load('current', {
        packages: ['corechart'],
        callback: this.setChartLoaded.bind(this),
      });
      this.charts.setOnLoadCallback(() => {
        this.drawChart.bind(this)();
      });
      this.resizeObserver = new ResizeObserver(() => {
        if (this.isChartLoaded) {
          this.drawChart();
        }
      });
      this.resizeObserver.observe(this.elRef.nativeElement);
    } else {
      console.error('GoogleCharts API is not loaded properly.');
    }
  }
  setChartLoaded() {
    this.isChartLoaded = true;
  }

  drawChart() {
    const data = this.visualization?.arrayToDataTable(this.data);
    const options = {
      height: this.chartConfig?.height || 338,
      responsive: true,
      chartArea: { height: '60%', width: '60%' },
      tooltip: { trigger: 'none' },
      width: this.chartConfig?.width,
      legend: { ...this.chartConfig?.legend, alignment: 'center' },
      is3D: this.chartConfig?.is3D,
      isStacked: this.chartConfig?.isStacked,
      focusTarget: 'category',
      colors: this.chartConfig?.colors,
      vAxis: {
        ...this.chartConfig?.vAxis,
        format: '0',
        fontName: 'Roboto',
        minValue: 1,
      },
      hAxis: { ...this.chartConfig?.hAxis, format: '0', fontName: 'Roboto' },
      fontName: 'Roboto',
      plugins: {
        legend: {
          position: 'top',
          labels: {
            font: {
              size: 7,
              family: 'vazir',
            },
          },
        },
      },
    };

    let chart = new this.visualization.ColumnChart(
      document.getElementById(this.chartId)
    );

    chart.draw(data, options);

    this.selectListener = GoogleCharts.api.visualization.events.addListener(
      chart,
      'select',
      () => {
        const selectedItem = chart.getSelection()[0];
        if (selectedItem) {
          const date = data.getValue(selectedItem.row, 0);
          this.onChartClick.emit(date);
        } else {
          this.onhideChart.emit();
        }
      }
    );
  }
}
