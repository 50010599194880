<app-drawer
  #drawerComponent
  [drawerModel]="drawerData"
  [drawerConfig]="drawerInputConfig"
  [AddDataURL]="addDataUrl"
  [editDataURL]="editDataUrl"
  [createDrawerTitle]="'Assign General/Practice Release'"
  (refresh)="refreshHandler()"
  (resetData)="resetEntry()"
></app-drawer>

<app-dynamic-grid
  [gridConfig]="gridConfig"
  [getDataUrl]="getDataUrl"
  [toggleUrl]="toggleUrl"
  [sortBy] ="'releaseDate'"
  [sortOrder] = "'desc'"
  (onToggleShowToastEmitter)="showToast($event)"
  (addClicked)="onAddHandler($event)"
  (editClicked)="onEditHandler($event)"
></app-dynamic-grid>

<app-toast></app-toast>
