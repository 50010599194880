import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // Import FormsModule
import { RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatChipGrid } from '@angular/material/chips';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatError } from '@angular/material/form-field';
import { OktaAuth } from '@okta/okta-auth-js';
import {
  OKTA_CONFIG,
  OktaAuthModule,
  OktaAuthStateService,
} from '@okta/okta-angular';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PHIMasterComponent } from './components/configuration/phi-master/phi-master.component';
import { HomeComponent } from './components/home/home.component';
import { MainComponent } from './components/main/main.component';
import { AppRoutingModule, routes } from './app-routing.module';
import { PMSObjectComponent } from './components/configuration/pms-object/pms-object.component';
import { ToastComponent } from './components/shared/toast/toast.component';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './auth.interceptor';
import { DynamicGridModule } from './components/shared/dynamic-grid/dynamic-grid.module';

import { PMSMasterComponent } from './components/configuration/pms-master/pms-master.component';
import { DrawerComponent } from './components/shared/drawer/drawer.component';
import { BeatLoaderComponent } from './components/shared/beat-loader/beat-loader.component';
import { DropdownComponent } from './components/shared/dropdown/dropdown.component';
import { PracticeLoadConfigurationComponent } from './components/configuration/practice-load-configuration/practice-load-configuration.component';
import { PracticeMasterComponent } from './components/configuration/practice-master/practice-master.component';
import { LoadScheduleMasterComponent } from './components/configuration/load-schedule-master/load-schedule-master.component';
import { UserProfilePageComponent } from './components/user-profile-page/user-profile-page.component';
import { ManageUserRoleComponent } from './components/user-role-management/manage-user-role/manage-user-role.component';
import { PmsAgentHealthStatusComponent } from './components/reports/pms-agent-health-status/pms-agent-health-status.component';
import { PracticeLoadSummaryComponent } from './components/reports/practice-load-summary/practice-load-summary.component';
import { MatSelectModule } from '@angular/material/select';
import { PracticeLoadDetailComponent } from './components/reports/practice-load-detail/practice-load-detail.component';
import { PracticeInstallationLogComponent } from './components/reports/practice-installation-log/practice-installation-log.component';
import { HealthCheckComponent } from './health-check.component';
import { DashboardComponent } from './components/home/dashboard/dashboard.component';
import { ChartComponent } from './components/shared/chart/chart.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { PMSAgentReleaseComponent } from './components/configuration/pms-agent-release/pms-agent-release.component';
import { PMSAgentReleaseMgmtComponent } from './components/configuration/pms-agent-release-mgmt/pms-agent-release-mgmt.component';
import { A11yModule } from '@angular/cdk/a11y';
import { PracticeLogComponent } from './components/reports/practice-log/practice-log.component';
import { ForbiddenRequestComponent } from './components/shared/error-pages/forbidden-request/forbidden-request.component';
import { UserLogsComponent } from './components/reports/user-logs/user-logs.component';
import { ClipboardModule } from "@angular/cdk/clipboard";
import { ConfirmationModalComponent } from './components/shared/confirmation-modal/confirmation-modal.component';

const oktaAuth = new OktaAuth({
  issuer: environment.okta.oidc.issuer,
  clientId: environment.okta.oidc.clientId,
  redirectUri: window.location.origin + '/login/callback',
  pkce: environment.okta.oidc.pkce,
});

@NgModule({
  declarations: [
    AppComponent,
    HealthCheckComponent,
    LoginComponent,
    HeaderComponent,
    SidebarComponent,
    PMSObjectComponent,
    HomeComponent,
    MainComponent,
    ToastComponent,
    PHIMasterComponent,
    PMSMasterComponent,
    DrawerComponent,
    BeatLoaderComponent,
    DropdownComponent,
    PracticeLoadConfigurationComponent,
    PracticeMasterComponent,
    LoadScheduleMasterComponent,
    UserProfilePageComponent,
    ManageUserRoleComponent,
    PmsAgentHealthStatusComponent,
    PracticeLoadSummaryComponent,
    PracticeLoadDetailComponent,
    PracticeInstallationLogComponent,
    DashboardComponent,
    ChartComponent,
    PMSAgentReleaseComponent,
    PMSAgentReleaseMgmtComponent,
    PracticeLogComponent,
    ForbiddenRequestComponent,
    UserLogsComponent,
    ConfirmationModalComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterLink,
    CommonModule,
    RouterOutlet,
    RouterModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    DynamicGridModule,
    HttpClientModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatChipsModule,
    MatChipGrid,
    MatError,
    MatSelectModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    A11yModule,
    ClipboardModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideAnimationsAsync(),
    MatDrawer,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
