import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
  animations: [
    trigger('menuAnimation', [
      state('hidden', style({
        opacity: 0,
        transform: 'scale(0.95)',
      })),
      state('visible', style({
        opacity: 1,
        transform: 'scale(1)',
      })),
      transition('hidden => visible', animate('100ms ease-out')),
      transition('visible => hidden', animate('100ms ease-in')),
    ]),
  ],
})
export class HeaderComponent {
  showMenu: boolean = false;


  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }
}
