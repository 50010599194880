<app-dynamic-grid
  [getDataUrl]="getUsers"
  [toggleUrl]="toggleUsersRole"
  (onToggleShowToastEmitter)="showToast($event)"
  [sortBy]="'modifiedAt'"
  [sortOrder]="'desc'"
  [gridConfig]="gridConfig"
  (addClicked)="onAddHandler($event)"
  (editClicked)="onEditHandler($event)"
></app-dynamic-grid>
<app-drawer
  #drawerComponent
  [drawerConfig]="drawerInputConfig"
  [drawerModel]="drawerData"
  [AddDataURL]="postUsers"
  [editDataURL]="editUsers"
  [createDrawerTitle]="'Create New User Role'"
  [updateDrawerTitle]="'Update User Role'"
  (resetData)="resetEntry()"
  (refresh)="refreshGrid()"
></app-drawer>
<app-toast></app-toast>