import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { NIL as NIL_UUID } from 'uuid';
import { DrawerComponent } from '../../shared/drawer/drawer.component';
import { DrawerInputConfig } from '../../../models/drawer-model';
import { DynamicGridComponent } from '../../shared/dynamic-grid/dynamic-grid.component';
import { GridConfig } from '../../shared/dynamic-grid/grid-model/grid-config.model';
import { API_ENDPOINTS } from '../../shared/constants/apiEnpoints';
import { DRAWER_PLACEHOLDERS } from '../../shared/constants/drawerPlaceHolders';
import { Toast, ToastComponent } from '../../shared/toast/toast.component';
import { RouteService } from '../../../service/route.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface UserRoleData {
  id: string;
  email: string;
  roleName: string;
  isActive: boolean;
}

@Component({
  selector: 'app-manage-user-role',
  templateUrl: './manage-user-role.component.html',
  styleUrl: './manage-user-role.component.scss',
})
export class ManageUserRoleComponent {
  // - [grid related properties]

  @ViewChild(DynamicGridComponent) dynamicGrid!: DynamicGridComponent;
  gridConfig: GridConfig = {
    columns: [
      {
        label: 'Active',
        jsonKey: 'isActive',
        dataType: 'toggle',
        isSortable: false,
      },
      {
        label: 'First Name',
        jsonKey: 'firstName',
        dataType: 'string',
        toUppercase: true,
      },
      {
        label: 'Last Name',
        jsonKey: 'lastName',
        dataType: 'string',
        toUppercase: true,
      },
      { label: 'Email', jsonKey: 'email', dataType: 'string' },
      { label: 'Role', jsonKey: 'roleName', dataType: 'string' },
      {
        label: 'Okta UserId',
        jsonKey: 'externalUserId',
        dataType: 'string',
        isSortable: false,
      },
      {
        label: 'Created By',
        jsonKey: 'createdByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Created At',
        jsonKey: 'createdAt',
        dataType: 'date',
        hideOnToggle: true,
      },
      {
        label: 'Modified By',
        jsonKey: 'modifiedByUserEmail',
        dataType: 'string',
        hideOnToggle: true,
      },
      {
        label: 'Modified At',
        jsonKey: 'modifiedAt',
        dataType: 'date',
        hideOnToggle: true,
      },
    ],
    filters: [
      {
        label: 'Role',
        jsonKey: 'role',
        type: 'multi',
        options: [],
        width: 'w-50',
      },
    ],
    editable: true,
    exportable: true,
    displayAdd: false,
    isToggleDisabled: true,
    uniqueTableName: 'UserRoleManagement',
    displayHideAuditColumnsToggle: true,
  };
  getUsers = API_ENDPOINTS.USERS_ROLE;
  getRoles = API_ENDPOINTS.ALL_ROLES;
  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.fetchRoleOptions();
  }

  // - [drawer related properties]
  postUsers = API_ENDPOINTS.ASSIGN_USER_ROLE;
  editUsers = API_ENDPOINTS.UPDATE_USER_ROLE;
  toggleUsersRole = API_ENDPOINTS.TOGGLE_USER_ROLE;

  @ViewChild(DrawerComponent) drawerComponent!: DrawerComponent<UserRoleData>;
  drawerData: UserRoleData = {
    id: NIL_UUID,
    email: DRAWER_PLACEHOLDERS.SELECT_USER,
    roleName: DRAWER_PLACEHOLDERS.SELECT_ROLE,
    isActive: true,
  };
  drawerInputConfig: DrawerInputConfig[] = [
    {
      label: 'First Name',
      jsonKey: 'firstName',
      dataType: 'string',
      input: 'text',
      isDisabled: true,
    },
    {
      label: 'Last Name',
      jsonKey: 'lastName',
      dataType: 'string',
      input: 'text',
      isDisabled: true,
    },
    {
      label: 'Email',
      jsonKey: 'email',
      dataType: 'string',
      input: 'text',
      isDisabled: true,
    },
    {
      label: 'Role',
      jsonKey: 'roleName',
      dataType: 'select',
      dropdownJsonKey: 'name',
      input: 'select',
      dropDownOptionsURL: API_ENDPOINTS.ALL_ROLES,
      isRequired: true,
    },
    {
      label: 'Active',
      jsonKey: 'isActive',
      dataType: 'boolean',
      input: 'toggle',
    },
  ];

  // - [common properties]

  fetchRoleOptions() {
    this.http
      .get<any>(this.getRoles, { params: { pageSize: 20 } })
      .subscribe((response) => {
        response.data = response.data
          .filter((option) => option.isActive)
          .map((option) => ({
            ...option,
            name: option.name,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        this.gridConfig.filters.find(
          (filter) => filter.jsonKey === 'role'
        ).options = response;
      });
  }

  // - [drawer related methods]

  openDrawer() {
    this.drawerComponent.openDrawer();
  }

  // - [grid related methods]

  onAddHandler(addClicked: boolean): void {
    if (addClicked) {
      this.drawerComponent.drawerType = 'add';
      this.resetEntry();
      this.openDrawer();
    }
  }

  onEditHandler(rowData: any): void {
    if (this.drawerComponent) {
      this.drawerComponent.drawerType = 'edit';
      this.drawerComponent.drawerModel = rowData;
      this.openDrawer();
    } else {
      console.error('Drawer component is not initialized');
    }
  }

  // - [common methods]

  resetEntry() {
    this.drawerComponent.drawerModel = {
      id: NIL_UUID,
      email: DRAWER_PLACEHOLDERS.SELECT_USER,
      roleName: DRAWER_PLACEHOLDERS.SELECT_ROLE,
      isActive: true,
    };
  }

  refreshGrid() {
    this.dynamicGrid.fetchData();
  }

  // toast related properties

  @ViewChild(ToastComponent) toastComponent!: ToastComponent;

  showToast(toast: Toast) {
    this.toastComponent.showToast(toast);
    // this.refreshHandler();
  }
}
