<div *ngIf="!showPracticeLoadDetail">
  <app-dynamic-grid
  [gridConfig]="gridConfig"
  [getDataUrl]="getDataUrl"
  (addClicked)="onAddHandler()"
  [sortBy]="'startTime'"
  [sortOrder]="'desc'"
  (onToggleShowToastEmitter)="showToast($event)"
  (editClicked)="onEditHandler($event)"
  [toggleUrl]="toggleUrl"
  (onRowClick)="rowClicked($event)"
></app-dynamic-grid>
<app-toast></app-toast>
</div>
<!-- practice-load-summary.component.html -->
<router-outlet></router-outlet>