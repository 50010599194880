<div class="relative">
    <app-dynamic-grid
    [gridConfig]="gridConfig"
    [getDataUrl]="getDataURL"
    [sortBy]="'createdAt'"
    [sortOrder]="'desc'"
    (onToggleShowToastEmitter)="showToast($event)"
  ></app-dynamic-grid>
  <app-toast></app-toast>
  </div>
  <router-outlet></router-outlet>   