import { Component, EventEmitter, Input, Output } from '@angular/core';
 
@Component({
  selector: 'app-tailwind-toggle',
  template: `
    <div class="flex items-center !-z-10"(click)="$event.stopPropagation()">
      <label class="relative inline-flex items-center" [ngClass]="{'cursor-not-allowed':disabled,'cursor-pointer':!disabled}">
        <input type="checkbox" [id]="toggleId" [checked]="checked" [disabled]="disabled" (change)="onChange($event)" class="sr-only peer">
        <div class="w-7 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-green-600"></div>
      </label>
    </div>
  `,
  styles: []
})
export class TailwindToggleComponent {
  @Input() toggleId!: string;
  @Input() checked!: boolean;
  @Input() disabled?: boolean;
  @Output() onToggleChange = new EventEmitter<boolean>();
 
  onChange(event:Event) {
    const target = event.target as HTMLInputElement;
    this.onToggleChange.emit(target.checked);
  }
}